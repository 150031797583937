import React, { useState } from 'react'
import { IonItem, IonIcon } from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'
import { Map } from 'immutable'
import { useTranslation } from 'react-i18next'
// import { useHistory } from 'react-router-dom'

import { DateFormatter } from 'components/formatters'

import './WalletTransactionListItem.scss'

const Title = ({ transaction }) => {
  const { t } = useTranslation('wallet')

  const type = transaction.get('type')
  const state = transaction.get('state')

  const withdrawal = type === 'wallet/withdrawals'
  const deposit = type === 'wallet/deposits'
  const payment = type === 'wallet/payments'

  const isHolding = state === 'holding'
  const isProcessing = state === 'processing'
  const isProcessed = state === 'processed' || state === 'manual_processed'
  const isCancelled = state === 'cancelled' || state === 'voided'

  let title = ''

  switch (true) {
    case withdrawal && isHolding:
      title = 'withdraw_pending_approval'
      break
    case withdrawal && isProcessing:
      title = 'withdraw'
      break
    case withdrawal && isProcessed:
      title = 'money_transferred_to_your_bank_account'
      break
    case withdrawal && isCancelled:
      title = 'withdraw_cancelled'
      break

    case deposit && isHolding:
      title = 'transfer_pending_approval'
      break
    case deposit && isProcessing:
      title = 'transferring_to_your_wallet'
      break
    case deposit && isProcessed:
      title = 'transferred_to_your_wallet'
      break
    case deposit && isCancelled:
      title = 'transfer_cancelled'
      break

    case payment && isHolding:
      title = 'deduct_pending_approval'
      break
    case payment && isProcessing:
      title = 'deducting_from_your_wallet'
      break
    case payment && isProcessed:
      title = 'deducted_from_your_wallet'
      break
    case payment && isCancelled:
      title = 'deduct_cancelled'
      break

    default:
      break
  }

  return <h3>{t(`transaction_title.${title}`) || 'Transaction'}</h3>
}

const Timestamp = ({ transaction }) => {
  return (
    <p>
      <DateFormatter value={transaction.get('updated_at')} format='DD MMMM YYYY HH:mm' />
    </p>
  )
}

const Amount = ({ transaction }) => {
  const amount = transaction.getIn(['amount', 'display_value'])
  const type = transaction.get('type')
  const state = transaction.get('state')

  const withdrawal = type === 'wallet/withdrawals'
  const deposit = type === 'wallet/deposits'
  const payment = type === 'wallet/payments'

  const isHolding = state === 'holding'
  const isProcessing = state === 'processing'
  const isProcessed = state === 'processed' || state === 'manual_processed'
  const isCancelled = state === 'cancelled' || state === 'voided'

  let className = ''

  switch (true) {
    case isHolding:
      className = 'wt-amount--orange'
      break

    case deposit && isProcessed:
    case deposit && isProcessing:
      className = 'wt-amount--green'
      break

    case payment && isProcessed:
    case payment && isProcessing:
      className = 'wt-amount--red'
      break

    case withdrawal && isProcessing:
      className = 'wt-amount--red'
      break

    case isCancelled:
      className = 'wt-amount--grey'
      break

    default:
      break
  }

  return <h3 className={className}>{amount}</h3>
}

const Fee = ({ transaction }) => {
  const { t } = useTranslation('wallet')

  const hasFee = !!transaction.getIn(['fee', 'fractional'])
  const amount = transaction.getIn(['fee', 'display_value'])

  let fee = ''

  if (hasFee) {
    fee = t('transaction_fee.commission', { amount })
  } else {
    fee = t('transaction_fee.no_fee')
  }

  return <p className={hasFee ? 'wt__has-fee' : ''}>{fee}</p>
}

const Details = ({ transaction }) => {
  const { t } = useTranslation('wallet')

  const type = transaction.get('type')
  const state = transaction.get('state')

  const isPaymentFromJob = !transaction.get('issued_by')

  const withdrawal = type === 'wallet/withdrawals'
  const deposit = type === 'wallet/deposits'
  const payment = type === 'wallet/payments'

  const isHolding = state === 'holding'
  const isProcessing = state === 'processing'
  const isProcessed = state === 'processed' || state === 'manual_processed'
  const isCancelled = state === 'cancelled' || state === 'voided'

  if (deposit && isPaymentFromJob) {
    return (
      <div className='wt-details__container'>
        <p className='wt-details__description'>{transaction.get('description')}</p>

        {isHolding && (
          <>
            <p className='wt-details__remark'>
              {t('details.complete_job_time')}
              {' : '}
              <DateFormatter
                value={transaction.get('created_at')}
                format='DD MMMM YYYY HH:mm'
              />
            </p>
            <p className='wt-details__remark--danger'>
              {t('details.deposit_to_wallet_on')}{' '}
              <DateFormatter
                value={transaction.get('released_at')}
                format='DD MMMM YYYY HH:mm'
              />
            </p>
          </>
        )}
      </div>
    )
  } else if (deposit) {
    return (
      <div className='wt-details__container'>
        <p className='wt-details__description'>{transaction.get('description')}</p>

        <p className='wt-details__remark'>{t('details.issued_by_admin')}</p>

        {isHolding && (
          <p className='wt-details__remark--danger'>
            {t('details.deposit_to_wallet_on')}{' '}
            <DateFormatter
              value={transaction.get('released_at')}
              format='DD MMMM YYYY HH:mm'
            />
          </p>
        )}
      </div>
    )
  } else if (withdrawal && isProcessing) {
    return (
      <div className='wt-details__container'>
        <p className='wt-details__description'>{t('details.account_details')}</p>

        <div className='wt-details__remark'>
          <div className='wt-details__remark--bank'>
            <img
              src={transaction.getIn(['bank_account', 'bank', 'logo_url'])}
              alt='Bank Logo'
              width={25}
              height={25}
            />

            {transaction.getIn(['bank_account', 'bank', 'name'])}
          </div>

          <p className='wt-details__remark--account--name'>
            {t('details.account_name')}
            {': '}
            {transaction.getIn(['bank_account', 'account_name'])}
          </p>
        </div>

        <p className='wt-details__remark--danger'>
          {t('details.deposit_to_bank_account_on')}{' '}
          <DateFormatter
            value={transaction.get('released_at')}
            format='DD MMMM YYYY HH:mm'
          />
        </p>
      </div>
    )
  } else if (payment && (isProcessed || isHolding || isCancelled)) {
    return (
      <div className='wt-details__container'>
        <p className='wt-details__description'>{transaction.get('description')}</p>
      </div>
    )
  } else {
    return null
  }
}

const WalletTransactionListItem = ({ transaction }) => {
  const [open, setOpen] = useState(false)
  // const { push } = useHistory()

  const handleClick = () => {
    setOpen((isOpen) => !isOpen)
  }

  return (
    <IonItem
      className='wallet-transaction__list-item'
      button
      onClick={handleClick}
      detail={false}
      lines='full'
    >
      <div className='wallet-transaction__inner-container'>
        <div>
          <Title transaction={transaction} />
          <Timestamp transaction={transaction} />
          {transaction.get('job') && (
            <div
              className='number'
              // onClick={() => push(`/jobs/${transaction.get('job_provider_id')}`)}
            >
              <p>{transaction.get('job')?.get('number')}</p>
            </div>
          )}
        </div>

        <div>
          <Amount transaction={transaction} />
          {transaction.get('type') === 'wallet/withdrawals' && (
            <Fee transaction={transaction} />
          )}

          <IonIcon
            className={open ? 'wt__active' : 'wt__inactive'}
            icon={chevronDownOutline}
          />
        </div>

        <div className={open ? 'wt__active' : 'wt__inactive'}>
          <Details transaction={transaction} />
        </div>
      </div>
    </IonItem>
  )
}

WalletTransactionListItem.defaultProps = {
  transaction: Map()
}

export default WalletTransactionListItem
