import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SmallParagraph from 'components/typography/SmallParagraph'
import Paragraph from 'components/typography/Paragraph'

import './SendOtpForm.scss'
import CountryModal from 'components/modals/CountryModal'
import { seperateDialCodeFromPhoneNumber } from 'utils/stringUtils'
import {
  errorIsAlert,
  errorHasResponseBody
} from 'pages/verify_phone_number/shared/verificationErrors'
import countries from './countries.json'
import VerifyAlertError from 'components/alerts/VerifyAlertError'
import BigButton from 'components/buttons/BigButton'
import { IonButton, useIonViewDidLeave } from '@ionic/react'

const SendOtpForm = ({
  defaultNumber,
  buttonText,
  p,
  p2,
  t,
  useBigButton,
  onSubmit,
  placeholder
}) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [alertError, setAlertError] = useState({
    display: false,
    header: '',
    body: ''
  })
  const [country, setCountry] = useState({})
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(new Map())

  const handleError = (error) => {
    if (errorHasResponseBody(error)) {
      if (errorIsAlert(error.response.body.error)) {
        return setAlertError({
          display: true,
          header: t(`errors.${error.response.body.error}`),
          body: error.response.body.message
        })
      } else {
        return tryAddError(error.response.body.message)
      }
    }
    return tryAddError(error)
  }

  const handleSubmit = async () => {
    setLoading(true)
    onSubmit(phoneNumber)
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const tryGetError = useCallback((error) => errors.get(error) !== undefined, [errors])
  const tryAddError = useCallback(
    (error) => !tryGetError(error) && setErrors(new Map([...errors, [error, error]])),
    [errors, tryGetError]
  )
  const removeError = useCallback(
    (error) => {
      if (!tryGetError(error)) return
      const tmpErrors = errors
      tmpErrors.delete(error)
      setErrors(new Map([...tmpErrors]))
    },
    [errors, tryGetError]
  )

  const thailandDialCode = '+66'

  const getThailandFromCountries = () =>
    countries.find((country) => country.dialCode === thailandDialCode)

  const getCountryByPhoneNumber = (countries, phoneNumber) => {
    let returnValue = null
    countries.forEach((country) => {
      if (phoneNumber.includes(country.dialCode)) {
        returnValue = country
        return
      }
    })
    return returnValue
  }

  const getErrorString = () => {
    let errorString = ''
    for (const [key] of errors) {
      errorString = errorString.concat(key).concat(', ')
    }
    return errorString.substring(0, errorString.length - 2)
  }

  const setInitialPhoneNumber = useCallback(() => {
    if (defaultNumber.startsWith(0)) {
      setPhoneNumber(defaultNumber)
      return setCountry(getThailandFromCountries())
    }

    if (defaultNumber.startsWith('+')) {
      const country = getCountryByPhoneNumber(countries, defaultNumber)
      if (!country) {
        setPhoneNumber('')
        return setCountry(getThailandFromCountries())
      }
      setCountry(country)
      setPhoneNumber(seperateDialCodeFromPhoneNumber(defaultNumber, country.dialCode))
      return
    }
    setPhoneNumber('')
    setCountry(getThailandFromCountries())
  }, [defaultNumber])

  const chooseCountry = (country) => {
    setModalOpen(false)
    setCountry(country)
  }

  const handleUpdatePhoneNumber = (e) => {
    if (e.target.value.length <= 10) setPhoneNumber(e.target.value)
  }

  useEffect(() => {
    setInitialPhoneNumber()
  }, [setInitialPhoneNumber])

  useEffect(() => {
    if (phoneNumber)
      phoneNumber?.length <= 9
        ? tryAddError(t('errors.invalid_phone_number'))
        : removeError(t('errors.invalid_phone_number'))
    if (phoneNumber !== defaultNumber) removeError('This number is already verified')
  }, [phoneNumber, tryAddError, removeError, t, defaultNumber])

  useIonViewDidLeave(() => {
    setPhoneNumber('')
    setErrors(new Map())
  })
  return (
    <div className='form-container'>
      <div>
        <div className='send-otp-small-paragraph-1'>
          <SmallParagraph>{p}</SmallParagraph>
        </div>
        <div className='input-container'>
          {country && (
            <div className='country-box' onClick={() => setModalOpen(true)}>
              <img src={country.flag} alt='flag of country' />
              <p className='country-number'>{country.dialCode}</p>
            </div>
          )}
          <input
            min={10}
            disabled={loading}
            maxLength={10}
            placeholder={placeholder}
            inputMode={'numeric'}
            type={'number'}
            value={phoneNumber}
            onChange={(e) => handleUpdatePhoneNumber(e)}
            className='otp-phone-input'
          />
        </div>
        {errors.size > 0 && (
          <div>
            <Paragraph error>{getErrorString()}</Paragraph>
          </div>
        )}
        <div className='send-otp-small-paragraph-2'>
          <SmallParagraph>{p2}</SmallParagraph>
        </div>
        {useBigButton ? (
          <BigButton
            disabled={phoneNumber?.length !== 10 || loading}
            onClick={() => handleSubmit()}
          >
            {buttonText}
          </BigButton>
        ) : (
          <IonButton
            expand='block'
            disabled={phoneNumber?.length !== 10 || loading}
            onClick={() => handleSubmit()}
          >
            {buttonText}
          </IonButton>
        )}
      </div>
      <CountryModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        chooseCountry={chooseCountry}
        countries={countries}
      />
      <VerifyAlertError
        isOpen={alertError.display}
        errorBody={alertError.body}
        errorHeader={alertError.header}
        closeText={t('errorModal.confirm')}
        dissmiss={() =>
          setAlertError({
            display: false,
            body: '',
            header: ''
          })
        }
      />
    </div>
  )
}

SendOtpForm.propTypes = {
  loading: PropTypes.bool,
  defaultNumber: PropTypes.string,
  buttonText: PropTypes.string,
  p: PropTypes.string,
  p2: PropTypes.string,
  onSuccess: PropTypes.func,
  t: PropTypes.func,
  enteredFromSettingsPage: PropTypes.bool,
  verified: PropTypes.bool
}

export default SendOtpForm
