import { useSelector, useDispatch } from 'react-redux'

import {
  setOpenPolicyModal,
  setClosePolicyModal,
  acceptPolicy as accept,
  declinePolicy as decline
} from 'actions/policy'

export const usePolicyModal = () => {
  const dispatch = useDispatch()
  const policyModal = useSelector((state) => state.get('policy'))
  const acceptPolicy = async (policyId) => dispatch(accept(policyId))
  const declinePolicy = async (policyId) => dispatch(decline(policyId))
  const openPolicyModal = (payload) => {
    dispatch(setOpenPolicyModal(payload))
  }

  const closePolicyModal = () => {
    dispatch(setClosePolicyModal())
  }

  const openPolicy = () => {
    dispatch(setOpenPolicyModal())
  }

  const openTerms = () => {
    dispatch(setOpenPolicyModal())
  }

  return {
    open: policyModal.get('open'),
    variant: policyModal.get('variant'),
    openPolicyModal,
    closePolicyModal,
    openPolicy,
    openTerms,
    acceptPolicy,
    declinePolicy
  }
}
