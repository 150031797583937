import React, { useState } from 'react'
import { inquirySchema } from '@seekster/schemas'
import {
  IonText, IonLabel, IonIcon, IonToolbar,
  IonGrid, IonRow, IonCol
} from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { useResource } from 'hooks/resources'

import { DateFormatter, TimeRangeFormatter } from 'components/formatters'

import './UpcomingJobCard.scss'

const UpcomingJobCard = ({ inquiryId }) => {
  const { t } = useTranslation('conversations')
  const [open, setOpen] = useState(false)

  const [inquiry, { loading }] = useResource(
    `/inquiries/${inquiryId}`,
    inquirySchema,
    { id: inquiryId }
  )

  const activeJob = !loading && inquiry.get('jobs') && inquiry.get('jobs')
    .filter(job => job.get('state') === 'pending_start' || job.get('state') === 'pending_completion')
    .first()

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div className='upcoming-job-container'>
      <IonToolbar className='upcoming-job-detail'>
        {
          !loading && (
            <div style={{ width: '100%' }}>
              <IonLabel>
                {
                  open
                    ? inquiry.get('number')
                    : inquiry.get('number') + ' / ' + inquiry.getIn(['service', 'name'])
                }
              </IonLabel>

              {
                !open && activeJob && (
                  <IonLabel
                    className='upcoming-job-detail__show-button'
                    onClick={handleClick}
                  >
                    <IonText>{t('show')}</IonText>
                    <IonIcon icon={chevronDownOutline} />
                  </IonLabel>
                )
              }
            </div>
          )
        }

        {
          open && (
            <IonGrid className='upcoming-job-detail__description' fixed>
              <IonRow>
                <IonCol>
                  <IonRow>
                    <IonText className='upcoming-job-detail__service-name'>
                      {inquiry.getIn(['service', 'name'])}
                    </IonText>
                  </IonRow>

                  <IonRow>
                    <IonLabel className='upcoming-job-detail__label'>
                      <img
                        className='clock-icon'
                        src='/assets/img/clock.png'
                        alt='Clock'
                      />
                      <IonText className='upcoming-job-detail__description-start-date'>
                        <DateFormatter value={activeJob.get('start_time')} />
                      </IonText>
                    </IonLabel>
                    <IonLabel className='upcoming-job-detail__label'>
                      <IonText className='upcoming-job-detail__description-start-time'>
                        <TimeRangeFormatter value={{ startTime: activeJob.get('start_time'), endTime: activeJob.get('end_time') }} />
                      </IonText>
                    </IonLabel>
                  </IonRow>

                  <IonRow>
                    <IonLabel className='upcoming-job-detail__label'>
                      <img
                        className='marker-icon'
                        src='/assets/img/map_marker.png'
                        alt='Marker'
                      />
                      <IonText className='upcoming-job-detail__description-address'>
                        {inquiry.getIn(['location', 'masked_address'])}
                      </IonText>
                    </IonLabel>
                  </IonRow>

                  <IonRow className='upcoming-job-detail__hide-button'>
                    <IonLabel onClick={handleClick}>
                      <IonText>{t('hide')}</IonText>
                      <IonIcon icon={chevronDownOutline} />
                    </IonLabel>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          )
        }
      </IonToolbar>
    </div>
  )
}

export default UpcomingJobCard
