import { withFormik } from 'formik'
import * as Yup from 'yup'

import SwitchTenantForm from './SwitchTenantForm'

const formikConfig = {
  validationSchema: Yup.object().shape({
    access_key: Yup.string().required(),
    secret_key: Yup.string().required()
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(SwitchTenantForm)
