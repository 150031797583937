import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Map } from 'immutable'
import { providerSchema } from '@seekster/schemas'

import { getAccessToken, getCurrentResource } from 'selectors/authentication'
import providerState from 'constants/providerState'

const PrivateRoute = ({ component: Component, componentProps, isError, ...rest }) => {
  const accessToken = useSelector(getAccessToken)

  const provider =
    useSelector((state) => getCurrentResource(state, providerSchema)) || Map()

  const currentProviderState = provider.get('state')

  const renderAuthenticatedPage = (props) => {
    switch (currentProviderState) {
      case providerState.registering:
        return <Redirect to='/sign_up/registering' />
      case providerState.pendingApproval:
        return <Redirect to='/sign_up/pending_approval' />
      case providerState.settingPassword:
        return <Redirect to='/sign_up/set_password' />
      default:
        return <Component {...props} {...componentProps} />
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken && !isError ? renderAuthenticatedPage(props) : <Redirect to='/home' />
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  componentProps: PropTypes.object,
  isError: PropTypes.bool
}

export default PrivateRoute
