import React from 'react'
import PropTypes from 'prop-types'
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { jobProviderSchema, answerSchema } from '@seekster/schemas'

import { useResource } from 'hooks/resources'
import { useJobTabs } from 'hooks/utils'
import { PageLayout, PageHeader, PageContent } from 'components/layout'

import { JobHistoryDetailsPane, JobHistoryReportPane } from './panes'

const JobHistoryShowPage = ({ match }) => {
  const { t } = useTranslation('jobs')
  const [currentTab, setTab] = useJobTabs()

  const [jobProvider, { loading }] = useResource(
    `/job_providers/${match.params.id}`,
    jobProviderSchema,
    { id: match.params.id }
  )
  const [jobProviderAnswers, { loading: answerLoading }] = useResource(
    `/job_providers/${match.params.id}/answers`,
    answerSchema,
    { implicit: true }
  )

  const handleTabChange = (e) => {
    setTab(e.detail.value)
  }

  return (
    <PageLayout>
      <PageHeader
        title={jobProvider.getIn(['job', 'number'])}
        withBackButton
        backTo='/jobs'
      >
        <IonSegment mode='md' color='secondary' onIonChange={handleTabChange}>
          <IonSegmentButton mode='md' value='details' checked={currentTab === 'details'}>
            <IonLabel>{t('job_details')}</IonLabel>
          </IonSegmentButton>

          <IonSegmentButton mode='md' value='report' checked={currentTab === 'report'}>
            <IonLabel>{t('your_report')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </PageHeader>

      <PageContent loading={loading || answerLoading}>
        {currentTab === 'details' ? (
          <JobHistoryDetailsPane jobProvider={jobProvider} />
        ) : (
          <JobHistoryReportPane answers={jobProviderAnswers.toArray()} />
        )}
      </PageContent>
    </PageLayout>
  )
}

JobHistoryShowPage.propTypes = {
  match: PropTypes.object
}

export default JobHistoryShowPage
