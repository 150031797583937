import React from 'react'
import { Form } from 'formik'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import BaseField from 'components/fields/BaseField'

import './SwitchTenantForm.scss'

const SwitchTenantForm = ({ onSubmit, values, loading }) => {
  const { t } = useTranslation('translation')

  function handleClick() {
    onSubmit(values)
  }

  return (
    <Form className='tenant-form-container'>
      <div>
        <BaseField name='access_key' label='Access Key' />

        <BaseField name='secret_key' label='Secret Key' />
      </div>

      <IonButton onClick={() => handleClick()} disabled={loading} expand='block'>
        {t('confirm')}
      </IonButton>
    </Form>
  )
}

export default SwitchTenantForm
