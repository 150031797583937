import React from 'react'
import PropTypes from 'prop-types'
import { IonLabel, IonText } from '@ionic/react'
import { Field, ErrorMessage, getIn } from 'formik'
import { useTranslation } from 'react-i18next'

import Input from 'components/inputs/Input'

import './BaseField.scss'

const BaseField = ({
  name,
  label,
  noLabel,
  required,
  placeholder,
  render,
  initialValue,
  noErrorMessagePlaceholder,
  component: Component,
  children,
  endLabel,
  ...rest
}) => {
  const { t } = useTranslation('fields')

  const renderInput = ({ field, form }) => {
    if (render) {
      return render({ field, form })
    } else if (Component) {
      return (
        <Component
          {...field}
          value={field.value || initialValue}
          placeholder={placeholder && t(`placeholder.${name}`)}
          error={getIn(form.touched, name) && getIn(form.errors, name)}
          onChange={(value) => form.setFieldValue(name, value)}
          onBlur={(e) => form.setFieldTouched(name, true)}
          required={required}
          children={children}
          {...rest}
        />
      )
    }
  }

  return (
    <Field name={name}>
      {(renderProps) => (
        <>
          {!noLabel && (
            <div className={`base-field label ${endLabel && 'row'}`}>
              <IonLabel className='field-label' color='primary'>
                {label || t(`label.${name}`)}
                {required && <IonText color='danger'>{t('required')}</IonText>}
              </IonLabel>
              {endLabel && endLabel}
            </div>
          )}

          {renderInput(renderProps)}

          <ErrorMessage
            name={name}
            render={(message) =>
              message ? (
                <IonText color='danger'>
                  <p style={{ margin: 0 }}>{message}</p>
                </IonText>
              ) : null
            }
          />

          {!noErrorMessagePlaceholder && (
            <IonText className='error-message-placeholder'>
              <p>.</p>
            </IonText>
          )}
        </>
      )}
    </Field>
  )
}

BaseField.defaultProps = {
  component: Input
}

BaseField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.bool,
  initialValue: PropTypes.string,
  render: PropTypes.func,
  noErrorMessagePlaceholder: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.elementType,
  endLabel: PropTypes.elementType
}

export default BaseField
