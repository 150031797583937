import { FacebookLogin } from '@capacitor-community/facebook-login'

export const initFacebookSdk = () => {
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v10.0'
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
    resolve()
  })
}

export const facebookLogin = async () => {
  const permissions = ['public_profile', 'email']
  try {
    const result = await FacebookLogin.login({ permissions })
    if (!result.accessToken) throw result
    const tokenResponse = await FacebookLogin.getCurrentAccessToken()
    const userResponse = await fetch(
      `https://graph.facebook.com/v10.0/${tokenResponse.accessToken.userId}?fields=first_name,last_name,email,picture.width(500).height(500)&type=large&access_token=${tokenResponse.accessToken.token}`
    )
    const userData = await userResponse.json()
    await FacebookLogin.logout()
    return {
      token: tokenResponse.accessToken.token,
      userId: tokenResponse.accessToken.userId,
      userData: userData
    }
  } catch (errors) {
    throw errors
  }
}
