import { Map } from 'immutable'

import {
  SET_ERROR_ALERT_MESSAGE_SUCCEEDED,
  CLEAR_ERROR_ALERT_MESSAGE_SUCCEEDED,
  SET_CURRENT_LOCATION_SUCCEEDED
} from 'constants/interfaces'

const initialState = Map()

const interfacesReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ERROR_ALERT_MESSAGE_SUCCEEDED:
      return state.set('error', data.message)

    case CLEAR_ERROR_ALERT_MESSAGE_SUCCEEDED:
      return state.delete('error')

    case SET_CURRENT_LOCATION_SUCCEEDED:
      return state.set('currentLocation', data.coordinates)

    default:
      return state
  }
}

export default interfacesReducer
