import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  IonButton,
  IonModal,
  IonToolbar,
  IonTitle,
  IonText,
  IonAlert,
  IonButtons,
  IonIcon,
  IonImg,
  IonLabel,
  IonCheckbox,
  IonItem,
  useIonViewWillLeave,
  isPlatform
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import SignatureCanvas from 'react-signature-canvas'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { jobProviderSchema, signatureSchema } from '@seekster/schemas'

import { useCurrentLocation } from 'hooks/utils'
import { useResourceActions } from 'hooks/resources'
import { clearCollectionData } from 'actions/collections'
import convertUriToImageFile from 'utils/convertUriToImageFile'
import { deleteReport } from 'utils/report'
import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import Spinner from 'components/Spinner'

import './JobCompletionPage.scss'

const JobCompletionPage = ({ match, history, location }) => {
  const jobProviderId = match.params.id

  const { t } = useTranslation('jobs')

  const canvasRef = useRef()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [customerNotPresent, setCustomerNotPresent] = useState(false)
  const [dataUrl, setDataUrl] = useState('')
  const [showAlertAccept, setShowAlertAccept] = useState(false)
  const [showCompleteJobAlert, setShowCompleteJobAlert] = useState(false)
  const [showGoBackAlert, setShowGoBackAlert] = useState(false)

  const { latitude, longitude } = useCurrentLocation()
  const { create: submitSignature, loading: submittingSignature } =
    useResourceActions(signatureSchema)
  const {
    triggerWithData: completeJob,
    update: submitReport,
    loading: completingJob
  } = useResourceActions(jobProviderSchema)

  const handleConfirmSignature = () => {
    const url = canvasRef.current.toDataURL('image/jpeg')

    !canvasRef.current.isEmpty() && setDataUrl(url)

    setModalOpen(false)
  }

  const handleClearSignature = () => {
    canvasRef.current && canvasRef.current.clear()

    setDataUrl('')
  }

  const handleOpenSignaturePad = () => {
    setModalOpen(true)
  }

  const handleCompleteJob = async () => {
    setIsLoading(true)

    try {
      if (
        location.state &&
        location.state.data &&
        location.state.data.answers_attributes
      ) {
        await submitReport(`/job_providers/${jobProviderId}`, location.state.data)
      }

      if (dataUrl) {
        const signatureImage = await convertUriToImageFile(dataUrl)
        const data = { file: signatureImage }
        const options = {
          parentSchema: jobProviderSchema,
          parentId: jobProviderId
        }

        await submitSignature(`/job_providers/${jobProviderId}/signature`, data, options)
      }

      await completeJob(`/job_providers/${jobProviderId}/complete`, {
        end_latitude: latitude,
        end_longitude: longitude
      })

      if (isPlatform('ios')) {
        await deleteReport(jobProviderId)
      }

      dispatch(clearCollectionData(jobProviderSchema))

      setIsLoading(false)

      history.replace('/jobs', { fetch: true })
    } catch (error) {
      if (error.message) {
        try {
          const errorResponse = JSON.parse(error.message)

          if (
            errorResponse.message ===
            "Event 'complete' cannot transition from 'completed'. "
          ) {
            setShowAlertAccept(true)
          }
          setIsLoading(false)
        } catch (e) {
          alert(error)
          setIsLoading(false)
        }
      }

      setIsLoading(false)
    }
  }

  useIonViewWillLeave(handleClearSignature)

  const handleCannotAccept = () => {
    return history.push('/jobs')
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('complete_job')}
        withBackButton
        onBack={() => setShowGoBackAlert(true)}
      />

      <PageContent>
        <div className='job-completion-page--content-wrapper'>
          <IonText>{t('customer_signature')}</IonText>

          <div className='job-completion-page--content' onClick={handleOpenSignaturePad}>
            {dataUrl ? (
              <IonImg src={dataUrl} className='signature-image' />
            ) : (
              <div>
                <IonText className='tap-sign-text'>
                  {t('tap_here_to_add_signature')}
                </IonText>

                <img className='tap-sign-icon' src='/assets/img/ic_sign.png' alt='Sign' />
              </div>
            )}
          </div>
        </div>

        <IonModal
          isOpen={modalOpen}
          mode='md'
          className='signature-pad-modal--root'
          showBackdrop={false}
        >
          <IonToolbar color='primary' className='page-header'>
            <IonButtons slot='start' className='page-header__back-button'>
              <IonButton
                className='signature-pad-modal--close-button'
                onClick={() => setModalOpen(false)}
              >
                <IonIcon icon={arrowBack} size='large' />
              </IonButton>
            </IonButtons>

            <IonTitle className='page-title'>{t('sign_off')}</IonTitle>
          </IonToolbar>

          <div className='signature-canvas-wrapper'>
            <SignatureCanvas
              ref={canvasRef}
              backgroundColor='white'
              canvasProps={{
                width: 320,
                height: 400,
                className: 'signature-canvas-element'
              }}
            />
          </div>

          <IonToolbar>
            <IonButton
              className='signature-pad-modal--action'
              slot='start'
              fill='outline'
              onClick={handleClearSignature}
            >
              {t('make_correction')}
            </IonButton>

            <IonButton
              className='signature-pad-modal--action'
              slot='end'
              onClick={handleConfirmSignature}
            >
              {t('confirm')}
            </IonButton>
          </IonToolbar>
        </IonModal>

        <IonAlert
          isOpen={showAlertAccept}
          onDidDismiss={() => setShowAlertAccept(false)}
          message={t('complete_job_confirmation')}
          buttons={[
            {
              text: t('okay'),
              handler: handleCannotAccept
            }
          ]}
        />

        <IonAlert
          isOpen={showCompleteJobAlert}
          onDidDismiss={() => setShowCompleteJobAlert(false)}
          message={t('complete_job_confirmation')}
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('okay'),
              handler: handleCompleteJob
            }
          ]}
        />

        <IonAlert
          isOpen={showGoBackAlert}
          onDidDismiss={() => setShowGoBackAlert(false)}
          message={t('signature_will_be_deleted')}
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('okay'),
              handler: () => history.push(`/jobs/${jobProviderId}`, { tab: 'report' })
            }
          ]}
        />
      </PageContent>

      <PageFooter>
        <IonItem lines='none' className='customer-not-present'>
          <IonLabel>{t('customer_not_present')}</IonLabel>
          <IonCheckbox
            slot='start'
            mode='md'
            checked={customerNotPresent}
            onClick={() => setCustomerNotPresent(!customerNotPresent)}
          />
        </IonItem>

        <IonButton
          expand='block'
          disabled={!(customerNotPresent || dataUrl) || completingJob || isLoading}
          onClick={() => setShowCompleteJobAlert(true)}
        >
          {t('complete_job')}
        </IonButton>
      </PageFooter>

      {(submittingSignature || completingJob || isLoading) && <Spinner overlaid />}
    </PageLayout>
  )
}

JobCompletionPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
}

export default JobCompletionPage
