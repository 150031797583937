import moment from 'moment'
import { get } from 'lodash'

export const leadsQuery = ({
  // eslint-disable-next-line camelcase
  filter,
  start_time
}) => {
  return {
    start_time_gteq: get(start_time, 'from')
      ? moment(start_time.from).startOf('day').toISOString(true)
      : undefined,
    start_time_lteq: get(start_time, 'to')
      ? moment(start_time.to).endOf('day').toISOString(true)
      : undefined,

    isAvailabled: filter && filter.includes('availabled'),
    isDisabled: filter && filter.includes('disabled'),
    isDistance: filter && filter.includes('distance')
  }
}

export default leadsQuery
