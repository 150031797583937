import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const format = (value) => moment(value).format('HH:mm')

const TimeRangeFormatter = ({ value }) => {
  const { t } = useTranslation()

  return (
    <span>
      {
        value.startTime === value.endTime ? (
          t('time', { time: format(value.startTime) })
        ) : (
          t('time_range', { start: format(value.startTime), end: format(value.endTime) })
        )
      }
    </span>
  )
}

export default TimeRangeFormatter
