import React from 'react'
import PropTypes from 'prop-types'
import { IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import './RemarkFormatter.scss'

const RemarkFormatter = ({ value, namespace }) => {
  const { t } = useTranslation(namespace)

  return (
    <IonText className='remark-text'>
      <p>{t('remark')}</p>
      <p className='remark-text-success'>{value}</p>
    </IonText>
  )
}

RemarkFormatter.propTypes = {
  value: PropTypes.string,
  namespace: PropTypes.string
}

RemarkFormatter.defaultProps = {
  namespace: 'jobs'
}

export default RemarkFormatter
