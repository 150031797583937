import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { IonButton, IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { providerSchema } from '@seekster/schemas'

import { useResource, useResourceActions } from 'hooks/resources'
import { useEnterModal } from 'hooks/phone_verification'
import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import ProfileForm from 'forms/ProfileForm'
import Spinner from 'components/Spinner'
const ProfilePage = ({ history }) => {
  const { t } = useTranslation('settings')
  const submitButtonRef = useRef()
  const [showUpdateSucceededAlert, setShowUpdateSucceededAlert] = useState(false)

  const [provider, { loading }] = useResource('/provider', providerSchema, {
    implicit: true
  })
  const { update: updateProfile, loading: updating } = useResourceActions(providerSchema)
  const { enterModalFrom } = useEnterModal()
  const handleSubmit = (data) => {
    return updateProfile('/provider', data).then(() => setShowUpdateSucceededAlert(true))
  }

  const handleDismissAlert = () => {
    setShowUpdateSucceededAlert(false)

    history.goBack()
  }

  const handleEditPhone = () => {
    enterModalFrom('/settings/profile')
  }

  return (
    <PageLayout>
      <PageHeader title={t('my_profile')} withBackButton backTo='/settings' />

      <PageContent loading={loading}>
        <ProfileForm
          data={provider}
          onSubmit={handleSubmit}
          submitButtonRef={submitButtonRef}
          handleEditPhone={handleEditPhone}
        />

        <IonAlert
          isOpen={showUpdateSucceededAlert}
          onDidDismiss={handleDismissAlert}
          message={t('profile_updated')}
          buttons={[t('okay')]}
        />
      </PageContent>

      <PageFooter>
        <IonButton
          expand='block'
          onClick={() => submitButtonRef.current.click()}
          disabled={updating}
        >
          {t('save')}
        </IonButton>
      </PageFooter>

      {updating && <Spinner overlaid />}
    </PageLayout>
  )
}

ProfilePage.propTypes = {
  history: PropTypes.object
}

export default ProfilePage
