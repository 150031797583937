import { denormalize } from 'normalizr'
import reReselect from 're-reselect'
import pluralize from 'pluralize'
import { upperFirst, camelCase } from 'lodash'

import getEntities from './entities'

export const getBasicAuth = (state) => [
  state.getIn(['authentication', 'clientAccessKey']),
  state.getIn(['authentication', 'clientSecretKey'])
]

export const getAccessToken = (state) => state.getIn(['authentication', 'accessToken'])
export const getTeam = (state) => state.getIn(['authentication', 'team'])

export const getCurrentIdAttribute = (state, schema) => {
  const type = upperFirst(camelCase(pluralize.singular(schema._key)))

  return state.getIn(['authentication', `current${type}`])
}

export const getCurrentResource = reReselect(
  getEntities,
  (_state, schema, _key) => schema,
  getCurrentIdAttribute,
  (entities, schema, key) => denormalize(key, schema, entities)
)((_state, schema, key) => `${schema._key}[${key}]`)
