import React from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonRow,
  IonSearchbar,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useTeamDistribution } from 'hooks/teamDistribution'

import CompletedJob from '../CompletedJob'
import IncommingJob from '../IncommingJob'
import PendingJob from '../PendingJob'
import { handleTeamDock, searchJob } from 'actions/teamDistribution'

import './WorkPage.scss'

const WorkPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('settings')
  const { currentTeam, jobSearch } = useTeamDistribution()

  const handleSearch = (e) => {
    dispatch(searchJob(e.target.value))
  }

  const onSwapTeam = () => {
    dispatch(handleTeamDock(true))
  }

  return (
    <>
      <IonHeader className='work-page-header'>
        <IonGrid>
          <IonRow className='avatar-row ion-justify-content-start ion-align-items-center'>
            <IonCol>
              <div className='avatar-container ion-'>
                <IonAvatar>
                  <IonImg
                    src={currentTeam?.pictureUrl || 'assets/img/default_avatar.png'}
                    alt='avatar'
                    onClick={onSwapTeam}
                  />
                </IonAvatar>
                <IonButton size='small' onClick={onSwapTeam}>
                  {t('swap_account')}
                </IonButton>
              </div>
              <IonLabel className='header-label' color='light'>
                {t('work')}
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow className='searchbar-row'>
            <IonCol>
              <IonSearchbar
                mode='md'
                value={jobSearch}
                onIonChange={handleSearch}
                placeholder={`${t('search')}...`}
                debounce={1000}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonContent className='work-page-content' scrollY={false}>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path={['/team/work/incomming']} component={IncommingJob} />
            <Route exact path='/team/work/pending' component={PendingJob} />
            <Route exact path='/team/work/completed' component={CompletedJob} />
            <Route
              exact
              path='/team/work'
              render={() => <Redirect to='/team/work/incomming' />}
            />
            <Route
              exact
              path='/team'
              render={() => <Redirect to='/team/work/incomming' />}
            />
          </IonRouterOutlet>
          <IonTabBar slot='top' style={{ height: 64 }}>
            <IonTabButton mode='md' tab='work' href='/team/work/incomming'>
              <IonLabel>
                <h3>
                  <strong>{t('incomming')}</strong>
                </h3>
              </IonLabel>
            </IonTabButton>
            <IonTabButton mode='md' tab='pending' href='/team/work/pending'>
              <IonLabel>
                <h3>
                  <strong>{t('inprogress')}</strong>
                </h3>
              </IonLabel>
            </IonTabButton>
            <IonTabButton mode='md' tab='completed' href='/team/work/completed'>
              <IonLabel>
                <h3>
                  <strong>{t('completed')}</strong>
                </h3>
              </IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonContent>
    </>
  )
}

export default WorkPage
