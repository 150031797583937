import React from 'react'
import PropTypes from 'prop-types'
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton } from '@ionic/react'

import './PageHeader.scss'

const PageHeader = ({
  children,
  title,
  noColor,
  withBackButton,
  backTo,
  onBack,
  action,
  noBorder,
  ...rest
}) => {
  return (
    <IonHeader {...rest}>
      <IonToolbar
        color={noColor ? '' : 'primary'}
        className={noBorder ? 'page-header-no-border' : 'page-header'}
      >
        {withBackButton && (
          <IonButtons
            slot='start'
            className={`page-header__back-button ${
              withBackButton ? 'withBackButton' : ''
            } ${onBack ? 'onBack' : ''}`}
          >
            {onBack ? (
              <IonBackButton onClick={onBack} text='' />
            ) : (
              <IonBackButton defaultHref={backTo} text='' />
            )}
          </IonButtons>
        )}
        {title && <IonTitle className='page-title'>{title}</IonTitle>}
        {action && <IonButtons slot='end'>{action}</IonButtons>}
      </IonToolbar>

      {children && <IonToolbar className='page-header__seconday'>{children}</IonToolbar>}
    </IonHeader>
  )
}

PageHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  noColor: PropTypes.bool,
  withBackButton: PropTypes.bool,
  backTo: PropTypes.string,
  onBack: PropTypes.func,
  noBorder: PropTypes.bool,
  action: PropTypes.node
}

PageHeader.defaultProps = {
  backTo: '/'
}

export default PageHeader
