import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  IonRow,
  IonCol,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonText
} from '@ionic/react'
import {
  leadSchema,
  providerSchema,
  providerActiveDateSchema,
  providerRestDateSchema,
  policyTermSchema,
  jobProviderSchema,
  walletSchema
} from '@seekster/schemas'
import { Plugins } from '@capacitor/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PageLayout, PageContent } from 'components/layout'
import MainLeadList from 'components/lists/MainLeadList'
import EmptyListPlaceholder from 'components/lists/EmptyListPlaceholder'
import JobSkeletonCard from 'components/JobSkeletonCard'

import { useCollection } from 'hooks/collections'
import { useResource } from 'hooks/resources'
import { useEventId } from 'hooks/utils'
import { usePolicyModal } from 'hooks/policy'
import groupByStartDate from 'utils/groupByStartDate'

import './MainPage.scss'
import InfoBox from 'components/InfoBox'
import ProviderHeader from 'components/ProviderHeader'
import VerifyPhoneAlert from 'components/alerts/VerifyPhoneAlert'
import PolicyTermsModal from 'components/modals/PolicyTermsModal'

const MainPage = ({ location }) => {
  const { push } = useHistory()
  const { t, i18n } = useTranslation(['main', 'leads', 'verify_phone_number'])
  const isDistance = true
  const backButtonListenerHandle = useRef()
  const [alert, setAlert] = useState({
    isOpen: false,
    firstRender: true
  })
  const { openPolicy } = usePolicyModal()
  const pushState = location.state && location.state.fetch

  const [eventId, generateEventId] = useEventId()
  const [leads, { loading }] = useCollection('/leads', leadSchema, {
    dependencies: [eventId, i18n.language, pushState],
    query: { filter: {} },
    cacheKey: `leads-main-${eventId}-${JSON.stringify({})}`
  })
  const [jobProviders] = useCollection('/job_providers', jobProviderSchema, {
    dependencies: [eventId, i18n.language, pushState],
    query: { active: true, filter: {} },
    cacheKey: `providers-main-${eventId}`
  })

  const [restDates] = useCollection('/provider_rest_dates', providerRestDateSchema)
  // const [policy] = useCollection('/policy_terms', policyTermSchema)

  const [availableDates] = useCollection(
    '/provider_available_dates',
    providerActiveDateSchema
  )
  const [wallet] = useResource('/wallet', walletSchema, {
    implicit: true,
    dependencies: [eventId, pushState]
  })
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  useIonViewDidEnter(() => {
    backButtonListenerHandle.current = Plugins.App.addListener(
      'backButton',
      Plugins.App.exitApp
    )
  })
  const [policy] = useResource('/policy_terms', policyTermSchema, { implicit: true })

  const [totalEarnings, setTotalEarnings] = useState(null)
  const [potentialEarnings, setPotentialEarnings] = useState(null)
  const [availableBalance, setAvailableBalance] = useState(null)

  useIonViewDidLeave(() => {
    if (backButtonListenerHandle.current) {
      backButtonListenerHandle.current.remove()
      backButtonListenerHandle.current = undefined
    }
  })
  const handleRefresh = () => {
    generateEventId()
  }

  const getMoneyString = (revenue, removeDecimals = false) => {
    var formatter = new Intl.NumberFormat('th', {
      style: 'currency',
      currency: 'THB'
    })
    if (!revenue) {
      return '฿ 0'
    }
    const formatedRevenue = formatter.format(revenue)
    if (formatedRevenue.split('.')[1] === '00' || removeDecimals) {
      return formatedRevenue.split('.')[0]
    }
    return formatedRevenue
  }

  useEffect(() => {
    if (provider) {
      setTotalEarnings(
        getMoneyString(provider.get('amount_satangs_completed_jobs') / 100)
      )
      setPotentialEarnings(
        getMoneyString(provider.get('amount_satangs_active_jobs') / 100)
      )
      setAvailableBalance(getMoneyString(wallet.getIn(['available_balance', 'decimal'])))
      if (provider.get('verified') !== undefined) {
        if (!provider.get('verified') && alert.firstRender) {
          setAlert({
            isOpen: true,
            firstRender: false
          })
        }
      }
    }
  }, [provider, wallet, alert.firstRender])

  const openPolicyCallBack = useCallback((e) => {
    openPolicy()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !provider.get('accepted_latest_policy') && // always "true" if there's no policy
      !provider.get('accepted_latest_policy') &&
      policy.size > 0
    ) {
      openPolicyCallBack()
    }
  }, [policy.size, provider, openPolicyCallBack])

  return (
    <PageLayout withSafeAreaTop className='main-page-safe-area-top'>
      <PageContent onRefresh={handleRefresh} className='main-page-content safe-area-top'>
        <VerifyPhoneAlert
          isOpen={alert.isOpen && t('alert.test') === 'ok'}
          header={t('alert.header_main')}
          message={t('alert.message_main')}
          cancel={t('alert.cancel_main')}
          confirm={t('alert.confirm_main')}
          dissmiss={() => setAlert({ isOpen: false, firstRender: false })}
          openedFrom='/main'
        />

        <div className='main-page'>
          <div className='main-page-container'>
            <ProviderHeader
              identifier={`${t('identifier')} ${provider.get('id')}`}
              displayName={provider.get('display_name')}
              imgUrl={provider.get('avatar_url')}
              rating={provider.get('public_rating')}
            />
            <IonRow className='main-page-small-square-row'>
              <IonCol className='main-page-small-square-col-left'>
                <InfoBox
                  topContentHeading={t('pending_start_job')}
                  topContentData={`${jobProviders.size} ${t(
                    jobProviders.size === 0 ? 'job' : 'jobs'
                  )}`}
                  bottomContentHeading={t('value_recived')}
                  bottomContentData={potentialEarnings || '0'}
                />
              </IonCol>
              <IonCol className='small-square-col-right'>
                <InfoBox
                  colorOne='#22C4CE'
                  colorTwo='#69D5D7'
                  angle={90}
                  topContentHeading={t('money_recieved')}
                  topContentData=' '
                  bottomContentData={totalEarnings !== null ? totalEarnings : '0'}
                  bottomContentHeading={t('amount_total')}
                />
              </IonCol>
            </IonRow>
            <IonRow className='main-page-big-square'>
              <IonCol>
                <InfoBox
                  colorOne='#49B5E2'
                  colorTwo='#6F61D9'
                  angle={126}
                  topContentHeading={t('withdrawable_earnings')}
                  bottomContentData={availableBalance}
                  isBigBox
                />
              </IonCol>
            </IonRow>

            {!provider.get('disable_leads') && (
              <>
                <IonRow className='main-page-list-header-text-row main-page-side-margin-left'>
                  <IonCol>
                    <IonText className='main-page-list-header-text'>
                      {t('jobs_recommended')}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className='main-page-list-sub-header-row main-page-side-margin-left main-page-side-margin-right'>
                  <IonCol className='main-page-list-info-col' size='8'>
                    <IonText className='main-page-list-info-text'>
                      {t('radius', { distance: provider.get('available_distance') })}
                    </IonText>
                  </IonCol>
                  <IonCol className='main-page-list-info-link'>
                    <IonText
                      className='main-page-list-link-text'
                      onClick={() => push('/leads')}
                    >
                      {t('view_all')}
                    </IonText>
                  </IonCol>
                </IonRow>
                {loading && leads.isEmpty() ? (
                  <>
                    <JobSkeletonCard />
                    <JobSkeletonCard />
                    <JobSkeletonCard />
                  </>
                ) : leads.isEmpty() ? (
                  <EmptyListPlaceholder
                    message={
                      leads.current?.value ? t('search_not_found') : t('no_leads_message')
                    }
                  />
                ) : (
                  <MainLeadList
                    isDistance={isDistance}
                    latitude={provider.getIn(['home_address', 'latitude'])}
                    longitude={provider.getIn(['home_address', 'longitude'])}
                    data={groupByStartDate(leads, leadSchema, ['start_time'])}
                    availableDates={availableDates}
                    restDates={restDates}
                    availableDistance={provider.get('available_distance')}
                    maxLeads={4}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <PolicyTermsModal isSignedIn />
      </PageContent>
    </PageLayout>
  )
}

MainPage.propTypes = {
  location: PropTypes.object
}

export default MainPage
