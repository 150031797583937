import { Map } from 'immutable'
import { normalize } from 'normalizr'
import { providerSchema, tenantSchema, clientSchema } from '@seekster/schemas'

import {
  SIGN_IN_SUCCEEDED,
  SIGN_UP_SUCCEEDED,
  SIGN_OUT,
  FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED,
  FETCH_CURRENT_DEVICE_SUCCEEDED,
  UPDATE_CURRENT_DEVICE_SUCCEEDED,
  INITIATE_PHONE_AUTH_SUCCEEDED,
  INITIATE_PHONE_AUTH_FAILED
} from 'constants/authentication'
import {
  LOAD_BASIC_AUTH_SUCCEEDED,
  LOAD_LOCAL_DATA_SUCCEEDED
} from 'constants/localStorage'

const initialState = Map()

const authenticationReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case LOAD_BASIC_AUTH_SUCCEEDED:
      return state.merge(response)

    case FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED:
      return state.merge({
        currentTenant: normalize(response.body, tenantSchema).result
      })

    case SIGN_IN_SUCCEEDED:
    case SIGN_UP_SUCCEEDED:
    case FETCH_CURRENT_DEVICE_SUCCEEDED:
    case UPDATE_CURRENT_DEVICE_SUCCEEDED:
      return state.merge({
        accessToken: response.body.access_token,
        currentProvider: normalize(response.body.user, providerSchema).result,
        currentTenant: normalize(response.body.tenant, tenantSchema).result,
        currentClient: normalize(response.body.client, clientSchema).result
      })

    case LOAD_LOCAL_DATA_SUCCEEDED:
      return state.merge(response)

    case SIGN_OUT:
      return ['accessToken', 'currentProvider'].reduce(
        (_state, key) => _state.delete(key),
        state
      )

    // After trying to authenticate with phone number
    case INITIATE_PHONE_AUTH_SUCCEEDED:
    case INITIATE_PHONE_AUTH_FAILED:
    default:
      return state
  }
}

export default authenticationReducer
