import { withFormik } from 'formik'

import LeadFilterForm from './LeadFilterForm'

const formikConfig = {
  mapPropsToValues: () => ({}),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(LeadFilterForm)
