import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { IonItem, IonText, IonLabel, IonAvatar, IonImg } from '@ionic/react'
import { Map, List, fromJS } from 'immutable'
import { useTranslation } from 'react-i18next'

import Segment from 'components/Segment'
import QuestionAnswer from 'components/QuestionAnswer'

import './JobDetailsSegment.scss'

const JobDetailsSegment = ({
  number,
  service,
  orders,
  answers,
  additionalInfo,
  attachments,
  hasSpecial,
  setHasSpecial,
  ...rest
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    checkOrderIsSpecial(orders)
  }, [orders]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkOrderIsSpecial = (orders) => {
    orders = orders.toJS()
    const _hasSpecial = orders.find((order) => order.package.is_special)
    if (_hasSpecial) {
      setHasSpecial && setHasSpecial(true)
    }
  }

  return (
    <Segment title={t('job_details')} {...rest}>
      <IonItem
        lines='none'
        className={`job-details__item no-padding-item ${hasSpecial && 'text-red'}`}
      >
        <IonLabel className='job-details__item-title-subtitle'>
          <IonText>{number}</IonText>
          <IonText>{service.get('name')}</IonText>
        </IonLabel>

        <IonAvatar slot='end' className='job-details-service-icon'>
          <IonImg src={service.get('icon_url')} alt={service.get('name')} />
        </IonAvatar>
      </IonItem>

      {orders.map((order) => (
        <IonItem
          key={order.get('id')}
          lines='none'
          className={`job-details__order no-padding-item ${hasSpecial && 'text-red'}`}
        >
          <IonLabel>{order.getIn(['package', 'name'])}</IonLabel>

          <IonText slot='end'>{order.get('quantity_string')}</IonText>
        </IonItem>
      ))}
      {answers.map((answer) => (
        <QuestionAnswer key={answer.get('id')} data={answer} />
      ))}
      {additionalInfo && (
        <QuestionAnswer
          data={fromJS({
            question: {
              display_type: 'string',
              name: t('additional_information')
            },
            display_value: additionalInfo
          })}
        />
      )}
      {!attachments.isEmpty() && (
        <QuestionAnswer
          data={fromJS({
            question: {
              display_type: 'gallery_multiple',
              name: t('photos')
            },
            attachments
          })}
        />
      )}
    </Segment>
  )
}

JobDetailsSegment.propTypes = {
  number: PropTypes.string,
  service: ImmutablePropTypes.map,
  orders: ImmutablePropTypes.list,
  answers: ImmutablePropTypes.list,
  additionalInfo: PropTypes.string,
  attachments: ImmutablePropTypes.list,
  hasSpecial: PropTypes.bool,
  setHasSpecial: PropTypes.func
}

JobDetailsSegment.defaultProps = {
  service: Map(),
  orders: List(),
  answers: List(),
  attachments: List()
}

export default JobDetailsSegment
