import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter
} from '@ionic/react'
import { peopleCircle, ribbon } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { useTeamDistribution } from 'hooks/teamDistribution'
import ProviderCard from '../ProviderCard'
import { handleTeamDock } from 'actions/teamDistribution'

import './People.scss'

const People = ({ match }) => {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation('settings')

  const { currentTeam } = useTeamDistribution()

  useIonViewWillEnter(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = ''
  })

  const onSwapTeam = () => {
    dispatch(handleTeamDock(true))
  }

  const handleSelectProvider = (providerId) => {
    push(`${match.url}/${providerId}`)
  }

  return (
    <IonPage className='team-people-page'>
      <IonHeader className='work-page-header team-people-page___header'>
        <IonGrid>
          <IonRow className='avatar-row ion-justify-content-start ion-align-items-center'>
            <IonCol>
              <div className='avatar-container'>
                <IonAvatar>
                  <IonImg
                    src={currentTeam?.pictureUrl}
                    alt='avatar'
                    onClick={onSwapTeam}
                  />
                </IonAvatar>
                <IonButton size='small' onClick={onSwapTeam}>
                  {t('swap_account')}
                </IonButton>
              </div>
              <IonLabel className='header-label' color='light'>
                {t('members')}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonHeader>
      <IonHeader className='team-people-page___sub-header'>
        <IonItem lines='full'>
          <IonAvatar slot='start'>
            <IonImg src={currentTeam?.pictureUrl} alt='avatar' />
          </IonAvatar>
          <IonLabel className='team-detail'>
            <h2>
              <strong>{currentTeam?.name}</strong>
            </h2>
            <div>
              <IonText color='primary'>
                <IonIcon icon={peopleCircle} /> {t('member')}{' '}
                {currentTeam?.members.length} คน
              </IonText>
            </div>
            <div>
              <IonText color='primary'>
                <IonIcon icon={ribbon} /> {currentTeam?.leader?.fullName}{' '}
                <IonText color='secondary'>หัวหน้าทีม</IonText>
              </IonText>
            </div>
          </IonLabel>
        </IonItem>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList className='assign-provider-page___list'>
                {currentTeam?.members &&
                  currentTeam?.members.map((teamMember) => (
                    <ProviderCard
                      key={teamMember._id}
                      member={teamMember.member}
                      onClick={() => handleSelectProvider(teamMember.member.wfUserId)}
                    />
                  ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default People
