import { withFormik } from 'formik'

import ActiveCalendarForm from './ActiveCalendarForm'

const formikConfig = {
  // mapPropsToValues: () => (),

  enableReinitialize: true,

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(ActiveCalendarForm)
