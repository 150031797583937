import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/analytics'

export const firebaseSupportPlatform = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID
  },
  'firebaseSupportPlatform'
)

export const firebaseWorkforcePartner = firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_GOOGLE_SERVICE_INFO_API_KEY,
    databaseURL: process.env.REACT_APP_GOOGLE_SERVICE_INFO_DATABASE_URL,
    projectId: process.env.REACT_APP_GOOGLE_SERVICE_INFO_PROJECT_ID,
    storageBucket: process.env.REACT_APP_GOOGLE_SERVICE_INFO_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_GOOGLE_SERVICE_INFO_GCM_SENDER_ID,
    appId: process.env.REACT_APP_GOOGLE_SERVICE_INFO_GOOGLE_APP_ID
  },
  'firebaseWorkforcePartner' // this name will be used to retrieve firebase instance. E.g. second.database();
)

export const firebaseAnalytics = firebaseWorkforcePartner.analytics()

export { firebase }
