import React from 'react'
import { IonRow } from '@ionic/react'

import './AddiotionalInfoBox.scss'

const AdditionalInfoBox = ({ children }) => {
  return (
    <IonRow className='additional-info-box-container'>
      <div className='additional-info-content'>
        <p>{children}</p>
      </div>
    </IonRow>
  )
}

export default AdditionalInfoBox
