import React from 'react'
import { Form } from 'formik'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import BaseField from 'components/fields/BaseField'
import PasswordInput from 'components/inputs/PasswordInput'

import './SignUpReferralForm.scss'
import PropTypes from 'prop-types'

const SignUpReferralForm = ({ referrerId }) => {
  const { t } = useTranslation('sign_up')

  return (
    <Form className='sign-up-referral-form-container'>
      <div>
        <BaseField name='first_name' required autofocus />

        <BaseField name='last_name' required />

        <BaseField name='email' placeholder type='email' inputmode='email' required />

        <BaseField name='password' placeholder component={PasswordInput} required />

        <BaseField name='provider_id' disabled initialValue={referrerId} />
      </div>
      <IonButton className='sign-up-referral-submit-button' type='submit' expand='block'>
        {t('sign_up')}
      </IonButton>
    </Form>
  )
}
SignUpReferralForm.propTypes = {
  referrerId: PropTypes.string
}
export default SignUpReferralForm
