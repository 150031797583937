import React from 'react'
import PropTypes from 'prop-types'
import { IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'

const getColor = (state) => {
  switch (state) {
    case 'pending_start':
      return 'warning'
    case 'pending_completion':
    case 'completed':
      return 'success'
    case 'cancelled':
      return 'danger'
    default:
      return 'dark'
  }
}

const StateFormatter = ({ value, namespace }) => {
  const { t } = useTranslation(namespace)

  return (
    <IonText className='job-state' color={getColor(value)}>
      {t(`states.${value}`)}
    </IonText>
  )
}

StateFormatter.propTypes = {
  value: PropTypes.string,
  namespace: PropTypes.string
}

StateFormatter.defaultProps = {
  namespace: 'jobs'
}

export default StateFormatter
