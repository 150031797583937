import React from 'react'
import PropTypes from 'prop-types'

import './InfoButton.scss'
import { IonRow, IonText, IonCol } from '@ionic/react'

const InfoButton = ({ angle, colorOne, colorTwo, heading, content, data, unit }) => {
  return (
    <IonCol
      className='info-button-container'
      style={{
        background: `linear-gradient(${angle}deg,  ${colorOne} 0%,${colorTwo} 100%)`
      }}
    >
      <IonRow className='info-button-row'>
        <IonText>
          <IonText className='info-button-heading'>{heading}</IonText>
        </IonText>
      </IonRow>
      <IonRow className='info-button-row'>
        <IonText>
          <span className='info-button-content'>{content} </span>
          <span className='info-button-data'>{data}</span>
          <span className='info-button-content'> {unit}</span>
        </IonText>
      </IonRow>
    </IonCol>
  )
}

InfoButton.propTypes = {
  angle: PropTypes.number,
  colorOne: PropTypes.string,
  colorTwo: PropTypes.string
}

export default InfoButton
