import React from 'react'
import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/react'
import { star } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import './ProviderCard.scss'

const ProviderCard = ({ onClick, member, loading }) => {
  const { t } = useTranslation('team_lead')

  if (loading) {
    return (
      <IonItem
        onClick={onClick}
        lines='none'
        className='assign-provider-page___item team-provider-card'
      >
        <IonThumbnail slot='start'>
          <IonSkeletonText animated />
        </IonThumbnail>
        <IonLabel>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </p>
        </IonLabel>
      </IonItem>
    )
  }

  return (
    <IonItem
      onClick={onClick}
      lines='none'
      className='assign-provider-page___item team-provider-card'
    >
      <IonAvatar slot='start'>
        <IonImg
          src={member.avatarUrl ? member.avatarUrl : 'assets/img/default_avatar.png'}
        />
      </IonAvatar>
      <div className='provider-detail'>
        <div className='title'>{member.fullName}</div>
        <div className='phone'>
          {t('tel')}: {member.phoneNumber}
        </div>
        {/* <div className='distance'>
          <IonIcon icon={location} /> โปรดทำการปักหมุดที่อยู่ของคุณ
        </div> */}
      </div>
      <div className='rating' slot='end'>
        {member.rating || 0}/5 <IonIcon icon={star} color='danger' />
      </div>
    </IonItem>
  )
}

export default ProviderCard
