import React, { useState } from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { List } from 'immutable'
import { bankAccountSchema, walletSchema, walletTransactionSchema } from '@seekster/schemas'

import {
  PageLayout,
  PageHeader,
  PageContent,
  PageFooter
} from 'components/layout'
import Spinner from 'components/Spinner'
import { DateFormatter } from 'components/formatters'
import WalletTransactionList from 'modules/wallet/transactions'

import { useCollection } from 'hooks/collections'
import { useResource } from 'hooks/resources'
import { useEventId } from 'hooks/utils'

import './WalletPage.scss'

const WalletPage = ({ location }) => {
  const pushState = location.state && location.state.fetch

  const { t } = useTranslation('wallet')

  const [currentPage, setCurrentPage] = useState(1)
  const [eventId, generateEventId] = useEventId()

  const handleRefresh = () => {
    generateEventId()
    setCurrentPage(1)
  }

  const handleLoadMore = () => {
    setCurrentPage(page => page + 1)
  }

  const [bankAccounts] = useCollection('/bank_accounts', bankAccountSchema)

  const [
    wallet,
    {
      loading: loadingWallet
    }
  ] = useResource('/wallet', walletSchema, {
    implicit: true,
    dependencies: [eventId, pushState]
  })

  const [
    walletTransactions,
    {
      totalCount,
      totalPages,
      loading: loadingWalletTransaction
    }
  ] = useCollection('/wallet/transactions', walletTransactionSchema, {
    dependencies: [eventId, pushState],
    query: { page: currentPage }
  })

  return (
    <PageLayout>
      <PageHeader
        className='wallet-page__page-header'
        title={t('my_wallet')}
        withBackButton
        backTo='/settings'
      />

      <PageContent
        loading={loadingWallet}
        onRefresh={handleRefresh}
      >
        <div className='wallet-page-header__backdrop' />

        <div className='wallet-page-content__container'>
          <div className='wallet-page__balance-card'>
            <div className='wallet-page__available-balance'>
              <label>{t('available_balance')}</label>
              <p>{wallet.getIn(['available_balance', 'display_value'])}</p>
            </div>

            <div className='wallet-page__total-balance'>
              <label>{t('total_balance')}</label>
              <p>{wallet.getIn(['total_balance', 'display_value'])}</p>
            </div>

            {
              wallet.get('kbank_processing_balances', List()).map(processingBalance => (
                <div
                  key={processingBalance.get('payout_date')}
                  className='wallet-page__processing-balance'
                >
                  <p>
                    {t('wallet_pending_approval')}
                    {' '}
                    {processingBalance.getIn(['processing_balance', 'display_value'])}
                  </p>
                  <label>
                    {t('wallet_money_will_be_deposited')}
                    {' '}
                    <DateFormatter value={processingBalance.get('payout_date')} format='DD MMMM YY' />
                  </label>
                </div>
              ))
            }

            <p className='wallet-page__updated-at'>
              {t('updated_at')}
              {' '}
              <DateFormatter value={new Date().toISOString()} format='DD MMMM YYYY HH:mm' />
            </p>
          </div>

          <WalletTransactionList transactions={walletTransactions} />

          {
            totalCount > 30 && currentPage < totalPages && (
              <IonButton
                expand='full'
                color='light'
                onClick={handleLoadMore}
              >
                {loadingWalletTransaction ? <IonSpinner color='secondary' /> : t('load_more')}
              </IonButton>
            )
          }
        </div>
      </PageContent>

      <PageFooter>
        <IonButton
          expand='block'
          routerLink={
            bankAccounts.isEmpty()
              ? '/settings/wallet_withdrawal/error'
              : '/settings/wallet_withdrawal'
          }
        >
          {t('withdraw')}
        </IonButton>
      </PageFooter>

      {(loadingWallet || loadingWalletTransaction) && <Spinner overlaid />}
    </PageLayout>
  )
}

export default WalletPage
