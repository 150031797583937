import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { IonAvatar, IonIcon, IonImg, IonItem, IonLabel, IonList } from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import Drawer from 'react-bottom-drawer'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'

import TeamCustomerToast from 'components/TeamCustomToast/TeamCustomToast'
import {
  setCurrentTeam,
  handleTeamDock,
  authenticate,
  clearTeam
} from 'actions/teamDistribution'
import { useTeamDistribution } from 'hooks/teamDistribution'

const SwitchTeamDock = ({ isShow, onClose, currentUser }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentTeam, teams } = useTeamDistribution()

  useEffect(() => {
    dispatch(authenticate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTeam = async (team) => {
    history.push('/team/work/incomming')
    toast(<TeamCustomerToast avatar={team.pictureUrl} displayName={team.name} />, {
      duration: 3000,
      style: { width: '100%', background: '#5B8AC3ee', color: '#fff' },
      position: 'bottom-center'
    })
    dispatch(setCurrentTeam(team))
    dispatch(handleTeamDock(false))
  }

  const swapToUser = () => {
    toast(
      <TeamCustomerToast
        avatar={currentUser.get('avatar_url') || 'assets/img/default_avatar.png'}
        displayName={currentUser.get('display_name')}
      />,
      {
        duration: 3000,
        style: { width: '100%', background: '#5B8AC3ee', color: '#fff' },
        position: 'bottom-center'
      }
    )
    history.push('/settings')
    dispatch(clearTeam())
    dispatch(handleTeamDock(false))
  }

  return (
    <Drawer isVisible={isShow} onClose={onClose}>
      <IonList className='bottom-drawer'>
        <IonItem lines='full' onClick={swapToUser}>
          <IonAvatar slot='start'>
            <IonImg
              src={currentUser.get('avatar_url') || 'assets/img/default_avatar.png'}
              alt='Provider Avatar'
            />
          </IonAvatar>
          {!currentTeam && <IonIcon slot='end' icon={checkmarkCircle} />}
          <IonLabel>{currentUser.get('display_name')}</IonLabel>
        </IonItem>
        {teams &&
          teams.map((team) => (
            <IonItem key={team._id} lines='full' onClick={() => handleTeam(team)}>
              <IonAvatar slot='start'>
                <IonImg
                  src={team.pictureUrl || 'assets/img/default_avatar.png'}
                  alt='Team Picture'
                />
              </IonAvatar>
              {currentTeam && currentTeam._id === team._id && (
                <IonIcon slot='end' icon={checkmarkCircle} />
              )}
              <IonLabel>{team.name}</IonLabel>
            </IonItem>
          ))}
      </IonList>
    </Drawer>
  )
}

SwitchTeamDock.propTypes = {
  currentUser: PropTypes.object,
  isShow: PropTypes.bool,
  onClose: PropTypes.func
}

export default SwitchTeamDock
