import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { inquirySchema } from '@seekster/schemas'

import { useResource } from 'hooks/resources'
import { useCurrentLocation } from 'hooks/interfaces'
import { PageLayout, PageHeader, PageContent } from 'components/layout'
import GoogleDynamicMap from 'components/GoogleDynamicMap'
import generateOffsetCoordinate from 'utils/generateOffsetCoordinate'

const LeadMapPage = ({ match }) => {
  const { t } = useTranslation()

  const [inquiry] = useResource(
    `/inquiries/${match.params.id}`,
    inquirySchema,
    { id: match.params.id }
  )

  const [{ latitude, longitude }] = useCurrentLocation()
  const isDelivery = inquiry.get('has_logistics')
  const destination = generateOffsetCoordinate({
    latitude: isDelivery ? inquiry.get(['route', 'destination', 'latitude']) : inquiry.getIn(['location', 'latitude']),
    longitude: isDelivery ? inquiry.get(['route', 'destination', 'longitude']) : inquiry.getIn(['location', 'longitude'])
  }, 500)

  return (
    <PageLayout>
      <PageHeader
        title={t('map')}
        withBackButton
        backTo={`/leads/${match.params.id}`}
      />

      <PageContent>
        {
          isDelivery ? (
            <GoogleDynamicMap
              current={{ latitude, longitude }}
              marker='multiCircles'
              route={inquiry.get('route')}
              destination={destination}
            />
          ) : (
            <GoogleDynamicMap
              current={{ latitude, longitude }}
              destination={destination}
              marker='circle'
            />
          )
        }
      </PageContent>
    </PageLayout>
  )
}

LeadMapPage.propTypes = {
  match: PropTypes.object
}

export default LeadMapPage
