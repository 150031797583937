import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IonGrid,
  IonCol,
  IonButton,
  IonText,
  IonRouterLink,
  IonIcon,
  IonAlert,
  isPlatform,
  IonRow
} from '@ionic/react'
import { logoFacebook } from 'ionicons/icons'
import { LineLogin } from '@ionic-native/line-login'
import { Plugins } from '@capacitor/core'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Map } from 'immutable'
import { tenantSchema } from '@seekster/schemas'

import { PageLayout, PageContent } from 'components/layout'
import SignInForm from 'forms/SignInForm'
import Spinner from 'components/Spinner'
import PolicyTermsModal from 'components/modals/PolicyTermsModal'
import LHeader from 'components/typography/LHeader/LHeader'
import TenantLogoHeader from 'components/TenantLogoHeader'
import SignInWithPhoneNumber from 'forms/SignInWithPhoneNumber'
import SmallParagraph from 'components/typography/SmallParagraph'
import Paragraph from 'components/typography/Paragraph'

import { usePolicyModal } from 'hooks/policy'
import { setErrorAlertMessageSucceeded } from 'actions/resources'
import {
  signIn,
  signInWithPhoneNumberAndPassword,
  signInWithPhoneNumberSendOtp,
  updateCurrentDevice
} from 'actions/authentication'
import { getCurrentResource } from 'selectors/authentication'
import analytics from 'utils/analytics'

import './SignInPage.scss'

const { Storage, FacebookLogin, SignInWithApple, Device } = Plugins

const isDev = process.env.NODE_ENV === 'development'

const SignInPage = ({ history }) => {
  const { t } = useTranslation(['sign_in', 'verify_phone_number'])
  const [isAlert, setAlert] = useState(false)
  const [alertMessage, setMessage] = useState({
    header: t('error_occured')
  })
  const [alertButtons, setAlertButtons] = useState([t('okay')])
  const [loading, setLoading] = useState(false)
  const [deviceInfo, setDeviceInfo] = useState({})

  const { openPolicyModal } = usePolicyModal()

  useEffect(() => {
    Device.getInfo().then(setDeviceInfo)
  }, [])

  useEffect(() => {
    LineLogin.initialize({ channel_id: process.env.REACT_APP_LINE_CLIENT_ID }).catch(
      (error) => {
        console.warn('ERROR INITIALIZING LINE LOGIN SDK:', error)
      }
    )
  }, [])

  const dispatch = useDispatch()

  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const logoUrl = tenant.get('dark_logo_url')

  // Email sign in, maybe remove in future
  const tenantsThatAllowEmailSignIn = []
  const showSignInByEmailForm = tenantsThatAllowEmailSignIn.includes(tenant.get('slug'))

  const handleSignInWithSms = async (phoneNumber, password) => {
    setLoading(true)
    dispatch(signInWithPhoneNumberAndPassword(phoneNumber, password))
      .then(() => {
        return Storage.get({ key: 'locale' })
      })
      .then(({ value }) => {
        return dispatch(updateCurrentDevice({ locale: value }))
      })
      .then(() => {
        return history.push('/main')
      })
      // This might need to be updated
      .catch((e) => {
        const error = { response: {} }
        error.response = { body: { message: t('invalid_credentials') } }
        dispatch(setErrorAlertMessageSucceeded(error))
        throw error
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleResetPassword = async (phoneNumber) => {
    setLoading(true)
    try {
      await dispatch(signInWithPhoneNumberSendOtp(phoneNumber))
      history.push('/forget_password/verify')
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const errorHandler = (error) => {
    const err = { message: error.response?.body?.error, status: error.response?.status }
    return err
  }

  const handleError = (e, callback) => {
    const error = { response: {} }
    const { message, status } = errorHandler(e)
    if (message === 'invalid_credentials' && status === 400) {
      error.response = { body: { message: t('invalid_credentials') } }
      dispatch(setErrorAlertMessageSucceeded(error))
    } else if (message === 'unauthorized' && status === 401) {
      setMessage({
        header: t('alert.accountNotExist.header'),
        message: t('alert.accountNotExist.message')
      })
      setAlertButtons([
        {
          text: t('register'),
          // handler: () => history.push('sign_up'),
          handler: callback,
          cssClass: 'fw-normal'
        },
        {
          text: t('cancel'),
          cssClass: 'fw-normal'
        }
      ])
      setAlert(true)
    }
    return error
  }

  const handleSubmit = (data) => {
    analytics.logSignIn()
    setLoading(true)

    return dispatch(signIn('/sign_in', data))
      .then(() => {
        return Storage.get({ key: 'locale' })
      })
      .then(({ value }) => {
        return dispatch(updateCurrentDevice({ locale: value }))
      })
      .catch((e) => {
        const error = { response: {} }
        const { message, status } = errorHandler(e)

        if (message === 'invalid_credentials' && status === 400) {
          error.response = { body: { message: t('invalid_credentials') } }
          dispatch(setErrorAlertMessageSucceeded(error))
        } else if (message === 'unauthorized' && status === 401) {
          setMessage({
            header: t('alert.accountNotExist.header'),
            message: t('alert.accountNotExist.message')
          })
          setAlertButtons([
            {
              text: t('yes'),
              handler: () => history.push('sign_up'),
              cssClass: 'fw-normal'
            },
            {
              text: t('cancel'),
              cssClass: 'fw-normal'
            }
          ])
          setAlert(true)
        } else {
          dispatch(setErrorAlertMessageSucceeded(error))
        }

        throw e
      })
      .finally(() => setLoading(false))
  }

  const signInWithFacebook = async () => {
    setLoading(true)

    const permissions = ['public_profile', 'email']

    try {
      const result = await FacebookLogin.login({ permissions })

      if (result && result.accessToken) {
        const { userId, token } = result.accessToken

        const url = `https://graph.facebook.com/${userId}?fields=first_name,last_name,email,picture.width(500).height(500)&type=large&access_token=${token}`

        const response = await fetch(url)
        const userData = await response.json()

        await dispatch(
          signIn('/oauth_existing', {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            remote_avatar_url: userData.picture.data.url,
            omniauth_identities_attributes: {
              uid: userId,
              provider: 'facebook',
              token
            }
          })
        )

        setLoading(false)
        FacebookLogin.logout()
        history.push('/main')
      } else {
        setLoading(false)
      }
    } catch (e) {
      console.warn('Facebook Login Error:', e)
      handleError(e, signUpWithFacebook)

      setLoading(false)
    }
  }

  const signInWithApple = async () => {
    setLoading(true)

    try {
      const { response } = await SignInWithApple.Authorize()

      // TODO:
      // cache givenName, familyName, email in localStorage by lookup with repsonse.user

      await dispatch(
        signIn('/oauth_existing', {
          first_name: response.givenName,
          last_name: response.familyName,
          email: response.email,
          omniauth_identities_attributes: {
            uid: response.user,
            provider: 'apple',
            token: response.identityToken
          }
        })
      )

      setLoading(false)
      history.push('/main')
    } catch (e) {
      console.warn('Apple Login Error:', e)
      handleError(e, signUpWithApple)

      setLoading(false)
    }
  }

  const signInWithLine = async () => {
    setLoading(true)

    try {
      const { email, pictureURL, displayName, userID } = await LineLogin.login()
      const { accessToken } = await LineLogin.getAccessToken()

      await dispatch(
        signIn('/oauth_existing', {
          first_name: displayName,
          email,
          remote_avatar_url: pictureURL,
          omniauth_identities_attributes: {
            uid: userID,
            provider: 'line',
            token: accessToken
          }
        })
      )

      setLoading(false)

      history.push('/main')
    } catch (e) {
      console.warn('Line Login Error:', e)
      handleError(e, signUpWithLine)

      setLoading(false)
    }
  }

  const signUpWithFacebook = async () => {
    setLoading(true)

    const permissions = ['public_profile', 'email']

    try {
      const result = await FacebookLogin.login({ permissions })

      if (result && result.accessToken) {
        const { userId, token } = result.accessToken

        const url = `https://graph.facebook.com/${userId}?fields=first_name,last_name,email,picture.width(500).height(500)&type=large&access_token=${token}`

        const response = await fetch(url)
        const userData = await response.json()

        await dispatch(
          signIn('/oauth', {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            remote_avatar_url: userData.picture.data.url,
            omniauth_identities_attributes: {
              uid: userId,
              provider: 'facebook',
              token
            }
          })
        )

        console.log('facebook login')
        setLoading(false)
        FacebookLogin.logout()
        history.push('/')
      } else {
        setLoading(false)
      }
    } catch (e) {
      console.warn('Facebook Login Error:', e)
      setLoading(false)
    }
  }

  const signUpWithLine = async () => {
    setLoading(true)

    try {
      const { email, pictureURL, displayName, userID } = await LineLogin.login()
      const { accessToken } = await LineLogin.getAccessToken()

      await dispatch(
        signIn('/oauth', {
          first_name: displayName,
          email,
          remote_avatar_url: pictureURL,
          omniauth_identities_attributes: {
            uid: userID,
            provider: 'line',
            token: accessToken
          }
        })
      )

      setLoading(false)
      history.push('/')
    } catch (e) {
      console.warn('Line Login Error:', e)
      setLoading(false)
    }
  }

  const signUpWithApple = async () => {
    setLoading(true)

    try {
      const { response } = await SignInWithApple.Authorize()

      // TODO:
      // cache givenName, familyName, email in localStorage by lookup with repsonse.user

      await dispatch(
        signIn('/oauth', {
          first_name: response.givenName,
          last_name: response.familyName,
          email: response.email,
          omniauth_identities_attributes: {
            uid: response.user,
            provider: 'apple',
            token: response.identityToken
          }
        })
      )

      setLoading(false)
      history.push('/')
    } catch (e) {
      console.warn('Apple Login Error:', e)
      setLoading(false)
    }
  }

  const handleDismissAlert = () => {
    setAlert(false)
    setAlertButtons([t('okay')])
    setMessage({ header: t('error_occured') })
  }

  return (
    <PageLayout>
      <PageContent className='sign-in-page'>
        <IonGrid className='grid-container'>
          <IonCol>
            <IonRow className='sign-in-header-section'>
              <IonRow className='header-text'>
                <LHeader>{t('sign_in')}</LHeader>
              </IonRow>
              <IonRow className='header-logo'>
                <TenantLogoHeader url={logoUrl} />
              </IonRow>
            </IonRow>
            <div className='sign-in-with-phone-number-container'>
              <SignInWithPhoneNumber
                defaultNumber=''
                t={t}
                useBigButton
                buttonText={t('sign_in_phone_number_button')}
                onSubmit={(phoneNumber, password) =>
                  handleSignInWithSms(phoneNumber, password)
                }
                onResetPassword={(phoneNumber) => handleResetPassword(phoneNumber)}
                p2={t('forgot_password_link')}
                p3={t('forgot_password_text')}
                placeholder={t('sign_in_with_phone_number')}
                passwordPlaceholder={t('sign_in_with_phone_number_password')}
              />
            </div>

            <IonButton
              className='line-login-button'
              expand='block'
              onClick={signInWithLine}
            >
              <img
                className='line-login-button__icon'
                src='/assets/img/line_logo.png'
                alt='Line Logo'
              />
              <IonText className='line-login-button__text'>
                {t('login_with_line')}
              </IonText>
            </IonButton>

            <IonButton
              className='facebook-sign-in-button'
              expand='block'
              onClick={signInWithFacebook}
            >
              <IonIcon
                className='facebook-sign-in-button__icon'
                slot='start'
                icon={logoFacebook}
              />
              <IonText className='facebook-sign-in-button__text'>
                {t('continue_with_facebook')}
              </IonText>
            </IonButton>

            {(isDev ||
              (isPlatform('ios') && parseInt(deviceInfo.osVersion, 10) >= 13)) && (
              <IonButton
                type='button'
                className='apple-sign-in-button'
                expand='block'
                onClick={signInWithApple}
              >
                <img
                  className='apple-sign-in-button__icon'
                  src='/assets/img/apple-logo-dark.png'
                  alt='Apple Logo'
                />
                <IonText className='apple-sign-in-button__text'>
                  {t('sign_in_with_apple')}
                </IonText>
              </IonButton>
            )}

            {showSignInByEmailForm && (
              <>
                <div className='button-divider'>
                  <div className='line' />

                  <IonText className='text' color='medium'>
                    {t('or')}
                  </IonText>

                  <div className='line' />
                </div>
                <SignInForm
                  onSubmit={handleSubmit}
                  push={history.push}
                  loading={loading}
                />
              </>
            )}

            <div className='policy-paragraph'>
              <Paragraph>
                <span className='blue-underline' onClick={() => openPolicyModal('terms')}>
                  {t('terms_part')}
                </span>{' '}
                <span>{t('and_part')}</span>{' '}
                <span
                  className='blue-underline'
                  onClick={() => openPolicyModal('privacy')}
                >
                  {t('policy_part')}
                </span>
              </Paragraph>
            </div>
            <IonRow className='sign-in-sign-up-row'>
              <SmallParagraph>
                <span>{t('sign_up_before')}</span>{' '}
                <IonRouterLink routerLink='/sign_up'>
                  <span className='blue-underline bold'>{t('sign_up_after')}</span>
                </IonRouterLink>
              </SmallParagraph>
            </IonRow>
          </IonCol>
        </IonGrid>
      </PageContent>
      <PolicyTermsModal isMenu swipeToClose />
      <IonAlert
        isOpen={isAlert}
        onDidDismiss={handleDismissAlert}
        header={alertMessage.header}
        message={alertMessage.message}
        buttons={alertButtons}
      />
      {loading && <Spinner overlaid />}
    </PageLayout>
  )
}

SignInPage.propTypes = {
  history: PropTypes.object
}

export default SignInPage
