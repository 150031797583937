import React from 'react'
import { Form } from 'formik'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import BaseField from 'components/fields/BaseField'
import PasswordInput from 'components/inputs/PasswordInput'

import './SignUpForm.scss'

const SignUpForm = () => {
  const { t } = useTranslation('sign_up')

  return (
    <Form className='sign-up-form-container'>
      <div>
        <BaseField
          name='first_name'
          required
          autofocus
        />

        <BaseField
          name='last_name'
          required
        />

        <BaseField
          name='email'
          type='email'
          inputmode='email'
          required
        />

        <BaseField
          name='password'
          component={PasswordInput}
          required
        />
      </div>

      <IonButton
        type='submit'
        expand='block'
      >
        {t('sign_up')}
      </IonButton>
    </Form>
  )
}

export default SignUpForm
