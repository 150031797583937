import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonAlert,
  IonRouterLink,
  IonAvatar,
  IonImg,
  IonItem,
  IonLabel,
  IonChip
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  leadSchema,
  inquirySchema,
  providerSchema,
  tenantSchema,
  providerActiveDateSchema,
  providerRestDateSchema
} from '@seekster/schemas'
import { getCurrentResource } from 'selectors/authentication'
import { useResource, useResourceActions } from 'hooks/resources'
import { clearCollectionData } from 'actions/collections'
import { useCollection } from 'hooks/collections'
import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import Segment from 'components/Segment'
import GoogleStaticMap from 'components/GoogleStaticMap'
import JobActionFooter from 'components/JobActionFooter'
import QuestionAnswer from 'components/QuestionAnswer'
import { JobDetailsSegment, JobStartTimeSegment } from 'modules/segments'
import { TimeRangeFormatter, RemarkFormatter } from 'components/formatters'
import Spinner from 'components/Spinner'
import distanceDifferent from 'utils/distanceDifferent'
import { useEventId } from 'hooks/utils'

import './LeadShowPage.scss'
import JobAdditionalInfoSegment from 'modules/segments/JobAdditionalInfoSegment/JobAdditioanlInfoSegment'
import VerifyPhoneAlert from 'components/alerts/VerifyPhoneAlert'

const LeadShowPage = ({ match, history }) => {
  const { t } = useTranslation(['leads', 'verify_phone_number'])
  const [showAlert, setShowAlert] = useState(false)
  const [showVerifyAlert, setShowVerifyAlert] = useState(false)
  const [showAlerWithDisabled, setShowAlertWithDisabled] = useState(false)
  const [showAlertAccept, setShowAlertAccept] = useState(false)
  const [currentState, setCurrentState] = useState()
  const [distance, setDistance] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAvailableDate, setIsAvailableDate] = useState(false)
  const [isDisableDate, setIsDisableDate] = useState(false)
  const [hasSpecial, setHasSpecial] = useState(false)
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const [eventId, generateEventId] = useEventId()
  const distanceEnabled = tenant.getIn([
    'general_configuration',
    'location_provider_enabled'
  ])

  const dispatch = useDispatch()

  const [restDates] = useCollection('/provider_rest_dates', providerRestDateSchema, {
    dependencies: [eventId],
    cacheKey: `restDate-show-${eventId}`
  })

  const [availableDates] = useCollection(
    '/provider_available_dates',
    providerActiveDateSchema,
    {
      dependencies: [eventId],
      cacheKey: `avialabe-show-${eventId}`
    }
  )
  const [inquiry, { loading }] = useResource(
    `/inquiries/${match.params.id}`,
    inquirySchema,
    { id: match.params.id }
  )

  const { trigger: acceptLead } = useResourceActions(inquirySchema)

  useEffect(() => {
    generateEventId()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!inquiry.isEmpty() && inquiry.get('jobs')) {
      const ava = availableDates.filter((date) =>
        inquiry
          .get('jobs')
          .find(
            (job) =>
              moment(date.get('start_time')).isSame(job.get('start_time'), 'day') &&
              moment(date.get('start_time')).hours() <
                moment(job.get('end_time')).hours() &&
              moment(date.get('end_time')).hours() > moment(job.get('start_time')).hours()
          )
      )

      const rest = restDates.filter((date) =>
        inquiry
          .get('jobs')
          .find(
            (job) =>
              moment(date.get('start_time')).isSame(job.get('start_time'), 'day') &&
              moment(date.get('start_time')).hours() <
                moment(job.get('end_time')).hours() &&
              moment(date.get('end_time')).hours() > moment(job.get('start_time')).hours()
          )
      )

      if (ava.size > 0) {
        setIsAvailableDate(true)
      } else {
        setIsAvailableDate(false)
      }

      if (rest.size > 0) {
        setIsDisableDate(true)
      } else {
        setIsDisableDate(false)
      }
    }
  }, [inquiry, availableDates, restDates])

  useEffect(() => {
    if (provider.getIn(['home_address', 'latitude'])) {
      setDistance(
        distanceDifferent(
          provider.getIn(['home_address', 'latitude']),
          provider.getIn(['home_address', 'longitude']),
          !inquiry.get('has_logistics')
            ? inquiry.getIn(['location', 'latitude'])
            : inquiry.getIn(['route', 'origin', 'latitude']),
          !inquiry.get('has_logistics')
            ? inquiry.getIn(['location', 'longitude'])
            : inquiry.getIn(['route', 'origin', 'longitude'])
        )
      )
    }
  }, [inquiry]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      inquiry.get('state') !== undefined &&
      inquiry.get('state') !== 'pending_provider' &&
      currentState !== 'pending_provider'
    ) {
      setCurrentState('pending_provider')
      setShowAlertAccept(true)
    }
  }, [inquiry, currentState])

  const handleAcceptLead = () => {
    setCurrentState('pending_provider')
    setIsLoading(true)

    return acceptLead(`/inquiries/${match.params.id}/accept`)
      .then(() => {
        history.push('/leads')
        history.push('/jobs', { fetch: true })

        setIsLoading(false)
        dispatch(clearCollectionData(leadSchema))
      })
      .catch((error) => {
        const errorResponse = JSON.parse(error.message)
        setIsLoading(false)

        if (
          errorResponse.message === "Event 'accept' cannot transition from 'assigned'. "
        ) {
          setShowAlertAccept(true)
        }

        if (
          errorResponse.message === "Event 'accept' cannot transition from 'accepted'. "
        ) {
          setShowAlertAccept(true)
        }

        if (
          errorResponse.message ===
          "Event 'accept' cannot transition from 'pending_completion'. "
        ) {
          setShowAlertAccept(true)
        }
      })
  }

  const handleCannotAccept = () => {
    return history.replace('/leads', { fetch: true })
  }

  return (
    <PageLayout>
      {hasSpecial && <div className='alert-topbar'>จำเป็นต้องใช้อุปกรณ์พิเศษ</div>}
      <PageHeader title={inquiry.get('number')} withBackButton />
      <PageContent loading={loading}>
        {inquiry.get('remarks_for_provider') && (
          <Segment>
            <RemarkFormatter value={inquiry.get('remarks_for_provider')} />
          </Segment>
        )}
        {inquiry.get('preferred_customer') && (
          <Segment>
            <IonGrid className='preferred-by-grid__container'>
              <IonRow>
                <IonCol size='auto' className='preferred-by-grid__column avatar-column'>
                  <IonRow>
                    <IonAvatar className='customer-avatar'>
                      <IonImg
                        src={inquiry.getIn(['preferred_customer', 'avatar_url'])}
                        alt='Customer Avatar'
                      />
                    </IonAvatar>
                  </IonRow>
                </IonCol>

                <IonCol className='preferred-by-grid__column'>
                  <IonRow>
                    <IonText color='secondary'>
                      {t('preferred_by_text', {
                        customerName: inquiry.getIn([
                          'preferred_customer',
                          'display_name'
                        ])
                      })}
                    </IonText>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </Segment>
        )}
        {inquiry.get('has_logistics') ? (
          <>
            <Segment title={t('direction')} noPadding>
              <div
                alt='line'
                className={
                  inquiry.getIn('preferred_customer')
                    ? 'fev-lead-timeline'
                    : 'lead-timeline'
                }
              />

              <IonItem lines='none'>
                <IonLabel>
                  <img className='oval-icon' src='/assets/img/oval.png' alt='Marker' />
                  <IonText className='lead-direction__title'>จุดรับ</IonText>
                </IonLabel>

                <IonText slot='end' className='lead-direction__title'>
                  <TimeRangeFormatter
                    value={{
                      startTime: inquiry.get('start_time'),
                      endTime: inquiry.get('start_time')
                    }}
                  />
                </IonText>
              </IonItem>

              <IonItem lines='none'>
                <IonLabel>
                  <IonText className='lead-direction__title-subtitle'>
                    {inquiry.getIn(['route', 'origin', 'masked_address'])}
                  </IonText>
                </IonLabel>
              </IonItem>

              <IonItem lines='none'>
                <IonLabel>
                  <img
                    className='marker-icon'
                    src='/assets/img/map_marker.png'
                    alt='Marker'
                  />
                  <IonText className='lead-direction__title'>จุดส่ง</IonText>
                </IonLabel>
              </IonItem>

              <IonItem lines='none'>
                <IonLabel>
                  <IonText className='lead-direction__title-subtitle'>
                    {inquiry.getIn(['route', 'destination', 'masked_address'])}
                  </IonText>
                </IonLabel>
              </IonItem>

              <IonRouterLink routerLink={`${match.url}/map`}>
                <GoogleStaticMap
                  center={{
                    latitude: inquiry.getIn(['route', 'origin', 'latitude']),
                    longitude: inquiry.getIn(['route', 'origin', 'longitude'])
                  }}
                  route={inquiry.get('route')}
                  marker='multiCircles'
                  offSetMarkerCenter
                />
              </IonRouterLink>

              <IonItem lines='none'>
                <IonText className='lead-direction-description'>
                  งานจะอยู่ในรัศมีวงกลม โดยระบบจะแสดงสถานที่ โดยละเอียดหากคุณกดรับงานแล้ว
                </IonText>
              </IonItem>
            </Segment>

            <JobStartTimeSegment jobs={inquiry.get('jobs')} isDelivery />

            {inquiry.get('answers') && (
              <Segment title={t('job_details')}>
                {inquiry.get('answers').map((answer) => (
                  <QuestionAnswer key={answer.get('id')} data={answer} />
                ))}
              </Segment>
            )}
          </>
        ) : (
          <>
            <Segment
              title={t('location')}
              chip={
                <>
                  {distanceEnabled &&
                    (provider.get('home_address') ||
                      provider.getIn(['home_address', 'latitude']) ||
                      provider.getIn(['home_address', 'longitude'])) && (
                      <IonChip
                        className={
                          parseFloat(distance) >
                          parseFloat(provider.get('available_distance'))
                            ? 'far-chip'
                            : 'near-chip'
                        }
                      >
                        <IonLabel
                          className={
                            parseFloat(distance) >
                            parseFloat(provider.get('available_distance'))
                              ? 'far-chip-label'
                              : 'near-chip-label'
                          }
                        >
                          ห่างจากบ้านคุณ {distance} กม.
                        </IonLabel>
                      </IonChip>
                    )}

                  {isAvailableDate && (
                    <IonChip className='available-show-chip'>
                      <IonLabel className='available-chip-label'>วันพร้อมรับงาน</IonLabel>
                    </IonChip>
                  )}
                  {isDisableDate && (
                    <IonChip className='disabled-show-chip'>
                      <IonLabel className='disabled-chip-label'>งานในวันหยุด</IonLabel>
                    </IonChip>
                  )}
                </>
              }
              noPadding
            >
              <IonText className='lead-address'>
                {inquiry.getIn(['location', 'masked_address'])}
              </IonText>

              <IonRouterLink routerLink={`${match.url}/map`}>
                <GoogleStaticMap
                  center={{
                    latitude: inquiry.getIn(['location', 'latitude']),
                    longitude: inquiry.getIn(['location', 'longitude'])
                  }}
                  marker='circle'
                  offSetMarkerCenter
                />
              </IonRouterLink>
            </Segment>

            <JobStartTimeSegment
              jobs={inquiry.get('jobs')}
              restDates={restDates}
              availableDates={availableDates}
            />
            <JobAdditionalInfoSegment
              additionalInfo={inquiry.get('additional_information')}
            />
            <JobDetailsSegment
              number={inquiry.get('number')}
              service={inquiry.get('service')}
              orders={inquiry.get('orders')}
              answers={inquiry.get('answers')}
              attachments={inquiry.get('attachments')}
              hasSpecial={hasSpecial}
              setHasSpecial={setHasSpecial}
            />
          </>
        )}
        <IonAlert
          isOpen={showAlertAccept}
          onDidDismiss={() => setShowAlertAccept(false)}
          header={t('job_already_accept_title')}
          message={t('job_already_accept_message')}
          buttons={[
            {
              text: t('okay'),
              handler: handleCannotAccept
            }
          ]}
        />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={t('accept_job_confirmation_title')}
          message={t('accept_job_confirmation_message')}
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('accept_job'),
              handler: handleAcceptLead
            }
          ]}
        />
        <VerifyPhoneAlert
          isOpen={showVerifyAlert}
          header={t('alert.header_lead')}
          message={t('alert.message_lead', { tenant: tenant.get('name') })}
          cancel={t('alert.cancel_lead')}
          confirm={t('alert.confirm_lead')}
          dissmiss={() => setShowVerifyAlert(false)}
          openedFrom={`/leads/${match.params.id}`}
        />
        <IonAlert
          isOpen={showAlerWithDisabled}
          onDidDismiss={() => setShowAlertWithDisabled(false)}
          header='ใบงานมีวันที่ตรงกับวันหยุด
          ของคุณ'
          message='ยืนยันที่จะรับงานใช่หรือไม่?'
          cssClass='alert-disabled-date'
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('accept_job'),
              handler: handleAcceptLead
            }
          ]}
        />
      </PageContent>
      <PageFooter>
        <JobActionFooter
          paymentMethod={inquiry.get('payment_method')}
          chargeCustomerPrice={inquiry.get('price')}
          payout={inquiry.get('payout')}
          onActionClick={() =>
            !provider.get('verified')
              ? setShowVerifyAlert(true)
              : isDisableDate
              ? setShowAlertWithDisabled(true)
              : setShowAlert(true)
          }
          actionLabel={t('accept_job')}
          isLoading={isLoading}
        />
      </PageFooter>
      {isLoading && <Spinner overlaid />}
    </PageLayout>
  )
}

LeadShowPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default LeadShowPage
