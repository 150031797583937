export const formatPhoneString = (phoneNumber, countryCode) => {
  if (phoneNumber !== undefined && countryCode !== undefined)
    if (phoneNumber?.length === 10 && countryCode?.length > 0) {
      return `${countryCode} ${phoneNumber.substring(1, 3)} ${phoneNumber.substring(
        3,
        6
      )} ${phoneNumber.substr(6, 9)}`
    } else if (phoneNumber.length === 9 && countryCode.length > 0) {
      return `${countryCode} ${phoneNumber.substring(0, 4)} ${phoneNumber.substring(
        4,
        6
      )} ${phoneNumber.substr(6, 9)}`
    }
  return ''
}

export const seperateDialCodeFromPhoneNumber = (phoneNumber, dialCode) =>
  phoneNumber.substring(dialCode.length, phoneNumber.length)

export const removeZeroFromPhoneNumber = (phoneNumber) =>
  phoneNumber.substring(1, phoneNumber.length)
