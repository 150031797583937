import React, { useState } from 'react'
import { Field } from 'formik'
import { IonPicker, IonAlert } from '@ionic/react'
import Input from 'components/inputs/Input'

import './TimeRangeField.scss'

const TimeRangeField = ({ name, label, dateRange = {}, placeholder, disabled }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAlert, setIsAlert] = useState(false)

  const DayColumn = {
    name: 'start',
    options: [
      { text: '9.00', value: '9' },
      { text: '10.00', value: '10' },
      { text: '11.00', value: '11' },
      { text: '12.00', value: '12' },
      { text: '13.00', value: '13' },
      { text: '14.00', value: '14' },
      { text: '15.00', value: '15' },
      { text: '16.00', value: '16' },
      { text: '17.00', value: '17' }
    ]
  }

  const SessionTimeColumn = {
    name: 'end',
    options: [
      { text: '10.00', value: '10' },
      { text: '11.00', value: '11' },
      { text: '12.00', value: '12' },
      { text: '13.00', value: '13' },
      { text: '14.00', value: '14' },
      { text: '15.00', value: '15' },
      { text: '16.00', value: '16' },
      { text: '17.00', value: '17' },
      { text: '18.00', value: '18' }
    ]
  }

  return (
    <Field
      name={name}
      render={({ form: { setFieldValue, values }, field }) => (
        <div className='form-field'>
          <div className='date-range-picker-label'>
            <label>{`${label}`}</label>
          </div>

          <div onClick={() => (disabled ? setIsOpen(false) : setIsOpen(true))}>
            <Input
              {...field}
              disabled
              value={field.value || ''}
              onChange={(value) => setFieldValue(name, value)}
              placeholder={placeholder || ''}
              className='input-time-range'
              // required={required}
              // children={children}
              // {...rest}
            />
          </div>

          <IonPicker
            isOpen={isOpen}
            columns={[DayColumn, SessionTimeColumn]}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  setIsOpen(false)
                }
              },
              {
                text: 'Confirm',
                handler: (value) => {
                  if (parseInt(value.start.value) < parseInt(value.end.value)) {
                    setFieldValue(name, `${value.start.text}-${value.end.text}`)
                    setIsOpen(false)
                  } else {
                    setIsOpen(false)
                    setIsAlert(true)
                  }
                }
              }
            ]}
          />

          <IonAlert
            isOpen={isAlert}
            onDidDismiss={() => setIsAlert(false)}
            message='ไม่สามารถเลือกเวลาเริ่มมากกว่าเวลาสิ้นสุดได้กรุณาเลือกใหม่อีกครั้ง'
            buttons={[
              {
                text: 'ตกลง',
                role: 'cancel',
                cssClass: 'secondary'
              }
            ]}
          />
        </div>
      )}
    />
  )
}

export default TimeRangeField
