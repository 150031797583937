import {
  clearTeam,
  fetchTeamResource,
  setJob as setJobAction,
  teamPostRequest as postRequest
} from 'actions/teamDistribution'
import { useDispatch, useSelector } from 'react-redux'

export const useTeamDistribution = () => {
  const dispatch = useDispatch()
  const teamState = useSelector((state) => state.get('teamDistribution'))

  const fetchTeam = async (endpoint, option = {}) =>
    dispatch(fetchTeamResource(endpoint, option))

  const teamPostRequest = async (endpoint, data) => dispatch(postRequest(endpoint, data))

  const setJob = (job) => dispatch(setJobAction(job))

  const currentTeam = useSelector((state) =>
    state.getIn(['teamDistribution', 'currentTeam'])
  )

  const auth = useSelector((state) => state.getIn(['teamDistribution', 'auth']))

  const teams = useSelector((state) => {
    const auth = state.getIn(['teamDistribution', 'auth'])

    return (
      auth.user.teams &&
      auth.user.teams.filter((team) => team.leader._id === auth.user._id)
    )
  })

  return {
    team: teamState.get('team'),
    jobSearch: teamState.get('jobSearch'),
    teamJob: teamState.get('job'),
    fetchTeam,
    teamPostRequest,
    clearTeam,
    setJob,
    auth,
    currentTeam,
    teams
  }
}
