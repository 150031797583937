import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

export const removeKeys = async (keys) => {
  await keys.forEach((key) => Storage.remove({ key }))
}

export const setStorageKey = async (key, value) => {
  await Storage.set({
    key,
    value
  })
}

export const getStorageValue = async (key) => {
  const result = await Storage.get({ key })
  return result.value
}
