import { PageHeader, PageLayout } from 'components/layout'
import OtpForm from 'forms/OtpForm'
import React, { useState } from 'react'
import { errorHasResponseBody, errorIsAlert } from 'utils/verification'
import './VerifyAuthenticationPage.scss'
import { useGetNumberInfo } from 'hooks/phone_verification'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  signInWithPhoneNumberSendOtp,
  signInWithPhoneNumberVerify,
  signUpSucceeded,
  updateCurrentDevice
} from 'actions/authentication'
import { IonContent, IonGrid, IonRow, IonText } from '@ionic/react'
import Timer from 'components/Timer'
import AdditionalInfoBox from 'components/AdditionalInfoBox'
import VerifyAlertError from 'components/alerts/VerifyAlertError'
import { formatPhoneString } from 'utils/stringUtils'
import Subheader from 'components/typography/Subheader'
import Header from 'components/typography/Header/Header'
import Paragraph from 'components/typography/Paragraph'
import { getCurrentResource } from 'selectors/authentication'
import { tenantSchema } from '@seekster/schemas'

import './VerifyAuthenticationPage.scss'
import { Plugins } from '@capacitor/core'
import Spinner from 'components/Spinner'

const { Storage } = Plugins
const VerifyAuthenticationPage = ({ history }) => {
  const { t } = useTranslation(['verify_phone_number', 'sign_up'])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [alertError, setAlertError] = useState({
    display: false,
    header: '',
    body: ''
  })
  const { phoneNumber, countryCode } = useGetNumberInfo()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()

  const formattedPhoneNumber = formatPhoneString(phoneNumber, countryCode)

  const dispatch = useDispatch()

  const verify = (otpCode, phoneNumber) =>
    dispatch(signInWithPhoneNumberVerify(phoneNumber, otpCode))

  const handleReSendOtp = async (phoneNumber) =>
    dispatch(signInWithPhoneNumberSendOtp(phoneNumber)).catch((error) =>
      handleError(error)
    )

  const handleSubmit = async (otp) => {
    setLoading(true)
    verify(otp, phoneNumber)
      .then((response) => {
        Storage.set({
          key: 'accessToken',
          value: response.body.access_token
        })

        dispatch(signUpSucceeded(response))
        return Storage.get({ key: 'locale' })
      })
      .then(({ value }) => {
        dispatch(updateCurrentDevice({ locale: value }))
        history.push('/sign_up/set_password')
      })
      .finally(() => setLoading(false))
      .catch((error) => handleError(error))
  }

  const handleError = (error) => {
    if (errorHasResponseBody(error)) {
      if (errorIsAlert(error.response.body.error)) {
        setAlertError({
          display: true,
          header: t(`errors.${error.response.body.error}`),
          body: error.response.body.message
        })
        if (
          error.response.body.error === 'verificaition_suspension' ||
          error.response.body.error === 'verificaition_suspension_admin'
        ) {
        }
        return
      } else {
        setError('')
        return setError(error.response.body.message)
      }
    }
    setError(error)
  }

  return (
    <PageLayout
      withSafeAreaBottom
      withSafeAreaTop
      className='sign-up-page-verify-core-layout'
    >
      <IonContent>
        <PageHeader
          title={t('verify_authentication.title')}
          withBackButton
          backTo={'/sign_up'}
        />
        <IonGrid className='sign-up-verify-page-container'>
          <div className='text-container'>
            <IonRow className='subheader-container'>
              <Subheader>{t('verify_authentication.subheader')}</Subheader>
            </IonRow>
            <IonRow className='header-container'>
              <Header>{t('verify_authentication.header')}</Header>
            </IonRow>
            <IonRow className='paragraph-container'>
              <Paragraph>
                {t('verify_authentication.paragraph', { tenant: tenant.get('name') })}
              </Paragraph>
            </IonRow>
            <IonRow className='phone-number-container'>
              <Subheader isInfo>{formattedPhoneNumber}</Subheader>
            </IonRow>
          </div>
          <IonRow className='otp-form-container'>
            {loading && <Spinner />}
            <OtpForm
              submit={(otp) => handleSubmit(otp)}
              hasErrors={error.length}
              resetError={() => setError('')}
            />
          </IonRow>
          {error && (
            <IonRow className='verify-phone-number-error-row'>
              <IonText className='verify-error'>{error}</IonText>
            </IonRow>
          )}
          <IonRow className='timer-row'>
            <IonRow>
              <Timer
                pre={t('timer.pre')}
                after={t('timer.after')}
                onReset={() => handleReSendOtp(phoneNumber)}
                resetPre={t('timer.resetPre')}
                resetAfter={t('timer.resetAfter')}
              />
            </IonRow>
            <IonRow>
              <AdditionalInfoBox>{t('info_box.text')}</AdditionalInfoBox>
            </IonRow>
          </IonRow>
        </IonGrid>
        <VerifyAlertError
          isOpen={alertError.display}
          errorBody={alertError.body}
          errorHeader={alertError.header}
          closeText={'Ok'}
          dissmiss={() =>
            setAlertError({
              display: false,
              body: '',
              headeR: ''
            })
          }
        />
      </IonContent>
    </PageLayout>
  )
}

export default VerifyAuthenticationPage
