export const generateOtp = async (genOtp, phoneNumber, handleError) =>
  genOtp(phoneNumber).catch((error) => handleError(error))
export const verifyOtp = async (verOtp, otp, navigate) =>
  verOtp(otp).then(() => navigate())

export const verifyOtpWithPhoneNumber = async (verOtp, otp, phoneNumber, navigate) => {
  try {
    await verOtp(otp, phoneNumber)
    navigate()
  } catch (error) {
    throw error
  }
}

export const errorIsAlert = (error) => {
  switch (error) {
    case 'could_not_send_sms':
    case 'verificaition_suspension_admin':
    case 'verificaition_suspension':
      return true
    case 'user_already_verified':
    case 'verification_database':
    case 'invalid_otp':
    case 'expired_otp':
    case 'otp_not_present':
      return false
    default:
      return false
  }
}

export const errorHasResponseBody = (error) => error?.response?.body !== undefined
