import React from 'react'
import './LHeader.scss'
import PropTypes from 'prop-types'

const LHeader = ({ children }) => {
  return <h1 className='typo-lheader'>{children}</h1>
}

LHeader.propTypes = {
  children: PropTypes.string
}

export default LHeader
