import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { IonButton, IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { providerSchema } from '@seekster/schemas'

import { useResource, useResourceActions } from 'hooks/resources'
import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import AddressForm from 'forms/AddressForm'
import Spinner from 'components/Spinner'

import './AddressPage.scss'

const AddressPage = ({ history }) => {
  const { t } = useTranslation('settings')
  const submitButtonRef = useRef()
  const [showUpdateSucceededAlert, setShowUpdateSucceededAlert] = useState(false)

  const [provider, { loading }] = useResource('/provider', providerSchema, { implicit: true })
  const { update: updateProfile, loading: updating } = useResourceActions(providerSchema)

  const handleSubmit = (data) => {
    return updateProfile('/provider', data)
      .then(() => setShowUpdateSucceededAlert(true))
  }

  const handleDismissAlert = () => {
    setShowUpdateSucceededAlert(false)

    history.push('/settings')
  }

  return (
    <PageLayout className='address-page'>
      <PageHeader
        title={t('fill_current_address')}
        withBackButton
        backTo='/settings'
      />

      <PageContent loading={loading}>
        <AddressForm
          data={provider}
          onSubmit={handleSubmit}
          submitButtonRef={submitButtonRef}
        />

        <IonAlert
          isOpen={showUpdateSucceededAlert}
          onDidDismiss={handleDismissAlert}
          message={t('profile_updated')}
          buttons={[t('okay')]}
        />
      </PageContent>

      <PageFooter className='address-footer-container'>
        <IonButton
          expand='block'
          onClick={() => {
            submitButtonRef.current.click()
          }}
          disabled={updating}
          color='light'
          className='submit-btn'
        >
          {t('save')}
        </IonButton>
      </PageFooter>

      {updating && <Spinner overlaid />}
    </PageLayout>
  )
}

AddressPage.propTypes = {
  history: PropTypes.object
}

export default AddressPage
