import React from 'react'
import PropTypes from 'prop-types'
import { IonInput, IonItem, IonTextarea } from '@ionic/react'
import clsx from 'clsx'

import './Input.scss'

const Input = React.forwardRef(
  ({ value, onChange, onBlur, children, error, textarea, ...rest }, ref) => {
    const props = {
      ...rest,
      onIonChange: (e) => onChange(e.target.value, e),
      onIonBlur: onBlur,
      value
    }

    return (
      <IonItem
        className={clsx(
          'input-container',
          'input-container--basic',
          Boolean(error) && 'input-container--error'
        )}
        lines='none'
      >
        {textarea ? <IonTextarea {...props} /> : <IonInput {...props} ref={ref} />}

        {children}
      </IonItem>
    )
  }
)

Input.defaultProps = {
  value: ''
}

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.string,
  textarea: PropTypes.bool
}

export default Input
