import { Plugins } from '@capacitor/core'
import { getBasicAuth } from 'selectors/authentication'
import teamRequest from 'utils/teamRequest'
import {
  SET_CURRENT_TEAM,
  SET_JOB_SEARCH,
  CLEAR_TEAM,
  SET_JOB,
  TD_AUTHENTICATED,
  SET_TEAM_DOCK
} from 'constants/teamDistribution'
const { Storage } = Plugins

export const teamPostRequest = (endpoint, data) => async (dispatch, getState) => {
  const tdAccessToken = await Storage.get({ key: 'tdAccessToken' })

  return teamRequest
    .auth(...getBasicAuth(getState()))
    .post(endpoint)
    .send(data)
    .authentication(tdAccessToken.value)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export const fetchTeamResource =
  (endpoint, options = {}) =>
  async (dispatch, getState) => {
    const { query = {} } = options
    const { locale, ...rest } = query
    const queryToServer = { ...options.query }
    const tdAccessToken = await Storage.get({ key: 'tdAccessToken' })

    return teamRequest
      .auth(...getBasicAuth(getState()))
      .get(endpoint)
      .query({ ...rest, ...queryToServer })
      .authentication(tdAccessToken.value)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }

export const setCurrentTeam = (team) => (dispatch) => {
  Storage.set({ key: 'currentTeamId', value: team._id })

  dispatch({
    type: SET_CURRENT_TEAM,
    payload: team
  })
}

export const selectTeamDock = (state) => {
  return state.getIn(['teamDistribution', 'teamDock'])
}

export const searchJob = (text) => {
  return {
    type: SET_JOB_SEARCH,
    payload: { jobSearch: text }
  }
}

export const setJob = (job) => (dispatch) => {
  return dispatch({
    type: SET_JOB,
    payload: {
      job
    }
  })
}

export const authenticate = () => async (dispatch) => {
  try {
    const [accessToken, currentTeamId] = await Promise.all([
      Storage.get({ key: 'accessToken' }),
      Storage.get({ key: 'currentTeamId' })
    ])

    const { body: userAuth } = await teamRequest.post('/auth/wf').send({
      accessToken: accessToken.value
    })

    if (userAuth && currentTeamId.value) {
      const currentTeam = userAuth.user.teams.find(
        (team) => team._id === currentTeamId.value
      )

      dispatch({
        type: SET_CURRENT_TEAM,
        payload: currentTeam
      })
    }

    Storage.set({ key: 'tdAccessToken', value: userAuth.token })

    return dispatch({
      type: TD_AUTHENTICATED,
      payload: userAuth
    })
  } catch (error) {
    throw error
  }
}

export const handleTeamDock = (isShow) => (dispatch) => {
  dispatch({
    type: SET_TEAM_DOCK,
    payload: { isShow }
  })
}

export const clearTeam = () => async (dispatch) => {
  await Storage.remove({ key: 'currentTeamId' })
  dispatch({ type: CLEAR_TEAM })
}
