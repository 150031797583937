import React from 'react'
import ManageCalendarForm from 'forms/ManageCalendarForm'

import './MyCalendarDisablePage.scss'

const MyCalendarDisablePage = ({ history }) => {
  return <ManageCalendarForm history={history} isDisabled />
}

export default MyCalendarDisablePage
