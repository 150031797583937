import React, { useCallback, useEffect, useState } from 'react'
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonList,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react'

import { close } from 'ionicons/icons'

import './CountryModal.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next/'

const CountryModal = ({ modalOpen, setModalOpen, chooseCountry, countries }) => {
  const [filteredCountries, setFileteredCountries] = useState(countries)
  const [searchInput, setSearchInput] = useState('')
  const { t } = useTranslation(['verify_phone_number'])
  const filterCountries = useCallback(() => {
    if (searchInput.startsWith('+')) {
      return setFileteredCountries([
        ...countries.filter((country) => country.dialCode.includes(searchInput))
      ])
    }
    return setFileteredCountries([
      ...countries.filter((country) =>
        country.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    ])
  }, [countries, searchInput])
  const handleClose = () => {
    setFileteredCountries([...countries])
    setModalOpen(false)
  }
  useEffect(() => {
    filterCountries()
  }, [searchInput, filterCountries])
  return (
    <IonModal
      isOpen={modalOpen}
      onDidDismiss={() => setModalOpen(false)}
      className='country-modal'
    >
      <IonHeader className='country-modal-header-section ion-no-border'>
        <IonToolbar>
          <IonTitle>{t('modal.header')}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => handleClose()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonInput
          onIonChange={(e) => setSearchInput(e.detail.value)}
          className='country-modal-input'
          placeholder={t('modal.search')}
        />
      </IonHeader>
      <IonContent className='country-modal-content-section'>
        <IonList className='country-modal-list'>
          {filteredCountries.map((country) => (
            <IonGrid
              className='country-item'
              onClick={() => chooseCountry(country)}
              key={country.isoCode}
            >
              <IonRow className='country-row'>
                <IonCol size='3'>
                  <IonAvatar className='country-avatar'>
                    <IonImg src={country.flag} />
                  </IonAvatar>
                </IonCol>
                <IonCol size='7' className='country-name'>
                  <IonText>{country.name}</IonText>
                </IonCol>
                <IonCol size='2' className='country-code'>
                  <IonText>{country.dialCode}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  )
}
CountryModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  chooseCountry: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.object)
}

export default CountryModal
