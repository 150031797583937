import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { Field } from 'formik'
import { get } from 'lodash'
import { IonIcon } from '@ionic/react'
import { arrowForwardOutline } from 'ionicons/icons'

import './DateRangeField.scss'

const DateRangeField = ({ name, date, onRemoveCard, label, dateRange = {} }) => {
  const [focusInput, setFocusInput] = useState(null)

  return (
    <Field
      name={name}
      render={({
      form: { setFieldValue, values }
    }) => (
      <div className='form-field'>
        <div className='date-range-picker-label'>
          <label>{`${label}`}</label>
        </div>
        <DateRangePicker
          readOnly
          startDateId='startDateId'
          endDateId='endDateId'
          startDate={
            get(values, [name, 'from'])
              ? moment(get(values, [name, 'from']))
              : (dateRange !== {} && dateRange?.from !== undefined ? moment(dateRange?.from) : null)
          }
          endDate={
            get(values, [name, 'to'])
              ? moment(get(values, [name, 'to']))
              : (dateRange !== {} && dateRange?.to !== undefined ? moment(dateRange?.to) : null)
          }
          onDatesChange={({ startDate, endDate }) => (
            setFieldValue(
              name,
              {
                from: startDate
                  ? moment(startDate).startOf('day').format('YYYY-MM-DD')
                  : null,
                to: endDate
                  ? moment(endDate).endOf('day').format('YYYY-MM-DD')
                  : null
              }
            )
          )}
          focusedInput={focusInput}
          onFocusChange={focusInput => setFocusInput(focusInput)}
          isOutsideRange={() => false}
          numberOfMonths={1}
          minimumNights={0}
          block
          startDatePlaceholderText='งานวันที่'
          endDatePlaceholderText='ถึงวันที่'
          showClearDates={false}
          regular
          customArrowIcon={<IonIcon icon={arrowForwardOutline} />}
        />
      </div>
    )}
    />
  )
}

export default DateRangeField
