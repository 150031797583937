import React from 'react'
import PropTypes from 'prop-types'

export function HtmlFormatter({ value, className }) {
  return <div className={className} dangerouslySetInnerHTML={{ __html: value }} />
}

HtmlFormatter.propTypes = {
  value: PropTypes.string,
  className: PropTypes.node
}

export default HtmlFormatter
