import React from 'react'
import PropTypes from 'prop-types'
import { IonLabel } from '@ionic/react'

import './EmptyListPlaceholder.scss'

const EmptyListPlaceholder = ({ message }) => {
  return (
    <div className='empty-list-message__container'>
      <IonLabel color='medium'>{message}</IonLabel>
    </div>
  )
}

EmptyListPlaceholder.propTypes = {
  message: PropTypes.string
}

export default EmptyListPlaceholder
