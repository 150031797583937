import React from 'react'
import PropTypes from 'prop-types'
import {
  Capacitor, Plugins, FilesystemDirectory,
  CameraResultType, CameraSource
} from '@capacitor/core'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { IonButton, IonIcon, isPlatform } from '@ionic/react'
import { camera, closeCircle } from 'ionicons/icons'
import uuid4 from 'uuid/v4'
import { useHistory } from 'react-router-dom'

import './CameraInput.scss'

const { Camera, Filesystem } = Plugins

defineCustomElements(window)

const CameraInput = ({ value, onChange, isMulti, cameraOptions, required }) => {
  const { location, push } = useHistory()

  const takePhoto = async () => {
    try {
      const options = {
        quality: isPlatform('ios') ? 5 : 20,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        ...cameraOptions
      }

      const originalPhoto = await Camera.getPhoto(options)
      const path = originalPhoto.path || originalPhoto.webPath
      const photoInTempStorage = await Filesystem.readFile({ path })
      const fileName = uuid4()

      await Filesystem.writeFile({
        data: photoInTempStorage.data,
        path: `${fileName}.jpeg`,
        directory: FilesystemDirectory.Data
      })

      const finalPhotoUri = await Filesystem.getUri({
        path: `${fileName}.jpeg`,
        directory: FilesystemDirectory.Data
      })

      const photoPath = Capacitor.convertFileSrc(finalPhotoUri.uri)
      let newValue = photoPath

      if (isMulti) {
        const currenyPhotoPaths = value || []

        newValue = [...currenyPhotoPaths, { file: photoPath }]
      }

      onChange && onChange(newValue)
    }
    catch (e) {
      console.error('ERROR', e)
    }
  }

  const handleDeletePhoto = async (e, uri) => {
    e.stopPropagation()

    if (isPlatform('ios')) {
      await Filesystem.deleteFile({
        path: uri,
        directory: FilesystemDirectory.Data
      })
    }

    let newValue = ''

    if (isMulti) {
      newValue = value.filter(_value => _value.file !== uri)
    }

    onChange && onChange(newValue)
  }

  const handleClickThumbnail = (i) => {
    const getUrls = () => {
      if (isMulti) {
        const paths = value || []

        return paths.map(({ file } = {}) => file)
      }
      else {
        return [value]
      }
    }

    push(`${location.pathname}/report_photos`, {
      urls: getUrls(),
      initialSlide: i
    })
  }

  const renderThumbnail = () => {
    const thumbnail = (uri, i) => (
      <div
        key={uri}
        className='photo-thumbnail__container'
        onClick={() => handleClickThumbnail(i)}
      >
        <img src={uri} alt='Report_Photo' />

        <IonButton
          className='photo-thumbnail__remove-button'
          shape='round'
          fill='clear'
          size='small'
          onClick={(e) => handleDeletePhoto(e, uri)}
        >
          <IonIcon icon={closeCircle} size='large' color='medium' />
        </IonButton>
      </div>
    )

    if (isMulti) {
      const paths = value || []

      return paths.map(({ file } = {}, i) => file && thumbnail(file, i))
    }
    else {
      return value && thumbnail(value, 0)
    }
  }

  return (
    <div className='camera-input-button__container'>
      <IonButton
        className='camera-input-button'
        color='light'
        onClick={takePhoto}
      >
        <IonIcon icon={camera} size='large' color='medium' />
      </IonButton>

      {renderThumbnail()}

      <input
        required={required}
        value={value}
        style={{ opacity: 0, height: 0, position: 'absolute', margin: '120px 0 0 50px' }}
      />
    </div>
  )
}

CameraInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  cameraOptions: PropTypes.object,
  required: PropTypes.bool
}

export default CameraInput
