import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'

import ProfileForm from './ProfileForm'

const formikConfig = {
  mapPropsToValues: ({ data = Map() }) => ({
    gender: data.get('gender') || 'male',
    first_name: data.get('first_name'),
    last_name: data.get('last_name'),
    email: data.get('email'),
    date_of_birth: data.get('date_of_birth'),
    phone_number: data.get('phone_number')
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().email(),
    phone_number: Yup.string()
      .matches(/^0[23456789]\d{7,8}$/, 'Invalid phone number')
      .required()
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(ProfileForm)
