import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function RelativeTimeIntevalFormatter ({ value }) {
  const { t } = useTranslation('conversations')
  const dayDiff = moment().diff(moment(value), 'days')

  if (dayDiff === 0) {
    return t('today')
  }
  else if (dayDiff === 1) {
    return t('yesterday')
  }
  else {
    return value
      ? moment(value).format('DD MMM YYYY')
      : '-'
  }
}

export default RelativeTimeIntevalFormatter
