import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const DateFormatter = ({ value, format, ...rest }) => {
  return (
    <span {...rest}>
      {moment(value).format(format)}
    </span>
  )
}

DateFormatter.defaultProps = {
  format: 'dddd, DD MMM YYYY'
}

DateFormatter.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string
}

export default DateFormatter
