import React, { useState } from 'react'
import { Map } from 'immutable'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonImg,
  IonModal,
  IonButton,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonHeader
} from '@ionic/react'

import { closeOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useCurrentLocation } from 'hooks/interfaces'
import moment from 'moment'
import { PageContent } from 'components/layout'
import QuestionAnswer from 'components/QuestionAnswer'
import Segment from 'components/Segment'

import GoogleRouteMap from 'components/GoogleRouteMap'

import './JobDeliveryDetailsPane.scss'

const JobDeliveryDetailsPane = ({ jobProvider }) => {
  const { t } = useTranslation('jobs')
  const [{ latitude, longitude }] = useCurrentLocation()
  const [showModal, setShowModal] = useState(false)

  const isHeadingToOrigin = ['pending_start', 'heading_to_origin'].includes(
    jobProvider.get('state')
  )
  const route = jobProvider.getIn(['job', 'route']) || Map()

  const getMapDirectionUrl = () => {
    const destinationLat = isHeadingToOrigin
      ? route.getIn(['origin', 'latitude'])
      : route.getIn(['destination', 'latitude'])
    const destinationLng = isHeadingToOrigin
      ? route.getIn(['origin', 'longitude'])
      : route.getIn(['destination', 'longitude'])

    return `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destinationLat},${destinationLng}`
  }

  return (
    <>
      <IonContent>
        <IonGrid className='job-delivery-container'>
          <IonRow>
            <IonCol
              size='5'
              className={
                isHeadingToOrigin ? 'content-col-center-active' : 'content-col-center'
              }
            >
              {t('delivery.heading_to_origin')}
            </IonCol>
            <IonCol size='2' className='content-col-center-active'>
              <IonImg
                src='/assets/img/next-icon.png'
                alt='directions'
                className='next-icon'
              />
            </IonCol>
            <IonCol
              size='5'
              className={
                isHeadingToOrigin ? 'content-col-center' : 'content-col-center-active'
              }
            >
              {t('delivery.heading_to_destination')}
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonCard className='address-delivery-card'>
          <IonCardHeader className='address-delivery-card-header'>
            <IonCardTitle>
              <IonGrid>
                <IonRow>
                  <IonCol size='9'>
                    {isHeadingToOrigin
                      ? t('delivery.picking_up', {
                          time: moment(jobProvider.getIn(['job', 'start_time'])).format(
                            'H:mm'
                          )
                        })
                      : t('delivery.heading_to_destination')}
                  </IonCol>
                  <IonCol size='3'>
                    <IonButton className='direction-button' href={getMapDirectionUrl()}>
                      <IonImg
                        src='/assets/img/directions.png'
                        alt='directions'
                        className='direction-image'
                      />
                      {t('delivery.nevigate')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardTitle>
          </IonCardHeader>

          {/* <IonButton slot='end'>View</IonButton> */}

          <IonCardContent>
            {isHeadingToOrigin
              ? `${jobProvider.getIn([
                  'job',
                  'route',
                  'origin',
                  'contact_name'
                ])}, ${jobProvider.getIn(['job', 'route', 'origin', 'full_address'])}`
              : `${jobProvider.getIn([
                  'job',
                  'route',
                  'destination',
                  'contact_name'
                ])}, ${jobProvider.getIn([
                  'job',
                  'route',
                  'destination',
                  'full_address'
                ])}`}
          </IonCardContent>

          <IonButton
            className='details-button'
            // expand='block'
            fill='outline'
            onClick={() => setShowModal(true)}
          >
            <div>รายละเอียดเพิ่มเติม</div>
          </IonButton>
        </IonCard>
        <GoogleRouteMap
          originRoute={isHeadingToOrigin}
          route={route}
          startTime={jobProvider.getIn(['job', 'start_time'])}
          currentLocation={{ latitude, longitude }}
        />
      </IonContent>
      <IonModal isOpen={showModal}>
        <>
          <IonHeader>
            <IonToolbar color='primary' className='modal-toolbar'>
              <IonButtons slot='end'>
                <IonButton onClick={() => setShowModal(false)}>
                  <IonIcon slot='end' icon={closeOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle className='page-title'>รายละเอียดเพิ่มเติม</IonTitle>
            </IonToolbar>
          </IonHeader>

          <PageContent className='calendar-page'>
            <div className='content-calendar-page'>
              <Segment>
                {jobProvider.get('answers') &&
                  jobProvider
                    .get('answers')
                    .map((answer) => (
                      <QuestionAnswer key={answer.get('id')} data={answer} />
                    ))}
              </Segment>
            </div>
          </PageContent>
        </>
      </IonModal>
    </>
  )
}

export default JobDeliveryDetailsPane
