import leadsQuery from './queries/leadsQuery'

export const selectQueryToServer = (schema, options) => {
  const queryObject = options.query || {}

  switch (schema._key) {
    case 'leads':
      return leadsQuery(queryObject.filter)
    default:
      return {}
  }
}

export default selectQueryToServer
