import React, { useState, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { search } from 'ionicons/icons'
import { Plugins } from '@capacitor/core'
import { IonInput, IonIcon } from '@ionic/react'

import { useSearchHistory } from 'utils/useSearchHistory'
import { useHandleOutside } from 'utils/useHandleOutside'
import historyIcon from 'assets/png/history_icon.png'

import './SearchInput.scss'

const SearchInput = forwardRef(
  ({ onSearch, count, loading, placeholder, showHistory = true }, ref) => {
    const { Keyboard } = Plugins
    const [focus, setFocus] = useState(false)
    const [input, setInput] = useState('')
    const [searched, setSearched] = useState(false)
    const { t } = useTranslation('translation')

    const wrapperRef = useRef()

    function handleSearch(value) {
      if (value && !searched) {
        setSearched(true)
      } else if (!value && searched) {
        setSearched(false)
      }

      onSearch(value)
      handleBlur()
      Keyboard.hide()
    }

    function handleKeywordChange(event) {
      setInput(event.target.value)
    }

    function handleSelectHistory(value, e) {
      cancelEventFromParent(e)

      setInput(value)
      handleSearch(value)
      handleBlur()
      ref.current.value = value
    }

    function handleKeyUp(event) {
      if (event.key === 'Enter') {
        handleSearch(ref.current.value)
      }
    }

    function handleCancel(e) {
      cancelEventFromParent(e)

      setInput('')
      handleSearch('')
      ref.current.value = ''
    }

    function handleFocus() {
      setFocus(true)
    }

    function handleBlur() {
      setFocus(false)
    }

    function cancelEventFromParent(e) {
      e.stopPropagation()
    }

    useHandleOutside(wrapperRef, handleBlur)

    return (
      <div className='search-input-container' ref={wrapperRef} onClick={handleBlur}>
        <div className='search-input-group'>
          <div className='search-input'>
            <IonIcon icon={search} />
            <IonInput
              ref={ref}
              enterkeyhint='search'
              onIonChange={handleKeywordChange}
              onIonFocus={handleFocus}
              onKeyUp={handleKeyUp}
              placeholder={placeholder}
              onClick={cancelEventFromParent}
              debounce={500}
            />
          </div>
          <div className='close-btn' onClick={handleCancel}>
            {t('clear')}
          </div>
        </div>

        {showHistory && (
          <HistoryList
            textSearch={input}
            inputFocused={focus}
            onSelect={handleSelectHistory}
          />
        )}

        {/* {showSearchCount && (
          <div className='search-result'>
            {t('search_result', { search_count: count })}
          </div>
        )} */}
      </div>
    )
  }
)

function HistoryList({ textSearch, inputFocused, onSelect }) {
  const { data: histories } = useSearchHistory(textSearch)

  return !!histories.length && inputFocused ? (
    <ul>
      <li>
        <h6>Recent search</h6>
      </li>
      {histories.map((history) => (
        <li key={history.id} onClick={(e) => onSelect(history.keyword, e)}>
          <img className='history-icon' src={historyIcon} alt='history-icon' />
          <label>{history.keyword}</label>
        </li>
      ))}
    </ul>
  ) : null
}

export default SearchInput
