import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { useHistory } from 'react-router-dom'
import { Map } from 'immutable'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonChip,
  IonLabel,
  IonButton
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import './JobActionFooter.scss'

const JobActionFooter = ({
  isLoading,
  paymentMethod,
  footer,
  payout,
  chargeCustomerPrice,
  actionLabel,
  onActionClick,
  disabled
}) => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const handleClickMessageIcon = (e) => {
    e.stopPropagation()

    push('/conversations')
  }

  const handleClickCallIcon = (e) => {
    e.stopPropagation()

    window.location.href = `tel:${footer.phoneNumber && footer.phoneNumber}`
  }

  return (
    <IonGrid>
      <IonRow>
        {!payout.isEmpty() && (
          <IonCol>
            <IonRow className='payout-value-label-row'>
              <IonText color='medium'>
                {paymentMethod && paymentMethod.get('direct')
                  ? t('please_charge_your_customer')
                  : t('you_will_recieve')}
              </IonText>
            </IonRow>

            <IonRow>
              <IonText color='secondary' className='payout-display-value'>
                {paymentMethod && paymentMethod.get('direct')
                  ? chargeCustomerPrice.get('display_value')
                  : payout.get('display_value')}
              </IonText>

              {paymentMethod && paymentMethod.get('slug') === 'cash' && (
                <IonChip color='success' outline className='cash-chip'>
                  <IonLabel color='success'>{t('cash')}</IonLabel>
                </IonChip>
              )}
            </IonRow>
          </IonCol>
        )}

        {footer && footer.phoneNumber && (
          <IonCol size='2'>
            <IonRow className='row-flex-end'>
              <img
                className='call-icon'
                src='/assets/img/icon_call.png'
                alt='Call'
                onClick={handleClickCallIcon}
              />
            </IonRow>
          </IonCol>
        )}

        {footer && footer.conversation && (
          <IonCol size='2' sizeMd='auto' className='message-col'>
            <IonRow className='row-flex-end'>
              <img
                className='message-icon'
                src='/assets/img/icon_message.png'
                alt='Message'
                onClick={handleClickMessageIcon}
              />
            </IonRow>
          </IonCol>
        )}

        <IonCol className='job-action-button-column'>
          <IonButton
            expand='block'
            onClick={onActionClick}
            disabled={disabled || isLoading}
          >
            {actionLabel}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

JobActionFooter.propTypes = {
  paymentMethod: ImmutablePropTypes.map,
  payout: ImmutablePropTypes.map,
  chargeCustomerPrice: ImmutablePropTypes.map,
  isLoading: PropTypes.bool,
  actionLabel: PropTypes.string,
  onActionClick: PropTypes.func,
  footer: PropTypes.shape({
    phoneNumber: PropTypes.string,
    conversation: ImmutablePropTypes.map
  }),
  disabled: PropTypes.bool
}

JobActionFooter.defaultProps = {
  payout: Map()
}

export default JobActionFooter
