import React from 'react'
import { IonList } from '@ionic/react'
import { List } from 'immutable'

import WalletTransactionListItem from './WalletTransactionListItem'

const WalletTransactionList = ({ transactions, ...rest }) => {
  return (
    <IonList {...rest}>
      {transactions.map((transaction) => (
        <WalletTransactionListItem
          key={transaction.get('id')}
          transaction={transaction}
        />
      ))}
    </IonList>
  )
}

WalletTransactionList.defaultProps = {
  transactions: List()
}

export default WalletTransactionList
