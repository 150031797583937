import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { IonText, IonImg, IonThumbnail } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import './QuestionAnswer.scss'

const Answer = ({ data, history }) => {
  let urls = []

  switch (data.getIn(['question', 'display_type'])) {
    case 'radio':
    case 'checkboxes':
    case 'string':
    case 'textarea':
    case 'number':
    case 'searchable_select':
      return (
        <IonText className='job-text-answer'>
          {data.get('display_value')}
        </IonText>
      )
    case 'select':
      return (
        <IonText className='job-text-answer'>
          {data?.toJS().choice?.value}
        </IonText>
      )
    case 'camera':
    case 'gallery':
      urls = [data.get('image_url')]

      return (
        <IonThumbnail
          className='job-image-answer'
          onClick={() => {
            history.push(`${history.location.pathname}/answer_photos`, {
              urls,
              initialSlide: 0
            })
          }}
        >
          <IonImg
            src={data.get('image_url')}
            alt='Image Answer'
          />
        </IonThumbnail>
      )
    case 'camera_multiple':
    case 'gallery_multiple':
      urls = data.get('attachments', List()).map(attachment => attachment.get('file_url')).toJS()

      return (
        <div className='job-images-answer-container'>
          {
            data.get('attachments', List()).map((attachment, i) => (
              <IonThumbnail
                key={attachment.get('id')}
                className='job-image-answer'
                onClick={() => {
                  history.push(`${history.location.pathname}/answer_photos`, {
                    urls,
                    initialSlide: i
                  })
                }}
              >
                <IonImg
                  src={attachment.get('file_url')}
                  alt='Image Answer'
                />
              </IonThumbnail>
            ))
          }
        </div>
      )
    default:
      return '-'
  }
}

const QuestionAnswer = ({ data }) => {
  const history = useHistory()

  return (
    <div className='job-question-answer-container'>
      <IonText className='job-text-question'>
        {data.getIn(['question', 'name'])}
      </IonText>

      <Answer data={data} history={history} />
    </div>
  )
}

QuestionAnswer.propTypes = {
  data: ImmutablePropTypes.map
}

export default QuestionAnswer
