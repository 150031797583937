import { Plugins } from '@capacitor/core'
const { Share } = Plugins

export const shareUniversalUrl = async (title, url) => {
  try {
    await Share.share({
      title: title,
      text: title,
      url: url,
      dialogTitle: title
    })
  } catch (ex) {
    throw ex
  }
}
