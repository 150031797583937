import React from 'react'
import PropTypes from 'prop-types'

import './OtpInput.scss'
const OtpInput = ({ handleChange, name, value, hasErrors, handleDelete }) => {
  const handleInput = (e) => {
    if (e.type === 'keyup' && e.key === 'Backspace') {
      handleDelete(e)
    } else if (e.type === 'change' && e.key !== 'Backspace') {
      handleChange(e)
    }
  }
  return (
    <input
      placeholder={0}
      autoComplete='off'
      className={hasErrors ? 'otp-input error' : 'otp-input'}
      value={value}
      name={name}
      inputMode={'numeric'}
      type='number'
      onChange={(e) => handleInput(e)}
      onKeyUp={(e) => handleInput(e)}
    />
  )
}
OtpInput.propTypes = {
  onChange: PropTypes.func,
  handleDelete: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  hasErrors: PropTypes.number
}

export default OtpInput
