import React from 'react'
import PropTypes from 'prop-types'
import { IonSpinner } from '@ionic/react'
import clsx from 'clsx'

import './Spinner.scss'

const Spinner = ({ size, overlaid, ...rest }) => {
  return (
    <div
      className={clsx(
        'spinner-container',
        overlaid && 'spinner-container--overlaid'
      )}
    >
      <IonSpinner
        style={{ width: size, height: size }}
        color='primary'
        {...rest}
      />
    </div>
  )
}

Spinner.defaultProps = {
  size: 35
}

Spinner.propTypes = {
  size: PropTypes.number,
  overlaid: PropTypes.bool
}

export default Spinner
