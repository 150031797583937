export const errorIsAlert = (error) => {
  switch (error) {
    case 'could_not_send_sms':
    case 'verificaition_suspension_admin':
    case 'verificaition_suspension':
      return true
    case 'user_already_verified':
    case 'verification_database':
    case 'invalid_otp':
    case 'expired_otp':
    case 'otp_not_present':
      return false
    default:
      return false
  }
}

export const errorHasResponseBody = (error) => error?.response?.body !== undefined
