import request from 'superagent'

import {
  TICKET_SUCCEEDED,
  CACHE_TICKET_ID
} from 'constants/firebase/resources'

import { firebaseSupportPlatform, firebaseWorkforcePartner } from 'utils/firebase'

const ticketSucceeded = (response) => ({
  type: TICKET_SUCCEEDED,
  response
})

export const setTicketId = (data) => ({
  type: CACHE_TICKET_ID,
  payload: data
})

export const getTicketId = (data) => (dispatch) => {
  return request
    .post(process.env.REACT_APP_FIREBASE_TICKET_ID_API)
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .send(data)
    .then(response => {
      dispatch(ticketSucceeded(response))
      return response
    })
    .catch(error => {
      return console.warn(error)
    })
}

export const create = (path, data, schema, options = {}) => (dispatch, getState) => {
  return firebaseSupportPlatform.firestore().collection(path)
    .add(data)
    .then(response => {
      firebaseSupportPlatform.firestore().collection(path).doc(response.id).update({ id: response.id })
      return response
    })
}

export const update = (id, path, data, schema, options = {}) => (dispatch, getState) => {
  return firebaseSupportPlatform.firestore().collection(path)
    .doc(id)
    .update(data)
    .then(response => {
      return response
    })
}

export const updateProviderLocation = (id, path, data) => {
  return firebaseWorkforcePartner.firestore().collection(path)
    .doc(id)
    .update(data)
    .then(response => {
      return response
    })
}
