import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IonButton, IonSpinner } from '@ionic/react'
import { jobProviderSchema, tenantSchema } from '@seekster/schemas'

import { PageLayout, PageHeader, PageContent } from 'components/layout'
import JobList from 'components/lists/JobList'
import EmptyListPlaceholder from 'components/lists/EmptyListPlaceholder'
import SearchInput from 'components/inputs/SearchInput'

import { useCollection } from 'hooks/collections'
import { useEventId } from 'hooks/utils'
import groupByStartDate from 'utils/groupByStartDate'
import { useSelector } from 'react-redux'
import { getCurrentResource } from 'selectors/authentication'

const JobIndexPage = ({ location }) => {
  const pushState = location.state && location.state.fetch

  const searchRef = useRef(null)
  const { t, i18n } = useTranslation(['jobs', 'translation'])
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')
  const [eventId, generateEventId] = useEventId()
  const [jobProviders, { loading, totalCount, totalPages }] = useCollection(
    '/job_providers',
    jobProviderSchema,
    {
      dependencies: [eventId, i18n.language, pushState, query],
      query: { active: true, page: currentPage, query },
      cacheKey: `${eventId}${query}`
    }
  )

  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const searchEnabled = tenant.getIn(['general_configuration', 'search_provider_enabled'])

  const handleRefresh = () => {
    generateEventId()
    setCurrentPage(1)

    if (searchEnabled) setQuery(searchRef.current.value)
  }

  const handleLoadMore = () => {
    setCurrentPage((page) => page + 1)
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('jobs')}
        action={
          <IonButton routerLink='/jobs/history'>
            <img
              style={{ height: '100%' }}
              src='/assets/img/ic_history.png'
              alt='History'
            />
          </IonButton>
        }
      />
      {searchEnabled && (
        <SearchInput
          ref={searchRef}
          count={jobProviders.count()}
          loading={loading}
          onSearch={setQuery}
          placeholder={t('search_placeholder')}
        />
      )}
      <PageContent onRefresh={handleRefresh} loading={loading && currentPage === 1}>
        {jobProviders.isEmpty() ? (
          <EmptyListPlaceholder
            message={
              searchRef.current?.value ? t('search_not_found') : t('no_jobs_message')
            }
          />
        ) : (
          <JobList
            data={groupByStartDate(jobProviders, jobProviderSchema, [
              'job',
              'start_time'
            ])}
          />
        )}

        {totalCount > 30 && currentPage < totalPages && (
          <IonButton expand='full' color='light' onClick={handleLoadMore}>
            {loading ? <IonSpinner color='secondary' /> : t('load_more')}
          </IonButton>
        )}
      </PageContent>
    </PageLayout>
  )
}

JobIndexPage.propTypes = {
  location: PropTypes.object
}

export default JobIndexPage
