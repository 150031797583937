import React from 'react'
import { IonButton } from '@ionic/react'

import { PageLayout, PageContent, PageHeader } from 'components/layout'

import { useTranslation } from 'react-i18next'

import './ConfirmForgotPasswordPage.scss'

const ConfirmForgotPasswordPage = ({ history, match }) => {
  const { t } = useTranslation('bank_account')

  return (
    <PageLayout>
      <PageHeader
        noColor
        noBorder
      />

      <PageContent className='page-content'>
        <div clasName='page-content__section'>
          <img
            className='page-content__pending-approve-img'
            src='/assets/img/send-mail-icon.png'
            alt='pending'
          />

          <h5>เราได้ทำการส่งอีเมลให้คุณแล้ว</h5>

          <p style={{ marginBottom: 24 }}>
          กรุณาเช็คอีเมล. และทำตามขั้นตอนที่ระบุในอีเมล์เพื่อตั้งค่ารหัสผ่านใหม่
          </p>

          <IonButton
            expand='block'
            style={{ marginTop: 24 }}
            onClick={() => {
              history.push('/sign_in')
            }}
          >
            {t('back_to_lead_page')}
          </IonButton>
        </div>
      </PageContent>
    </PageLayout>
  )
}

export default ConfirmForgotPasswordPage
