import React, { useRef, useState, useEffect } from 'react'
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonIcon,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react'

import { ellipse } from 'ionicons/icons'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar, utils } from 'react-modern-calendar-datepicker'

import CalendarCard from 'components/CalendarCard'
import PopupCalendarCard from 'modules/calendars/PopupCalendarCard'
import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import moment from 'moment'
import { useEventId } from 'hooks/utils'
import {
  providerRestDateSchema,
  providerSchema,
  providerActiveDateSchema
} from '@seekster/schemas'
import { useCollection } from 'hooks/collections'
import { useResourceActions, useResource } from 'hooks/resources'
import { uniqBy } from 'lodash'
import BaseField from 'components/fields/BaseField'
import RadioInput from 'components/inputs/RadioInput'
import TimeRangeField from 'components/inputs/TimeRangeField'
import { Form } from 'formik'

import './ManageCalendarForm.scss'

const myCustomLocale = {
  // months list by order
  months: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ],
  // week days by order
  weekDays: [
    {
      name: 'Sunday', // used for accessibility
      short: 'อา.'
    },
    {
      name: 'Monday',
      short: 'จ.'
    },
    {
      name: 'Tuesday',
      short: 'อ.'
    },
    {
      name: 'Wednesday',
      short: 'พ.'
    },
    {
      name: 'Thursday',
      short: 'พฤ.'
    },
    {
      name: 'Friday',
      short: 'ศ.'
    },
    {
      name: 'Saturday',
      short: 'ส.'
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day)
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate()
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'from',
  to: 'to',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false
}

const ManageCalendarForm = ({ setFieldValue, values, history, isDisabled }) => {
  const backButtonListenerHandle = useRef()
  // const [radioValue, setRadioValue] = useState()
  const [stepCalendar, setStepCalendar] = useState(1)
  const [eventId, generateEventId] = useEventId()
  const [eventId1, generateEventId1] = useEventId()
  const [restDates, { loading: restLoading }] = useCollection(
    '/provider_rest_dates',
    providerRestDateSchema,
    {
      dependencies: [eventId],
      cacheKey: eventId
    }
  )

  const [availableDates, { loading: avaiLoading }] = useCollection(
    '/provider_available_dates',
    providerActiveDateSchema,
    {
      dependencies: [eventId1],
      cacheKey: eventId1
    }
  )

  const [provider, { loading }] = useResource('/provider', providerSchema, {
    implicit: true
  })

  let preselectedDays = []
  let activeDates = []
  let availableDays = []
  const [selectedDay, setSelectedDay] = useState([])
  const [disabledDay, setDisabledDay] = useState([])
  // const [isFirstTime, setIsFirstTime] = useState(false)
  const [activeDate, setActiveDate] = useState([])
  const [availableDate, setAvailableDate] = useState([])
  const [isSelectDisabledDate, setIsSelectDisabledDate] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [pairAvailableActiveDay, setPairAvailableActiveDay] = useState([])
  const [pairActiveDisableDay, setPairActiveDisableDay] = useState([])
  const [pairAvailableDisableDay, setPairAvailableDisableDay] = useState([])
  const [groupAvailableActiveDisableDay, setGroupAvailableActiveDisableDay] = useState([])
  const [isSelectActiveOverLapDate, setIsSelectActiveOverLapDate] = useState(false)
  const [activeDateOverlap, setActiveDateOverlap] = useState([])
  const [pairOverLapAvailableActiveDay, setPairOverLapAvailableActiveDay] = useState([])
  const [pairOverLapActiveDisableDay, setPairOverLapActiveDisableDay] = useState([])
  const [
    groupOverlapAvailableActiveDisableDay,
    setGroupOverlapAvailableActiveDisableDay
  ] = useState([])

  const concatCustomDate = groupOverlapAvailableActiveDisableDay
    .concat(groupAvailableActiveDisableDay)
    .concat(pairOverLapActiveDisableDay)
    .concat(pairOverLapAvailableActiveDay)
    .concat(pairAvailableActiveDay)
    .concat(pairAvailableDisableDay)
    .concat(pairActiveDisableDay)
    .concat(disabledDay)
    .concat(availableDate)
    .concat(activeDateOverlap)
    .concat(activeDate)

  useIonViewDidEnter(() => {
    generateEventId()
    generateEventId1()
  })

  // useEffect(() => {
  //   if (
  //     radioValue === 'other' &&
  //     values.selectedTime &&
  //     values.selectedTime.substring(0, 1) === 'a'
  //   ) {
  //     setFieldValue('selectedTime', '')
  //   }
  // }, [radioValue])

  useEffect(() => {
    if (provider.get('active_jobs')) {
      const activeDay = provider.get('active_jobs').toJS()
      activeDay.map(
        (date) =>
          // eslint-disable-next-line
          (activeDates = [
            ...activeDates,
            {
              className: 'active_day',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              startTime: moment(date.start_time).hour(),
              endTime: moment(date.end_time).hour(),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      setActiveDate(activeDates)
    }
  }, [provider])

  useEffect(() => {
    if (!restDates.isEmpty()) {
      const dateTime = restDates.toJS()
      dateTime.map(
        (date) =>
          // eslint-disable-next-line
          (preselectedDays = [
            ...preselectedDays,
            {
              className: 'disable_day',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              startTime: moment(date.start_time).hour(),
              endTime: moment(date.end_time).hour(),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      setDisabledDay(preselectedDays)
    } else {
      setDisabledDay([])
    }
  }, [restDates, eventId])

  useEffect(() => {
    if (!availableDates.isEmpty()) {
      const availableDay = availableDates.toJS()
      availableDay.map(
        (date) =>
          // eslint-disable-next-line
          (availableDays = [
            ...availableDays,
            {
              className: 'available_day',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              startTime: moment(date.start_time).hour(),
              endTime: moment(date.end_time).hour(),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      setAvailableDate(availableDays)
    }
  }, [availableDates, eventId1])

  useEffect(() => {
    if (provider.get('active_jobs') && values.selectedTime) {
      const activeDay = provider.get('active_jobs').toJS()
      activeDay.map(
        (date) =>
          // eslint-disable-next-line
          (activeDates = [
            ...activeDates,
            {
              className: 'active_day_overlab',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              startTime: moment(date.start_time).hour(),
              endTime: moment(date.end_time).hour(),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      const isActiveOverlap = activeDates.filter(
        (time) =>
          time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
          time.endTime > parseInt(values.selectedTime.split('-')[0])
      )

      setActiveDateOverlap(
        uniqBy(isActiveOverlap, (v) => [v.year, v.month, v.day].join())
      )

      if (!availableDates.isEmpty() && !restDates.isEmpty()) {
        const onceMixlap = []
        const onceActiveAvailableOverlap = []
        const onceActiveDisableOverlap = []

        const activeAndAvailableOverlap = availableDates.filter((available) =>
          isActiveOverlap.find(
            (active) =>
              moment(available.get('start_time')).get('date') === active.day &&
              moment(available.get('start_time')).get('month') + 1 === active.month
          )
        )

        const activeAndDisableOverlap = restDates.filter((disable) =>
          isActiveOverlap.find(
            (active) =>
              moment(disable.get('start_time')).get('date') === active.day &&
              moment(disable.get('start_time')).get('month') + 1 === active.month
          )
        )

        const mixOverlap = activeAndAvailableOverlap.filter((date) =>
          restDates.find(
            (disable) =>
              moment(date.get('start_time')).get('date') ===
                moment(disable.get('start_time')).get('date') &&
              moment(date.get('start_time')).get('month') + 1 ===
                moment(disable.get('start_time')).get('month') + 1
          )
        )
        // eslint-disable-next-line array-callback-return
        mixOverlap.map((date) => {
          const formatDay = {
            year: moment(date.get('start_time')).get('year'),
            month: moment(date.get('start_time')).get('month') + 1,
            day: moment(date.get('start_time')).get('date'),
            className: 'group_available_active_overlap_disable_day'
          }
          onceMixlap.push(formatDay)
        })
        // eslint-disable-next-line array-callback-return
        activeAndAvailableOverlap.map((date) => {
          const formatDay = {
            year: moment(date.get('start_time')).get('year'),
            month: moment(date.get('start_time')).get('month') + 1,
            day: moment(date.get('start_time')).get('date'),
            className: 'pair_available_overlap_active_day'
          }
          onceActiveAvailableOverlap.push(formatDay)
        })

        // eslint-disable-next-line array-callback-return
        activeAndDisableOverlap.map((date) => {
          const formatDay = {
            year: moment(date.get('start_time')).get('year'),
            month: moment(date.get('start_time')).get('month') + 1,
            day: moment(date.get('start_time')).get('date'),
            className: 'pair_active_overlap_disable_day'
          }
          onceActiveDisableOverlap.push(formatDay)
        })

        setPairOverLapActiveDisableDay(
          onceActiveDisableOverlap.filter(
            (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
          )
        )

        setPairOverLapAvailableActiveDay(
          onceActiveAvailableOverlap.filter(
            (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
          )
        )

        setGroupOverlapAvailableActiveDisableDay(
          onceMixlap.filter(
            (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
          )
        )
      }
    }
  }, [provider, stepCalendar])

  useEffect(() => {
    const onceActiveAvailable = []
    const onceActiveDisable = []
    const onceDisableAvailable = []
    const onceMix = []

    if (
      !availableDates.isEmpty() &&
      provider.get('active_jobs') &&
      !restDates.isEmpty()
    ) {
      const activeAndAvailable = availableDates.filter((available) =>
        provider
          .get('active_jobs')
          .find(
            (active) =>
              moment(available.get('start_time')).get('date') ===
                moment(active.get('start_time')).get('date') &&
              moment(available.get('start_time')).get('month') + 1 ===
                moment(active.get('start_time')).get('month') + 1
          )
      )

      const disabledAndActive = restDates.filter((disable) =>
        provider
          .get('active_jobs')
          .find(
            (active) =>
              moment(disable.get('start_time')).get('date') ===
                moment(active.get('start_time')).get('date') &&
              moment(disable.get('start_time')).get('month') + 1 ===
                moment(active.get('start_time')).get('month') + 1
          )
      )

      const disabledAndAvailable = availableDates.filter((available) =>
        restDates.find(
          (disable) =>
            moment(available.get('start_time')).get('date') ===
              moment(disable.get('start_time')).get('date') &&
            moment(available.get('start_time')).get('month') + 1 ===
              moment(disable.get('start_time')).get('month') + 1
        )
      )

      const mixDates = activeAndAvailable.filter((date) =>
        restDates.find(
          (disable) =>
            moment(date.get('start_time')).get('date') ===
              moment(disable.get('start_time')).get('date') &&
            moment(date.get('start_time')).get('month') + 1 ===
              moment(disable.get('start_time')).get('month') + 1
        )
      )

      // mixDate
      // eslint-disable-next-line array-callback-return
      mixDates.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'group_available_active_disable_day'
        }
        onceMix.push(formatDay)
      })

      // activeAndAvailable
      // eslint-disable-next-line array-callback-return
      activeAndAvailable.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'pair_available_active_day'
        }
        onceActiveAvailable.push(formatDay)
      })

      // eslint-disable-next-line array-callback-return
      disabledAndAvailable.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'pair_available_disable_day'
        }
        onceDisableAvailable.push(formatDay)
      })

      // eslint-disable-next-line array-callback-return
      disabledAndActive.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'pair_active_disable_day'
        }
        onceActiveDisable.push(formatDay)
      })

      setGroupAvailableActiveDisableDay(
        onceMix.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairAvailableActiveDay(
        onceActiveAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairAvailableDisableDay(
        onceDisableAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairActiveDisableDay(
        onceActiveDisable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
    }
  }, [provider, restDates, availableDates, eventId, eventId1]) // eslint-disable-line react-hooks/exhaustive-deps

  const { create: createRestDate } = useResourceActions(providerRestDateSchema)

  function handleRemoveCard(date) {
    const selectedDayWithRemove = selectedDay.filter(
      (value) =>
        value.day !== date.day || value.month !== date.month || value.year !== date.year
    )

    setSelectedDay(selectedDayWithRemove)
  }

  function handleAcceptDayoff() {
    let newDate = []
    selectedDay.map(
      (date) =>
        (newDate = [
          ...newDate,
          {
            start_time: `${moment(
              `${date.month}-${date.day}-${date.year} ${
                values.selectedTime.split('-')[0]
              }.00`,
              'MM-DD-YYYY HH.mm.ss'
            ).toISOString(true)}`,
            end_time: `${moment(
              `${date.month}-${date.day}-${date.year} ${
                values.selectedTime.split('-')[1]
              }.00`,
              'MM-DD-YYYY HH.mm.ss'
            )
              // .subtract(1, 'seconds')
              .toISOString(true)}`
          }
        ])
    )

    const providerRestDatesAttributes = [...newDate]

    return createRestDate('/provider_rest_dates', {
      provider_rest_dates_attributes: providerRestDatesAttributes
    }).then(() => {
      handleOnChangeSetDefault()
      generateEventId()
      generateEventId1()
      setShowAlert(false)

      setTimeout(() => {
        history.replace('/settings/my_calendar', { fetch: true })
      }, 300)
      // history.replace('/settings/my_calendar', { fetch: true })
    })
  }

  useIonViewDidLeave(() => {
    if (backButtonListenerHandle.current) {
      backButtonListenerHandle.current.remove()
      backButtonListenerHandle.current = undefined
    }
  })

  const hanldeOnChangeDisableDay = (date) => {
    const selectedDisabledDate = date[date.length - 1]
    let checkIsActive = []
    let checkIsDisabled = []
    let availabledDate = []

    if (selectedDisabledDate) {
      checkIsActive = activeDate.filter(
        (value) =>
          value.day === selectedDisabledDate.day &&
          value.month === selectedDisabledDate.month &&
          value.year === selectedDisabledDate.year
      )
      checkIsDisabled = disabledDay.filter(
        (value) =>
          value.day === selectedDisabledDate.day &&
          value.month === selectedDisabledDate.month &&
          value.year === selectedDisabledDate.year
      )
      availabledDate = availableDate.filter(
        (value) =>
          value.day === selectedDisabledDate.day &&
          value.month === selectedDisabledDate.month &&
          value.year === selectedDisabledDate.year
      )
    }

    // checkNotOverLap => [a >= d || b <= c (a-b, c-d)]
    // checkOverlab => [a < d && c > b]
    if (availabledDate.length > 0 && checkIsDisabled.length > 0) {
      const isAvialableOverlap = availabledDate.filter(
        (time) =>
          time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
          time.endTime > parseInt(values.selectedTime.split('-')[0])
      )

      if (isAvialableOverlap.length > 0) {
        setIsSelectDisabledDate(true)
      } else {
        const isDiableOverlap = checkIsDisabled.filter(
          (time) =>
            time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
            time.endTime > parseInt(values.selectedTime.split('-')[0])
        )

        if (isDiableOverlap.length > 0) {
          setIsSelectDisabledDate(true)
        } else {
          setSelectedDay(date)
        }
      }
    } else if (checkIsDisabled.length > 0) {
      const isDiableOverlap = checkIsDisabled.filter(
        (time) =>
          time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
          time.endTime > parseInt(values.selectedTime.split('-')[0])
      )

      if (isDiableOverlap.length > 0) {
        setIsSelectDisabledDate(true)
      } else {
        setSelectedDay(date)
      }
    } else if (availabledDate.length > 0) {
      const isAvialableOverlap = availabledDate.filter(
        (time) =>
          time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
          time.endTime > parseInt(values.selectedTime.split('-')[0])
      )

      if (isAvialableOverlap.length > 0) {
        setIsSelectDisabledDate(true)
      } else {
        if (checkIsActive.length > 0) {
          const isActiveOverlap = checkIsActive.filter(
            (time) =>
              time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
              time.endTime > parseInt(values.selectedTime.split('-')[0])
          )

          if (isActiveOverlap.length > 0) {
            setIsSelectActiveOverLapDate(true)
          } else {
            setSelectedDay(date)
          }
        }
      }
    } else if (checkIsActive.length > 0) {
      const isActiveOverlap = checkIsActive.filter(
        (time) =>
          time.startTime < parseInt(values.selectedTime.split('-')[1]) &&
          time.endTime > parseInt(values.selectedTime.split('-')[0])
      )

      if (isActiveOverlap.length > 0) {
        setIsSelectActiveOverLapDate(true)
      } else {
        setSelectedDay(date)
      }
    } else if (date.length === 0) {
      setIsSelectDisabledDate(false)
      setSelectedDay(date)
      // getContent().scrollToPoint(0, 200, 500)
    } else {
      if (!isSelectDisabledDate) {
        setSelectedDay(date)
        // getContent().scrollToPoint(0, 200, 500)
      }
    }
  }

  function handleOnChangeSetDefault() {
    setIsSelectDisabledDate(false)
    setIsSelectActiveOverLapDate(false)
    setSelectedDay([])
  }

  return (
    <Form>
      <PageLayout>
        {stepCalendar === 1 ? (
          <PageHeader
            title='สร้างวันหยุด'
            withBackButton
            backTo='/settings/my_calendar'
          />
        ) : (
          <PageHeader
            title='สร้างวันหยุด'
            withBackButton
            onBack={() => setStepCalendar(stepCalendar - 1)}
          />
        )}
        {stepCalendar === 1 ? (
          <>
            <PageContent
              className='calendar-page'
              loading={restLoading || avaiLoading || loading}
            >
              <div className='content-calendar-page-form'>
                <BaseField
                  name='selectedTime'
                  label='ระบุช่วงเวลา'
                  component={RadioInput}
                  options={[{ value: '9.00-18.00', label: 'ทั้งวัน (9:00 - 18:00)' }]}
                />

                <div className='header-selected-disabled-date'>
                  <BaseField
                    name='selectedTime'
                    label='เลือกช่วงเวลา'
                    component={RadioInput}
                    options={[
                      { value: '9.00-12.00', label: 'ช่วงเช้า 9:00 - 12:00' },
                      { value: '12.00-15.00', label: 'ช่วงบ่าย 12:01 - 15:00' },
                      { value: '15.00-18.00', label: 'ช่วงเย็น 15:01 - 18:00' }
                    ]}
                  />
                </div>

                {/* <RadioInput
                  value={radioValue}
                  onChange={(value) => setRadioValue(value)}
                  options={[{ value: 'other', label: 'กำหนดช่วงเวลา' }]}
                /> */}

                <div className='job-time'>
                  <TimeRangeField
                    name='selectedTime'
                    label='กำหนดช่วงเวลา'
                    placeholder='ระบุช่วงเวลา'
                  />
                </div>
              </div>
            </PageContent>

            <PageFooter>
              <IonButton
                disabled={!values.selectedTime}
                onClick={() => {
                  setSelectedDay([])
                  setStepCalendar(2)
                }}
                expand='block'
              >
                ถัดไป
              </IonButton>
            </PageFooter>
          </>
        ) : stepCalendar === 2 ? (
          <>
            <PageContent className='calendar-main-page'>
              <IonGrid className='my-calendar' fixed>
                <div className='disabled-calendar'>
                  <Calendar
                    value={selectedDay}
                    onChange={hanldeOnChangeDisableDay}
                    // disabledDays={disabledDay}
                    minimumDate={utils().getToday()}
                    // onDisabledDayError={handleDisabledSelect}
                    shouldHighlightWeekends
                    locale={myCustomLocale}
                    customDaysClassName={concatCustomDate}
                  />
                </div>
                <div className='calendar-devide' />

                <IonRow>
                  <IonCol size='5'>
                    <IonRow className='remark-for-calendar'>
                      <IonIcon icon={ellipse} className='icon-actived-date' />
                      วันที่คุณรับงานไว้
                    </IonRow>
                  </IonCol>
                  <IonCol size='7'>
                    <IonRow className='remark-for-calendar'>
                      <IonIcon icon={ellipse} className='icon-selected-date' />
                      วันหยุดที่คุณเลือกไว้
                    </IonRow>
                  </IonCol>
                  <IonCol size='12'>
                    <IonRow className='remark-for-calendar'>
                      <IonIcon icon={ellipse} className='icon-already-date' />
                      วันพร้อมรับงาน
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </PageContent>

            {isSelectDisabledDate && (
              <PopupCalendarCard
                isDisabled
                onRemoveCard={() => setIsSelectDisabledDate(false)}
              />
            )}

            {isSelectActiveOverLapDate && (
              <PopupCalendarCard
                isActive
                onRemoveCard={() => setIsSelectActiveOverLapDate(false)}
              />
            )}

            <PageFooter>
              <IonButton
                disabled={selectedDay.length < 1}
                onClick={() => setStepCalendar(3)}
                expand='block'
              >
                ถัดไป
              </IonButton>
            </PageFooter>
          </>
        ) : (
          <>
            <PageContent className='calendar-page'>
              <div className='content-calendar-page'>
                {isDisabled ? (
                  <p>
                    ไม่ต้องกังวล คุณจะได้รับแจ้งเตือนงานในวันดังกล่าว
                    และคุณสามารถยกเลิกวันหยุดได้หากคุณเปลี่ยนใจ
                  </p>
                ) : (
                  <p>
                    คุณจะได้รับการแจ้งเตือนงานเข้าใหม่และ
                    แอดมินยังสามารถส่งงานให้คุณตามปกติ
                  </p>
                )}

                <h5>วันที่เลือก (จำนวน {selectedDay.length} วัน)</h5>

                <IonGrid fixed>
                  <IonRow className='calendar-card-row'>
                    {selectedDay.map((date, index) => {
                      return (
                        <IonCol size='3' key={index}>
                          <CalendarCard date={date} onRemoveCard={handleRemoveCard} />
                        </IonCol>
                      )
                    })}
                  </IonRow>
                </IonGrid>

                <div className='job-time-calendar'>
                  <TimeRangeField name='selectedTime' label='เวลา' disabled />
                </div>
              </div>
            </PageContent>

            <PageFooter>
              <IonGrid>
                <IonRow>
                  <IonCol size='6'>
                    <IonButton
                      expand='block'
                      fill='outline'
                      onClick={() => setStepCalendar(1)}
                    >
                      แก้ไข
                    </IonButton>
                  </IonCol>

                  <IonCol size='6'>
                    <IonButton
                      disabled={selectedDay.length === 0}
                      expand='block'
                      onClick={() => setShowAlert(true)}
                    >
                      ตั้งวันหยุด
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </PageFooter>
          </>
        )}

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='alert-calendar'
          header='คุณยืนยันที่จะหยุดงานตามวัน ดังกล่าวใช่หรือไม่?'
          buttons={[
            {
              text: 'ยกเลิก',
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: 'ยืนยัน',
              handler: handleAcceptDayoff
            }
          ]}
        />
      </PageLayout>
    </Form>
  )
}

export default ManageCalendarForm
