import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { SIGN_OUT } from 'constants/authentication'
import {
  FETCH_COLLECTION_SUCCEEDED,
  CLEAR_COLLECTION_DATA_SUCCEEDED
} from 'constants/collections'

const initialState = Map()

const collectionsReducer = (
  state = initialState,
  { type, response, schema, cacheKey }
) => {
  let key
  let normalizedData
  let list

  switch (type) {
    case FETCH_COLLECTION_SUCCEEDED:
      key = cacheKey || schema._key
      normalizedData = normalize(response.body, [schema])
      list = state.getIn([key, 'data']) || List()

      return state
        .setIn([key, 'data'], list.concat(List(normalizedData.result)).toOrderedSet().toList())
        .setIn([key, 'total'], parseInt(response.headers.total, 10))
        .setIn([key, 'perPage'], parseInt(response.headers['per-page'], 10))

    case CLEAR_COLLECTION_DATA_SUCCEEDED:
      key = cacheKey || schema._key

      return state.delete(key)

    case SIGN_OUT:
      return state.clear()

    default:
      return state
  }
}

export default collectionsReducer
