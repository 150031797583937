import { withFormik } from 'formik'
import { Map } from 'immutable'
import * as Yup from 'yup'

import BankAccountForm from './BankAccountForm'

const formikConfig = {
  mapPropsToValues: ({ bankAccount = Map() }) => ({
    bank_id: bankAccount.getIn(['bank', 'id']),
    account_number: bankAccount.get('formatted_account_number'),
    account_name: bankAccount.get('account_name')
  }),

  enableReinitialize: true,

  validationSchema: (props) => {
    return Yup.object().shape({
      bank_id: Yup.string().required(),
      account_number: Yup.string().matches(/^\d{3}-\d{1}-\d{5}-\d{1}$/, 'หมายเลขบัญชีต้องเป็น 10 หลัก').required(),
      account_name: Yup.string().required()
    })
  },

  handleSubmit: (values, { props }) => {
    props.onSubmit({ ...values, account_number: values.account_number.split('-').join('') })
  }
}

export default withFormik(formikConfig)(BankAccountForm)
