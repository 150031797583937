import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import {
  IonGrid, IonRow, IonCol, IonButton,
  IonRouterLink, IonText
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import BaseField from 'components/fields/BaseField'
import PasswordInput from 'components/inputs/PasswordInput'

import './SignInForm.scss'

const SignInForm = ({ loading }) => {
  const { t } = useTranslation('sign_in')

  return (
    <Form>
      <IonGrid className='form-container'>
        <IonRow>
          <IonCol>
            <BaseField
              name='email'
              type='email'
              inputmode='email'
              noLabel
              placeholder={t('email')}
              noErrorMessagePlaceholder
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <BaseField
              name='password'
              component={PasswordInput}
              noLabel
              placeholder={t('password')}
              noErrorMessagePlaceholder
            />
          </IonCol>
        </IonRow>

        <IonRow className='forget-password-row'>
          <IonCol size='auto'>
            <IonRouterLink routerLink='/forget_password'>
              <IonText color='medium'>
                {t('forgot_password')}
              </IonText>
            </IonRouterLink>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className='sign-in-button-col'>
            <IonButton
              type='submit'
              expand='block'
              disabled={loading}
            >
              {t('sign_in')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Form>
  )
}

SignInForm.propTypes = {
  loading: PropTypes.bool
}

export default SignInForm
