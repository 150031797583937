export default function distanceDifferent(lat1, lon1, lat2, lon2) {
  let R = 6371 // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1)
  let dLon = deg2rad(lon2 - lon1)
  let a =
    0.5 -
    Math.cos(dLat) / 2 +
    (Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * (1 - Math.cos(dLon))) / 2

  return (R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))).toFixed(2)
}

function deg2rad(deg) {
  return (deg * Math.PI) / 180
}
