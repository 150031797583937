import React from 'react'
import { Plugins } from '@capacitor/core'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { policyTermSchema } from '@seekster/schemas'
import moment from 'moment'
import maxBy from 'lodash/maxBy'

import { usePolicyModal } from 'hooks/policy'
import { signOut } from 'actions/authentication'
import { DateFormatter, HTMLFormatter } from 'components/formatters'

import './PolicyTermsModal.scss'
import { useCollection } from 'hooks/collections'

const { Storage } = Plugins

const PolicyTermsModal = ({ isMenu, isSignedIn, swipeToClose }) => {
  const {
    t,
    i18n: { language },
    i18n
  } = useTranslation(['policy_terms'])
  const { push } = useHistory()
  const dispatch = useDispatch()
  const [policyTerms] = useCollection('/policy_terms/provider', policyTermSchema)
  const { open, variant, closePolicyModal, acceptPolicy } = usePolicyModal()
  const policy = policyTerms.find((pt) => pt.get('variant') === 'policy')
  const terms = policyTerms.find((pt) => pt.get('variant') === 'terms')
  const lastestEffectiveDate = maxBy([...policyTerms.toJS()], (p) =>
    moment(p.created_at)
  )?.created_at
  const isPolicyAvailable = policyTerms.size > 0

  const getContent = (obj) => {
    const translations = obj?.get('policy_term_translations')
    const content = translations
      ?.find((trn) => trn.get('lang') === language)
      ?.get('content')
    return content
  }

  const setLocale = async (locale) => {
    Storage.set({ key: 'locale', value: locale })
    i18n.changeLanguage(locale)
    moment.locale(locale)

    // return dispatch(updateCurrentDevice({ locale }))
    // .then(() => {
    // return Storage.set({ key: 'locale', value: locale })
    // })
    // .then(() => {
    // i18n.changeLanguage(locale)
    //
    // moment.locale(locale)
    // })
  }

  const handleSelectLanguage = (e) => {
    setLocale(e.detail.value)
  }

  const handleAccept = async () => {
    if (isSignedIn) {
      await acceptPolicy(policy.get('id'))
      await acceptPolicy(terms.get('id'))
    }
    closePolicyModal()
  }

  const handleDecline = async () => {
    // await declinePolicy(policy.get('id'))
    // await declinePolicy(terms.get('id'))
    if (isSignedIn) {
      return dispatch(signOut())
        .then(() => {
          // deleteRedirectUrl()
          closePolicyModal()
          return push('/sign_in')
        })
        .catch(() => {
          // deleteRedirectUrl()
          closePolicyModal()
          return push('/sign_in')
        })
    } else {
      closePolicyModal()
      return push('/sign_in')
    }
  }

  const selectLineContact = (variant) => {
    const lineContact =
      variant === 'privacy' ? policy.get('contact_line') : terms.get('contact_line')
    return lineContact && lineContact !== '' ? lineContact : 'https://lin.ee/43UobDy'
  }

  return (
    <IonModal
      swipeToClose={swipeToClose}
      isOpen={open && isPolicyAvailable > 0}
      className='policy-terms-modal'
      onDidDismiss={closePolicyModal}
    >
      <IonHeader className='policy-modal-header'>
        <IonToolbar className='first-bar'>
          <div className='title'>
            {(['privacy', 'terms'].includes(variant) || isMenu) && (
              <IonButtons className='back-button'>
                <IonBackButton
                  defaultHref='/settings'
                  onClick={closePolicyModal}
                  text=''
                />
              </IonButtons>
            )}
            <IonTitle>{t(variant || 'header')}</IonTitle>
          </div>
        </IonToolbar>
        <IonToolbar className='second-bar'>
          <div className=''>
            {`${t('performed_date')}${' '}`}&nbsp;
            <DateFormatter value={lastestEffectiveDate} />
            &nbsp;
            <IonSelect
              className='language-select'
              interface='popover'
              value={language}
              onIonChange={handleSelectLanguage}
              // onIonChange={(e) => console.log(e.detail.value)}
            >
              <IonSelectOption value='en'>English</IonSelectOption>
              <IonSelectOption value='th'>ไทย</IonSelectOption>
            </IonSelect>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='policy-modal-content'>
          {variant ? (
            <HTMLFormatter value={getContent(variant === 'privacy' ? policy : terms)} />
          ) : (
            <>
              {policy && <HTMLFormatter value={getContent(policy)} />}
              {terms && <HTMLFormatter value={getContent(terms)} />}
            </>
          )}
        </div>
      </IonContent>
      <IonFooter className='policy-modal-footer'>
        <IonToolbar>
          <div className='buttons'>
            {isMenu || ['privacy', 'terms'].includes(variant) ? (
              <IonButton
                expand='block'
                className='contact-button'
                onClick={() => window.open(selectLineContact(variant))}
              >
                {t('contact_us')}
              </IonButton>
            ) : (
              <>
                <IonButton onClick={handleDecline} fill='clear'>
                  {t('button.not_accept')}
                </IonButton>
                <IonButton onClick={handleAccept} color='primary' shape='round'>
                  {t('button.accept')}
                </IonButton>
              </>
            )}
          </div>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default PolicyTermsModal
