import React from 'react'
import { IonButton, IonImg, IonText } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { tenantSchema } from '@seekster/schemas'

import { getCurrentIdAttribute } from 'selectors/authentication'
import { PageLayout, PageContent } from 'components/layout'
import { signOut } from 'actions/authentication'

import './SignUpPendingPage.scss'
import Header from 'components/typography/Header/Header'
import Subheader from 'components/typography/Subheader'
import Paragraph from 'components/typography/Paragraph'

const SignUpPendingPage = ({ history }) => {
  const { t } = useTranslation(['sign_up', 'settings'])
  const tenantName = useSelector((state) => getCurrentIdAttribute(state, tenantSchema))
  const dispatch = useDispatch()

  const handleBack = () => {
    return dispatch(signOut())
      .then(() => {
        return history.push('/sign_in')
      })
      .catch(() => {
        return history.push('/sign_in')
      })
  }

  return (
    <PageLayout withSafeAreaTop className='pending-page-core-layout'>
      <PageContent>
        <div className='pending-message-container'>
          <div className='header-container'>
            <Header white>{t('filling_completed')}</Header>
          </div>
          <div className='img-container'>
            <IonImg src='assets/img/pending_approval_2.svg' />
          </div>

          <Subheader white>{t('please_prepare_documents')}</Subheader>
          <Paragraph white>{t('copy_of_identification_card')}</Paragraph>
          <Paragraph white>{t('occupational_documents')}</Paragraph>
          <Paragraph white>{t('seminar_participation_certificates')}</Paragraph>
          <div className='spacer' />
          <div className='we-will-contact-you'>
            {tenantName === 'seekster' && (
              <>
                <Paragraph white>{t('we_will_contact_you')}</Paragraph>
              </>
            )}
          </div>
          {tenantName === 'seekster' && (
            <IonButton
              href='https://line.me/R/ti/p/@joinseekster'
              className='line-add-friend-button'
              expand='block'
            >
              <img src='/assets/img/line_logo.png' alt='Line Logo' />
              <IonText className='line-add-friend-button__text'>
                {t('add_friend')}
              </IonText>
            </IonButton>
          )}
          <div className='button-container'>
            <IonButton
              // type='submit'
              onClick={handleBack}
              expand='block'
              className='sign-up-pending-back-button'
            >
              {t('logout')}
            </IonButton>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  )
}

export default SignUpPendingPage
