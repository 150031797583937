import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { IonSegment, IonSegmentButton, IonLabel, IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { jobProviderSchema, providerSchema, tenantSchema } from '@seekster/schemas'
import { Map } from 'immutable'

import JobActionFooter from 'components/JobActionFooter'
import Spinner from 'components/Spinner'
import JobComment from 'components/JobComment'
import { useResource, useResourceActions } from 'hooks/resources'
import { useCurrentLocation } from 'hooks/interfaces'
import { useJobTabs } from 'hooks/utils'
import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import { updateProviderLocation } from 'actions/firebase/resources'
import { JobDetailsPane, JobReportPane } from './panes'
import { getCurrentResource } from 'selectors/authentication'

import './JobShowPage.scss'

const JobShowPage = ({ match }) => {
  const { t } = useTranslation('jobs')
  const submitButtonRef = useRef()
  const [currentTab, setTab] = useJobTabs()
  const [showStartJobAlert, setShowStartJobAlert] = useState(false)
  const [showStartTimeAlert, setShowStartTimeAlert] = useState(false)
  const [showJobReportAlert, setShowJobReportAlert] = useState(false)
  const [{ latitude, longitude }] = useCurrentLocation()
  const provider =
    useSelector((state) => getCurrentResource(state, providerSchema)) || Map()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()

  const [jobProvider, { loading }] = useResource(
    `/job_providers/${match.params.id}`,
    jobProviderSchema,
    { id: match.params.id }
  )
  const {
    triggerWithData: startJob,
    update: completeJob,
    loading: updatingJob
  } = useResourceActions(jobProviderSchema)

  const destinationLat = jobProvider.getIn(['job', 'location', 'latitude'])
  const destinationLng = jobProvider.getIn(['job', 'location', 'longitude'])

  const getMapDirectionUrl = () => {
    if (latitude && longitude && destinationLat && destinationLng) {
      return `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destinationLat},${destinationLng}`
    }
  }

  const isPendingCompletion = jobProvider.get('state') === 'pending_completion'

  const handleUpdateLocation = () => {
    if (latitude && longitude) {
      return updateProviderLocation(
        `${provider.get('id')}`,
        'staging_current_locations',
        {
          latitude,
          longitude,
          updated_at: new Date()
        }
      )
    }
  }

  const updateLocation = useCallback(handleUpdateLocation, [
    provider,
    latitude,
    longitude
  ])

  useEffect(() => {
    let myUpdateLocation

    if (jobProvider.get('state') === 'heading_to_destination') {
      myUpdateLocation = setInterval(updateLocation, 150000)
    } else {
      clearInterval(myUpdateLocation)
    }
  }, [jobProvider, updateLocation])

  const handleTabChange = (e) => {
    setTab(e.detail.value)
  }

  const handleStartJob = () => {
    const jobStartTime = jobProvider.getIn(['job', 'start_time'])
    const second = 1000
    const halfHour = second * 60 * 30
    const _24Hour = second * 60 * 60 * 24
    const providerCanStartJobBefore = tenant.get('slug') === 'hdl' ? _24Hour : halfHour
    const startTime = (
      new Date(jobStartTime).valueOf() - providerCanStartJobBefore
    ).valueOf()
    const currentDate = new Date().valueOf()

    if (startTime <= currentDate) {
      return startJob(`/job_providers/${match.params.id}/depart`, {
        start_latitude: latitude,
        start_longitude: longitude
      }).then(() => {
        startJob(`/job_providers/${match.params.id}/start`, {
          start_latitude: latitude,
          start_longitude: longitude
        })

        updateLocation()
      })
    } else {
      setShowStartTimeAlert(true)
    }
  }

  const handleHeadingToDestination = () => {
    return startJob(`/job_providers/${match.params.id}/start`, {
      start_latitude: latitude,
      start_longitude: longitude
    }).then(() => {
      updateLocation()
      handleCompleteJob()
    })
  }

  function getContent() {
    return document.querySelector('.job-show-page')
  }

  const handleCompleteJob = () => {
    if (currentTab !== 'report') {
      getContent().scrollToPoint(0, 0, 500)
      setTab('report')
    } else {
      submitButtonRef.current.click()
    }
  }

  return (
    <PageLayout>
      <PageHeader
        title={jobProvider.getIn(['job', 'number'])}
        withBackButton
        backTo='/jobs'
      >
        <IonSegment
          mode='md'
          color='secondary'
          value={currentTab}
          onIonChange={handleTabChange}
        >
          <IonSegmentButton mode='md' value='details'>
            <IonLabel>{t('job_details')}</IonLabel>
          </IonSegmentButton>

          <IonSegmentButton mode='md' value='report' disabled={!isPendingCompletion}>
            <IonLabel>{t('your_report')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </PageHeader>

      <PageContent loading={loading} className='job-show-page'>
        {currentTab === 'details' ? (
          <JobDetailsPane
            jobProvider={jobProvider}
            match={match}
            mapDirectionUrl={getMapDirectionUrl()}
          />
        ) : (
          <JobReportPane
            reportQuestions={jobProvider.getIn(['job', 'report_questions'])}
            submitButtonRef={submitButtonRef}
            completeJob={completeJob}
            jobProvider={jobProvider}
            jobProviderId={match.params.id}
          />
        )}
        <IonAlert
          isOpen={showStartTimeAlert}
          onDidDismiss={() => setShowStartTimeAlert(false)}
          message={tenant.get('slug') === 'hdl' ? t('time_error_hdl') : t('time_error')}
          buttons={[
            {
              text: t('okay'),
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]}
        />

        <IonAlert
          isOpen={showStartJobAlert}
          onDidDismiss={() => setShowStartJobAlert(false)}
          message={t('ask_customer_about')}
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('okay'),
              handler: handleStartJob
            }
          ]}
        />

        <IonAlert
          isOpen={showJobReportAlert}
          onDidDismiss={() => setShowJobReportAlert(false)}
          message={t('required_field_error')}
          buttons={[
            {
              text: t('okay')
            }
          ]}
        />
        <JobComment job={jobProvider.get('job')} />
      </PageContent>

      <PageFooter>
        <JobActionFooter
          paymentMethod={jobProvider.getIn(['job', 'payment_method'])}
          payout={jobProvider.getIn(['job', 'payout'])}
          chargeCustomerPrice={jobProvider.getIn(['job', 'price'])}
          onActionClick={
            isPendingCompletion
              ? handleCompleteJob
              : jobProvider.get('state') === 'heading_to_destination'
              ? handleHeadingToDestination
              : () => setShowStartJobAlert(true)
          }
          actionLabel={
            isPendingCompletion ? t('complete_job') : t(jobProvider.get('state'))
          }
          disabled={updatingJob}
        />
      </PageFooter>

      {updatingJob && <Spinner overlaid />}
    </PageLayout>
  )
}

JobShowPage.propTypes = {
  match: PropTypes.object
}

export default JobShowPage
