import { Plugins } from '@capacitor/core'
import { searchHistorySchema } from '@seekster/schemas'

import request from 'utils/request'
import { getBasicAuth, getAccessToken } from 'selectors/authentication'

let previousRequest = null
const { Storage } = Plugins

export const getSearchHistory = (options = {}) => async (dispatch, getState) => {
  const query = options.query
  const language = await Storage.get({ key: 'locale' })

  if (previousRequest) {
    previousRequest.abort()
  }

  const req = request
    .auth(...getBasicAuth(getState()))
    .get(`/${searchHistorySchema._key}`)
    .query({ query, locale: language.value, per_page: 5 })
    .authentication(getAccessToken(getState()))

  previousRequest = req

  return new Promise((resolve) => {
    req.end((err, res) => {
      if (!err) {
        previousRequest = null
        resolve(res.body)
      }
    })
  })
}
