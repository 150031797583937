import { IonAvatar, IonImg, IonLabel } from '@ionic/react'
import React from 'react'

const TeamCustomerToast = ({ avatar, displayName }) => {
  return (
    <div
      className='custom-toast'
      style={{ width: '100%', display: 'flex', alignItems: 'center' }}
    >
      <IonAvatar slot='start' style={{ marginRight: '20px' }}>
        <IonImg src={avatar} alt='Provider Avatar' />
      </IonAvatar>
      <IonLabel>
        <h3>
          <strong>สลับไปใช้ {displayName}</strong>
        </h3>
      </IonLabel>
    </div>
  )
}

export default TeamCustomerToast
