import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ImagePicker } from '@ionic-native/image-picker'
import {
  Capacitor,
  Plugins,
  FilesystemDirectory,
  CameraResultType,
  CameraSource
} from '@capacitor/core'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { IonButton, IonIcon, isPlatform, IonModal } from '@ionic/react'
import { camera, closeCircle } from 'ionicons/icons'
import uuid4 from 'uuid/v4'
import { useHistory } from 'react-router-dom'

import './CameraInput.scss'
import './CustomCameraInput.scss'

const { Camera, Filesystem } = Plugins

defineCustomElements(window)

const CustomCameraInput = ({
  value,
  onChange,
  cameraOptions,
  required,
  swipeToClose = true
}) => {
  const { location, push } = useHistory()
  const [isShow, setIsShow] = useState(false)

  const writeFile = (data, fileName) => {
    return Filesystem.writeFile({
      data,
      path: `${fileName}.jpeg`,
      directory: FilesystemDirectory.Data
    })
  }

  const getPhotoUri = (fileName) => {
    return Filesystem.getUri({
      path: `${fileName}.jpeg`,
      directory: FilesystemDirectory.Data
    })
  }

  const onChoosePhotos = async () => {
    try {
      const options = {
        quality: isPlatform('ios') ? 5 : 20,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      }

      const originalPhotos = await ImagePicker.getPictures(options)
      const photosPath = await Promise.all(
        originalPhotos.map(async (path) => {
          const fileName = uuid4()

          const photoInTempStorage = await Filesystem.readFile({ path })

          await writeFile(photoInTempStorage.data, fileName)

          const finalPhotoUri = await getPhotoUri(fileName)
          const photoPath = Capacitor.convertFileSrc(finalPhotoUri.uri)

          return { file: photoPath }
        })
      )

      const currenyPhotoPaths = value || []

      const newValue = [...currenyPhotoPaths, ...photosPath]

      modalHandler(false)

      onChange && onChange(newValue)
    } catch (e) {
      console.error('ERROR', e)
    }
  }

  const handleClickThumbnail = (i) => {
    const getUrls = () => {
      const paths = value || []
      return paths.map(({ file } = {}) => file)
    }

    push(`${location.pathname}/report_photos`, {
      urls: getUrls(),
      initialSlide: i
    })
  }

  const handleDeletePhoto = async (e, uri) => {
    e.stopPropagation()

    if (isPlatform('ios')) {
      await Filesystem.deleteFile({
        path: uri,
        directory: FilesystemDirectory.Data
      })
    }

    let newValue = value.filter((_value) => _value.file !== uri)

    onChange && onChange(newValue)
  }

  const onTakePicture = async () => {
    try {
      const options = {
        quality: isPlatform('ios') ? 5 : 20,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        ...cameraOptions
      }

      const originalPhoto = await Camera.getPhoto(options)
      const path = originalPhoto.path || originalPhoto.webPath
      const photoInTempStorage = await Filesystem.readFile({ path })
      const fileName = uuid4()

      await Filesystem.writeFile({
        data: photoInTempStorage.data,
        path: `${fileName}.jpeg`,
        directory: FilesystemDirectory.Data
      })

      const finalPhotoUri = await Filesystem.getUri({
        path: `${fileName}.jpeg`,
        directory: FilesystemDirectory.Data
      })

      const photoPath = Capacitor.convertFileSrc(finalPhotoUri.uri)
      let newValue = photoPath

      const currenyPhotoPaths = value || []

      newValue = [...currenyPhotoPaths, { file: photoPath }]

      modalHandler(false)

      onChange && onChange(newValue)
    } catch (e) {
      console.error('ERROR', e)
    }
  }

  const renderThumbnail = () => {
    const thumbnail = (uri, i) => (
      <div
        key={uri}
        className='photo-thumbnail__container'
        onClick={() => handleClickThumbnail(i)}
      >
        <img src={uri} alt='Report_Photo' />

        <IonButton
          className='photo-thumbnail__remove-button'
          shape='round'
          fill='clear'
          size='small'
          onClick={(e) => handleDeletePhoto(e, uri)}
        >
          <IonIcon icon={closeCircle} size='large' color='medium' />
        </IonButton>
      </div>
    )

    const paths = value || []

    return paths.map(({ file } = {}, i) => file && thumbnail(file, i))
  }

  const modalHandler = (status) => {
    setIsShow(status)
  }

  return (
    <>
      <IonModal
        isOpen={isShow}
        cssClass='modal-container'
        swipeToClose={swipeToClose}
        onDidDismiss={() => modalHandler(false)}
      >
        <div className='modal-content'>
          <p
            className={isPlatform('ios') ? 'text-ios' : 'text-android'}
            onClick={onChoosePhotos}
          >
            From Photos
          </p>
          <p
            className={isPlatform('ios') ? 'text-ios' : 'text-android'}
            onClick={onTakePicture}
          >
            Take Picture
          </p>
        </div>
      </IonModal>

      <div className='camera-input-button__container'>
        <IonButton
          className='camera-input-button'
          color='light'
          onClick={() => modalHandler(true)}
        >
          <IonIcon icon={camera} size='large' color='medium' />
        </IonButton>

        {renderThumbnail()}

        <input
          required={required}
          value={value}
          style={{
            opacity: 0,
            height: 0,
            position: 'absolute',
            margin: '120px 0 0 50px'
          }}
        />
      </div>
    </>
  )
}

CustomCameraInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  cameraOptions: PropTypes.object,
  required: PropTypes.bool,
  swipeToClose: PropTypes.bool
}

export default CustomCameraInput
