import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getSearchHistory } from 'actions/searchHistory'

export function useSearchHistory(query) {
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  useEffect(() => {
    dispatch(getSearchHistory({ query })).then((res) => {
      setData(res)
    })
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data
  }
}
