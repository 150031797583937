import React from 'react'

import './FacebookLoginButton.scss'
import { IonButton, IonText, IonIcon } from '@ionic/react'
import { logoFacebook } from 'ionicons/icons'

const FacebookLoginButton = ({ handleClick, children }) => {
  return (
    <IonButton
      className='facebook-sign-in-button'
      expand='block'
      onClick={() => handleClick()}
    >
      <IonIcon
        className='facebook-sign-in-button__icon'
        slot='start'
        icon={logoFacebook}
      />
      <IonText className='facebook-sign-in-button__text'>{children}</IonText>
    </IonButton>
  )
}

export default FacebookLoginButton
