import React from 'react'
import {
  IonGrid, IonRow, IonCol, IonText, IonSkeletonText, IonItem, IonAvatar
} from '@ionic/react'

import './JobSkeletonCard.scss'

const JobSkeletonCard = () => {
  return (
    <IonItem
      detail={false}
      lines='full'
      className='list-item'
    >
      <IonGrid className='job-card-container' fixed>
        <IonRow>
          <IonCol size='9'>
            <IonRow className='job-number-row'>
              <IonText className='job-number'>
                <IonSkeletonText animated style={{ width: 80 }} />
              </IonText>
            </IonRow>

            <IonRow className='service-name-row'>
              <IonText className='service-name'>
                <IonSkeletonText animated style={{ width: 80 }} />
              </IonText>
            </IonRow>

            <IonRow className='start-time-row'>
              <IonCol size='1' className='clock-icon-column'>
                <IonRow>
                  <img
                    className='clock-icon'
                    src='/assets/img/clock.png'
                    alt='Clock'
                  />
                </IonRow>
              </IonCol>

              <IonCol>
                <IonRow>
                  <IonText className='start-time'>
                    <IonSkeletonText animated style={{ width: 140 }} />
                  </IonText>
                </IonRow>

                <IonRow>
                  <IonText className='start-time'>
                    <IonSkeletonText animated style={{ width: 140 }} />
                  </IonText>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonCol>

          <IonCol size='3'>
            <IonRow className='row-flex-end'>
              <IonAvatar>
                <IonSkeletonText animated style={{ width: 40 }} />
              </IonAvatar>
            </IonRow>

            <IonRow className='row-flex-end'>
              <IonText className='payout' color='secondary'>
                <IonSkeletonText animated style={{ width: 60 }} />
              </IonText>
            </IonRow>
          </IonCol>
        </IonRow>

        <IonRow className='address-row'>
          <IonCol size='1' className='marker-icon-column'>
            <IonRow>
              <img
                className='marker-icon'
                src='/assets/img/map_marker.png'
                alt='Marker'
              />
            </IonRow>
          </IonCol>

          <IonCol>
            <IonRow>
              <IonText className='address'>
                <IonSkeletonText animated style={{ width: 250 }} />
                <IonSkeletonText animated style={{ width: 200 }} />
              </IonText>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>

  )
}

export default JobSkeletonCard
