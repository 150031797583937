import { Map } from 'immutable'

import {
  ENTER_MODAL,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAIL,
  LEAVE_MODAL,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_SUCCESS,
  INVALIDATE_VERIFICATION_SUCCESS,
  INVALIDATE_VERIFICATION_FAIL
} from 'constants/phone_verification'

const initialState = Map()

const phoneVerificationReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case ENTER_MODAL:
      return state.merge({ enteredFrom: response })
    case LEAVE_MODAL:
      return state.remove('enteredFrom')
    case GENERATE_OTP_SUCCESS:
      return state.merge(response)
    case GENERATE_OTP_FAIL:
    case VERIFY_OTP_SUCCESS:
    case VERIFY_OTP_FAIL:
    case INVALIDATE_VERIFICATION_SUCCESS:
    case INVALIDATE_VERIFICATION_FAIL:
      return state
    default:
      return state
  }
}

export default phoneVerificationReducer
