import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { IonItemGroup, IonItemDivider, IonList, IonItem, IonLabel } from '@ionic/react'
import distanceDifferent from 'utils/distanceDifferent'

import JobCard from 'components/JobCard'
import { DateFormatter } from 'components/formatters'

import './LeadList.scss'

const LeadList = ({
  data,
  isDistance,
  distanceEnabled,
  latitude,
  longitude,
  restDates,
  availableDates
}) => {
  const { push } = useHistory()

  function compare(a, b) {
    if (isDistance) {
      if (
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'latitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'longitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        ) <
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'latitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'longitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        )
      ) {
        return -1
      }
      if (
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'latitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !a.getIn(['inquiry', 'has_logistics'])
              ? a.getIn(['inquiry', 'location', 'longitude'])
              : a.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        ) <
        parseFloat(
          distanceDifferent(
            latitude,
            longitude,
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'latitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'latitude']),
            !b.getIn(['inquiry', 'has_logistics'])
              ? b.getIn(['inquiry', 'location', 'longitude'])
              : b.getIn(['inquiry', 'route', 'origin', 'longitude'])
          )
        )
      ) {
        return 1
      }
      return 0
    }
  }

  const checkOrderIsSpecial = (orders) => {
    if (!orders) return
    orders = orders.toJS()
    const isFound = orders.find((order) => order.package.is_special)
    return isFound !== undefined
  }

  return (
    <IonList>
      {data.map((group) => (
        <IonItemGroup key={group.get('start_time')}>
          <IonItemDivider className='group-header' color='secondary' sticky>
            <IonLabel className='group-label'>
              <DateFormatter value={group.get('start_time')} format='DD MMMM YYYY' />
            </IonLabel>
          </IonItemDivider>
          {
            // eslint-disable-next-line array-callback-return
            group
              .get('leads')
              .sort(compare)
              // eslint-disable-next-line
              .map((lead) => {
                if (lead.getIn(['inquiry', 'state']) === 'pending_provider') {
                  return (
                    <IonItem
                      key={lead.get('id')}
                      button
                      onClick={() => push(`/leads/${lead.getIn(['inquiry', 'id'])}`)}
                      detail={false}
                      lines='full'
                      className='list-item'
                    >
                      <JobCard
                        type={lead.get('type')}
                        jobNumber={lead.getIn(['inquiry', 'number'])}
                        serviceName={lead.getIn(['inquiry', 'service', 'name'])}
                        serviceIconUrl={lead.getIn(['inquiry', 'service', 'icon_url'])}
                        chargeCustomerPrice={lead.getIn([
                          'inquiry',
                          'price',
                          'display_value'
                        ])}
                        payout={lead.getIn(['inquiry', 'payout', 'display_value'])}
                        paymentMethod={lead.getIn(['inquiry', 'payment_method'])}
                        startTime={lead.get('start_time')}
                        restDates={restDates}
                        availableDates={availableDates}
                        endTime={lead.get('end_time')}
                        address={lead.getIn(['inquiry', 'location', 'masked_address'])}
                        state={lead.get('state')}
                        preferredBy={lead.getIn(['inquiry', 'preferred_customer'])}
                        hasLogistics={lead.getIn(['inquiry', 'has_logistics'])}
                        route={lead.getIn(['inquiry', 'route'])}
                        remark={lead.getIn(['inquiry', 'remarks_for_provider'])}
                        distanceEnabled={
                          latitude !== null && latitude !== null && distanceEnabled
                        }
                        distance={distanceDifferent(
                          latitude,
                          longitude,
                          !lead.getIn(['inquiry', 'has_logistics'])
                            ? lead.getIn(['inquiry', 'location', 'latitude'])
                            : lead.getIn(['inquiry', 'route', 'origin', 'latitude']),
                          !lead.getIn(['inquiry', 'has_logistics'])
                            ? lead.getIn(['inquiry', 'location', 'longitude'])
                            : lead.getIn(['inquiry', 'route', 'origin', 'longitude'])
                        )}
                        isSpecial={checkOrderIsSpecial(lead.getIn(['orders']))}
                      />
                    </IonItem>
                  )
                }
              })
          }
        </IonItemGroup>
      ))}
    </IonList>
  )
}

LeadList.propTypes = {
  data: ImmutablePropTypes.list,
  availableDates: ImmutablePropTypes.list,
  restDates: ImmutablePropTypes.list,
  isDistance: PropTypes.bool,
  distanceEnabled: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number
}

export default LeadList
