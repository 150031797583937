import React from 'react'
import { IonAlert } from '@ionic/react'

const VerifyAlertError = ({ errorHeader, errorBody, closeText, isOpen, dissmiss }) => {
  return (
    <IonAlert
      isOpen={isOpen}
      header={errorHeader}
      message={errorBody}
      buttons={[
        {
          text: closeText,
          handler: () => dissmiss()
        }
      ]}
    />
  )
}

export default VerifyAlertError
