import React, { useState, useEffect } from 'react'
import { IonText, IonImg, IonRow, IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { PageLayout, PageContent, PageHeader, PageFooter } from 'components/layout'

import { providerSchema } from '@seekster/schemas'
import { useResource } from 'hooks/resources'
import referAFriend from 'assets/svg/image_refer_a_friend.svg'

import { useHistory } from 'react-router-dom'

import './ReferralPage.scss'
import InfoButton from 'components/InfoButton'

import { shareUniversalUrl } from 'utils/share'

const ReferralPage = () => {
  const BASE_URL = process.env.REACT_APP_APPLICATION_URL

  const { t } = useTranslation(['settings', 'referral'])
  const { push } = useHistory()
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const [referralCount, setReferralCount] = useState(0)
  const [unitText, setUnitText] = useState('')
  const [referralUrl, setReferralUrl] = useState('')

  useEffect(() => {
    const providerReferrals = provider.get('referrals')
    setReferralUrl(
      new URL(
        `${BASE_URL}/sign_up_referral?referrer_id=${provider.get(
          'id'
        )}&referrer_name=${provider.get('first_name')}`
      )
    )
    if (providerReferrals) {
      setUnitText(providerReferrals.size === 1 ? t('friend') : t('friends'))
      setReferralCount(providerReferrals.size)
    }
  }, [provider, t, BASE_URL])

  return (
    <PageLayout>
      <PageHeader title={t('refer_friend')} withBackButton backTo='/settings' />
      <PageContent className='refer-friend-page-container'>
        <IonRow className='refer-friend-header-row'>
          <IonImg className='refer-friend-header-image' src={referAFriend} />
        </IonRow>
        <div className='refer-friend-content-container'>
          <IonRow className='refer-friend-content-header-row'>
            <IonText className='refer-friend-content-header-text'>
              {t('refer_friend_content_header_text')}
            </IonText>
          </IonRow>
          <IonRow className='refer-friend-content-row'>
            <IonText className='refer-friend-content-text'>
              {t('refer_friend_content')}
            </IonText>
          </IonRow>
          <IonRow
            className='refer-friend-info-button refer-friend-info-button-one'
            onClick={() => push('/settings/referral/status')}
          >
            <InfoButton
              colorOne='#41CCE7'
              colorTwo='#5372FF'
              angle={211}
              heading={t('your_referrals_status')}
              content={t('friends_referred')}
              data={referralCount}
              unit={unitText}
            />
          </IonRow>
        </div>
      </PageContent>
      <PageFooter className='ion-no-border'>
        <IonRow className='refer-friend-footer'>
          <IonButton
            block
            className='refer-friend-universal-url-btn'
            onClick={() => shareUniversalUrl('Friend referral', referralUrl.href)}
          >
            {t('refer_friends')}
          </IonButton>
        </IonRow>
      </PageFooter>
    </PageLayout>
  )
}

export default ReferralPage
