export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED'
export const SIGN_UP_SUCCEEDED = 'SIGN_UP_SUCCEEDED'
export const SIGN_OUT = 'SIGN_OUT'
export const FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED =
  'FETCH_TENANT_WITH_BASIC_AUTH_SUCCEEDED'
export const FETCH_CURRENT_DEVICE_SUCCEEDED = 'FETCH_CURRENT_DEVICE_SUCCEEDED'
export const UPDATE_CURRENT_DEVICE_SUCCEEDED = 'UPDATE_CURRENT_DEVICE_SUCCEEDED'
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED'
export const UPDATE_PASSWORD_SUCCEEDED = 'UPDATE_PASSWORD_SUCCEEDED'
export const INITIATE_PHONE_AUTH_SUCCEEDED = 'INITIATE_PHONE_AUTH_SUCCEEDED'
export const INITIATE_PHONE_AUTH_FAILED = 'INITIATE_PHONE_AUTH_FAILED'
