import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IonGrid, IonRow, IonCol, IonText, IonAvatar, IonImg } from '@ionic/react'
import './ReferralCard.scss'

const ReferralCard = ({ referral }) => {
  const { t } = useTranslation(['referral'])

  const formatDate = (date) => {
    return date.split('T')[0].replace('/-/g', '/')
  }

  const setStatusClass = (state, completedJobs) => {
    if (state === 'registering') return 'registering'
    else if (state === 'approved' && completedJobs < 5) return 'approved'
    else if (state === 'approved' && completedJobs >= 5) return 'completed'
    else return 'registering'
  }

  const setReferralStatusText = (state, completedJobs) => {
    if (state === 'registering') return t('referral_registered')
    else if (state === 'approved' && completedJobs < 5) return t('referral_working')
    else if (state === 'approved' && completedJobs >= 5) return t('referral_completed')
    else return t('referral_registered')
  }

  const setCompletedJobsString = (state, completedJobs) => {
    if (completedJobs >= 5) return '5/5'
    if (completedJobs === 0 && state === 'approved') return '0/5'
    if (completedJobs > 0) return `${completedJobs}/5`
    return ''
  }

  const state = referral.get('state')
  const jobsCompleted = referral.get('jobs_completed')

  const referralStatusText = setReferralStatusText(state, jobsCompleted)
  const statusClass = setStatusClass(state, jobsCompleted)
  const date = formatDate(referral.get('referred_on'))
  const completedJobs = setCompletedJobsString(state, jobsCompleted)
  return (
    <IonGrid fixed>
      <IonRow className='referral-card-container'>
        <IonCol className='referral-card-image-col' size='3'>
          <IonRow className='referral-card-image-row'>
            <IonAvatar className='referral-card-avatar'>
              <IonImg
                className='referral-card-avatar-img'
                src={referral.get('avatar_url')}
              />
            </IonAvatar>
          </IonRow>
        </IonCol>
        <IonCol className='referral-card-name-data-col'>
          <IonRow className='referral-card-name-row'>
            <IonText className='referral-card-name-text'>
              {referral.get('display_name')}
            </IonText>
          </IonRow>
          <IonRow className='referral-card-info-row'>
            <IonText className={statusClass}>
              {referralStatusText} {completedJobs}
            </IonText>
          </IonRow>
          <IonRow className='referral-card-date-row'>
            <IonText className='referral-card-date-text'>
              {t('registered_on')} {date}
            </IonText>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

ReferralCard.propTypes = {
  referral: PropTypes.object
}

export default ReferralCard
