import React from 'react'

import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonRow } from '@ionic/react'
import { PageLayout } from 'components/layout'

import './VerifyCompletePage.scss'
import TenantLogoHeader from 'components/TenantLogoHeader'
import Subheader from 'components/typography/Subheader'
import Paragraph from 'components/typography/Paragraph'
import AvatarCard from 'components/AvatarCard'
import { useResource } from 'hooks/resources'
import { providerSchema, tenantSchema } from '@seekster/schemas'
import Header from 'components/typography/Header/Header'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useEnterModal } from 'hooks/phone_verification'
import { getCurrentResource } from 'selectors/authentication'
import { useSelector } from 'react-redux'
const VerifyCompletePage = () => {
  const { t } = useTranslation(['verify_phone_number'])
  const [provider] = useResource('/provider', providerSchema, { implicit: true })
  const { replace } = useHistory()
  const { enteredFrom } = useEnterModal()
  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const navigate = () => replace(enteredFrom || '/main', { fetch: true })
  const buttonText =
    enteredFrom === '/settings/profile' ? t('complete.continue') : t('complete.start_now')
  return (
    <PageLayout withSafeAreaTop>
      <IonContent>
        <IonGrid className='verify-complete-grid'>
          <IonRow className='verify-complete-logo-row'>
            <TenantLogoHeader url={tenant.get('dark_logo_url')} />
          </IonRow>
          <IonRow className='verify-complete-header-row'>
            <Header>{t('complete.header')}</Header>
          </IonRow>
          <IonRow className='verify-complete-subheader-row'>
            <Subheader>{t('complete.subheader')}</Subheader>
          </IonRow>
          <IonRow className='verify-complete-paragraph-row'>
            <Paragraph>
              {t('complete.paragraph', { tenant: tenant.get('name') })}
            </Paragraph>
          </IonRow>
          <IonCol className='verify-complete-lower'>
            <IonRow className='verify-complete-avatar-row'>
              <AvatarCard provider={provider} />
            </IonRow>
            <IonRow>
              <IonImg src='assets/img/shield-checkmark-outline.svg' />
            </IonRow>
            <IonRow className='verify-complete-subheader-2-row'>
              <Subheader>{t('complete.subheader2')}</Subheader>
            </IonRow>
            <IonRow className='verify-complete-paragraph-2-row'>
              <Paragraph>{t('complete.paragraph2')}</Paragraph>
            </IonRow>
          </IonCol>
          <IonRow>
            <IonCol>
              <IonButton onClick={() => navigate()} expand='block'>
                {buttonText}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </PageLayout>
  )
}

export default VerifyCompletePage
