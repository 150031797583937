import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { IonButton, IonSpinner } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { jobProviderSchema, tenantSchema } from '@seekster/schemas'

import { PageLayout, PageHeader, PageContent } from 'components/layout'
import JobHistoryList from 'components/lists/JobHistoryList'
import EmptyListPlaceholder from 'components/lists/EmptyListPlaceholder'
import SearchInput from 'components/inputs/SearchInput'
import { useCollection } from 'hooks/collections'
import { useEventId } from 'hooks/utils'
import groupByStartDate from 'utils/groupByStartDate'
import { getCurrentResource } from 'selectors/authentication'

const JobHistoryIndexPage = () => {
  const searchRef = useRef(null)
  const { t, i18n } = useTranslation(['jobs', 'translation'])
  const [currentPage, setCurrentPage] = useState(1)
  const [eventId, generateEventId] = useEventId()
  const [query, setQuery] = useState('')
  const [jobProviders, { loading, totalCount, totalPages }] = useCollection(
    '/job_providers',
    jobProviderSchema,
    {
      dependencies: [eventId, i18n.language],
      query: { history: true, page: currentPage, query },
      cacheKey: `job_providers_history${query}`
    }
  )

  const tenant = useSelector((state) => getCurrentResource(state, tenantSchema)) || Map()
  const searchEnabled = tenant.getIn(['general_configuration', 'search_provider_enabled'])

  const handleRefresh = () => {
    generateEventId()
    setCurrentPage(1)

    if (searchEnabled) setQuery(searchRef.current.value)
  }

  const handleLoadMore = () => {
    setCurrentPage((page) => page + 1)
  }

  return (
    <PageLayout>
      <PageHeader title={t('job_history')} withBackButton backTo='/jobs' />
      {searchEnabled && (
        <SearchInput
          ref={searchRef}
          count={jobProviders.count()}
          loading={loading}
          onSearch={setQuery}
          placeholder={t('search_placeholder')}
        />
      )}
      <PageContent onRefresh={handleRefresh} loading={loading && currentPage === 1}>
        {jobProviders.isEmpty() ? (
          <EmptyListPlaceholder
            message={
              searchRef.current?.value ? t('search_not_found') : t('no_jobs_message')
            }
          />
        ) : (
          <JobHistoryList
            data={groupByStartDate(
              jobProviders,
              jobProviderSchema,
              ['job', 'start_time'],
              'desc'
            )}
          />
        )}

        {totalCount > 30 && currentPage < totalPages && (
          <IonButton expand='full' color='light' onClick={handleLoadMore}>
            {loading ? <IonSpinner color='secondary' /> : t('load_more')}
          </IonButton>
        )}
      </PageContent>
    </PageLayout>
  )
}

export default JobHistoryIndexPage
