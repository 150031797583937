import React from 'react';
import PropTypes from 'prop-types';

import './ProviderHeader.scss';
import { IonRow, IonCol, IonAvatar, IonImg, IonText } from '@ionic/react';

import rate_star_icon from 'assets/png/rate_star_icon.png';

const ProviderHeader = ({ displayName, identifier, imgUrl, rating }) => {
  const truncateString = (str, maxLength) => {
    var truncatedString = '';
    if (str.length < maxLength) {
      return str;
    }
    truncatedString = str.substring(0, maxLength - 3);
    truncatedString += '...';
    return truncatedString;
  };

  const truncatedDisplayName = truncateString(displayName, 26);
  return (
    <IonRow className='provider-header-container'>
      <IonCol size='9'>
        <IonRow className='provider-header-display-name-row'>
          <IonText className='provider-header-display-name'>
            {truncatedDisplayName}
          </IonText>
        </IonRow>
        <IonRow className='provider-header-address'>
          {identifier}
          <IonImg src={rate_star_icon} className='provider-header-rating' />
          {rating ? rating : 0}
        </IonRow>
      </IonCol>
      <IonCol className='provider-header-img-col'>
        <IonAvatar>
          <IonImg src={imgUrl} />
        </IonAvatar>
      </IonCol>
    </IonRow>
  );
};


ProviderHeader.propTypes = {
  displayName: PropTypes.string,
  address: PropTypes.string,
  zipCode: PropTypes.string,
  imgUrl: PropTypes.string,
  rating: PropTypes.string
};

export default ProviderHeader;
