import { Map } from 'immutable'

import {
  SET_OPEN_POLICY_MODAL,
  SET_CLOSE_POLICY_MODAL,
  ACCEPT_POLICY_SUCCESS,
  DECLINE_POLICY_SUCCESS,
  ACCEPT_POLICY_FAIL,
  DECLINE_POLICY_FAIL
} from 'constants/policy'

const initialState = Map({
  open: false,
  variant: false
})

const policyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_OPEN_POLICY_MODAL:
      return Map(payload)
    case SET_CLOSE_POLICY_MODAL:
      return Map(initialState)
    case ACCEPT_POLICY_SUCCESS:
    case DECLINE_POLICY_SUCCESS:
    case ACCEPT_POLICY_FAIL:
    case DECLINE_POLICY_FAIL:
      return state.set('open', false).set('variant', undefined)
    default:
      return state
  }
}

export default policyReducer
