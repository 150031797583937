import React, { useEffect, useState } from 'react'
import {
  IonAvatar,
  IonButton,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonThumbnail,
  useIonViewWillEnter
} from '@ionic/react'
import moment from 'moment'
import {
  call,
  informationCircleOutline,
  location,
  peopleCircle,
  star
} from 'ionicons/icons'
import { jobSchema } from '@seekster/schemas'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

import { useTeamDistribution } from 'hooks/teamDistribution'
import distanceDifferent from 'utils/distanceDifferent'
import { useResource } from 'hooks/resources'

import { PageFooter, PageHeader, PageContent } from 'components/layout'
import ListSkeleton from 'components/ListSkeleton'

import './ProviderDetailPage.scss'

const ProviderDetailPage = ({ readOnly, match }) => {
  const { provider_id: providerId, job_id: jobId } = match.params
  const { fetchTeam, teamPostRequest, currentTeam } = useTeamDistribution()
  const [provider, setProvider] = useState({})
  const [inprogressJobs, setInprogressJob] = useState([])
  const [pendingStartJobCount, setPendingStartJobCount] = useState(0)
  const [completedJobsCount, setCompletedJobsCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('team_lead')

  useEffect(() => {
    if (provider._id) {
      setLoading(true)

      fetchTeam('/jobs', {
        query: {
          status: ['pending_start', 'pending_completion', 'completed'],
          sort: 'startTime:asc',
          team: currentTeam?._id,
          provider: provider._id
        }
      })
        .then((res) => {
          const jobs = res.body
          const pendingJobs = jobs.filter(
            (job) => job.status === 'pending_start' || job.status === 'pending_completion'
          )
          const completedJobs = jobs.filter((job) => job.status === 'completed')
          setCompletedJobsCount(completedJobs.length)
          const pendingStartJobs = jobs.filter((job) => job.status === 'pending_start')
          setPendingStartJobCount(pendingStartJobs.length)

          setInprogressJob(pendingJobs)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [providerId, provider._id])

  useEffect(() => {
    if (currentTeam) {
      const _provider = currentTeam.members.find(
        (member) => member.member.wfUserId == providerId
      )

      if (_provider) {
        setProvider(_provider.member)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam])

  const handleRefresh = () => {}

  useIonViewWillEnter(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = 'none'
  })

  const handleAssignProvider = async () => {
    try {
      const { body } = await teamPostRequest(
        `/jobs/${jobId}/providers/${providerId}/assign`
      )
      await Swal.fire({
        html:
          '<span style="display: inline-flex;">' +
          '<img style="border-radius: 50%;margin-right: 20px;margin-top: 16px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
          `<p style="color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">${t(
            'assign_job_to'
          )} ${body.provider.fullName}</p>` +
          '</span>',
        showConfirmButton: false,
        timer: 1500
      })

      window.location.href = '/team/work/pending'
    } catch (error) {
      alert(error.message)
    }
  }

  const renderChipLabel = (state) => {
    switch (state) {
      case 'pending_start':
        return 'Pending Start'
      case 'pending_completion':
        return 'Pending Complete'
      case 'pending_provider':
        return 'Pending Provider'
      case 'completed':
        return 'Complete'
      case 'cancelled':
        return 'Cancel'
      case 'expired':
        return 'Expired'
      default:
        return state
    }
  }

  return (
    <PageContent onRefresh={handleRefresh}>
      <IonPage withSafeAreaTop className='provider-detail-page'>
        <PageHeader
          className='assign-provder-page___page-header'
          title={t('provider_info')}
          withBackButton
        />
        <IonHeader className='provider-detail-page___sub-header'>
          <IonItem lines='full' className='provider-profile ion-align-items-start'>
            <IonAvatar slot='start'>
              <IonImg src={provider?.avatarUrl || 'assets/img/default_avatar.png'} />
            </IonAvatar>
            <div className='provider-profile___content'>
              <IonLabel className='name'>{provider?.fullName}</IonLabel>
              <div className='distance'>
                <IonIcon slot='start' icon={location} />
                {jobId ? (
                  <ShowDistanceBetweenJobAndProvider
                    jobId={jobId}
                    provider={provider}
                    t={t}
                  />
                ) : (
                  <IonLabel>
                    {provider?.homeAddress?.address
                      ? provider?.homeAddress?.address
                      : '-'}
                  </IonLabel>
                )}
              </div>
              <div className='team'>
                <IonIcon icon={peopleCircle} />
                <IonLabel>
                  {t('team')} {currentTeam?.name}
                </IonLabel>
              </div>
              <div className='rating'>
                <IonIcon icon={star} />
                <IonLabel>
                  {t('score')} {provider?.rating} {t('star')}
                </IonLabel>
              </div>
            </div>
          </IonItem>
          <IonItem lines='full' className='provider-detail --inner-padding-top'>
            <div slot='start' className='pending-job-number ion-no-margin'>
              <div>{pendingStartJobCount}</div>
              <div style={{ fontSize: '12px' }}>{t('pending_start')}</div>
            </div>
            <div className='completed-job-number ion-no-margin'>
              <div>
                {completedJobsCount}
                <div style={{ fontSize: '12px' }}>{t('completed')}</div>
              </div>
              <IonIcon icon={informationCircleOutline} />
            </div>
            <div slot='end' className='contact-provider ion-no-margin'>
              <a
                href={`tel:${provider?.phoneNumber}`}
                target='_blank'
                rel='noreferrer noopener'
              >
                <IonChip>
                  <IonIcon icon={call} />
                  <IonLabel>{t('contact_provider')}</IonLabel>
                </IonChip>
              </a>
            </div>
          </IonItem>
        </IonHeader>
        <IonContent className='provider-detail-page___content'>
          {loading && <ListSkeleton />}
          <IonList className=''>
            {inprogressJobs.map((jobProvider) => (
              <IonItem key={jobProvider._id} lines='full' className='job'>
                <IonThumbnail slot='start'>
                  <IonImg src={jobProvider.service?.iconUrl} alt='icon' />
                </IonThumbnail>
                <div className='job-body'>
                  <div style={{ textDecoration: 'underline', fontWeight: 500 }}>
                    {jobProvider.number}
                  </div>
                  <div>
                    <small>{moment().format('ddd, D MMM YYYY - hh:mm')}</small>
                  </div>
                </div>
                <IonChip slot='end' className={`job-state ${jobProvider.status}`}>
                  {renderChipLabel(jobProvider.status)}
                </IonChip>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
        {jobId && providerId && (
          <PageFooter className='provider-detail-page___footer'>
            <IonButton expand='block' onClick={handleAssignProvider}>
              {t('assign_job')}
            </IonButton>
          </PageFooter>
        )}
      </IonPage>
    </PageContent>
  )
}

export default ProviderDetailPage

const ShowDistanceBetweenJobAndProvider = ({ jobId, provider, t }) => {
  const [job] = useResource(`/jobs/${jobId}`, jobSchema, {
    id: jobId
  })

  return (
    <IonLabel>
      {t('distance_from_this_work')}{' '}
      {job.get('id') && provider._id
        ? distanceDifferent(
            provider?.address?.latitude,
            provider?.address?.longitude,
            job.getIn(['location', 'latitude']),
            job.getIn(['location', 'longitude'])
          )
        : '-'}{' '}
      {t('km')}
    </IonLabel>
  )
}
