import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { IonText } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import Segment from 'components/Segment'
import { JobDetailsSegment, JobStartTimeSegment } from 'modules/segments'

const JobHistoryDetailsPane = ({ jobProvider }) => {
  const { t } = useTranslation('jobs')

  return (
    <>
      <Segment title={t('location')} noPadding>
        <IonText className='lead-address'>
          {jobProvider.getIn(['job', 'location', 'masked_address'])}
        </IonText>
      </Segment>

      <JobStartTimeSegment
        job={jobProvider.get('job')}
      />

      <JobDetailsSegment
        number={jobProvider.getIn(['job', 'number'])}
        service={jobProvider.getIn(['job', 'service'])}
        orders={jobProvider.getIn(['job', 'orders'])}
        answers={jobProvider.getIn(['job', 'answers'])}
        additionalInfo={jobProvider.getIn(['job', 'inquiry', 'additional_information'])}
        attachments={jobProvider.getIn(['job', 'attachments'])}
      />
    </>
  )
}

JobHistoryDetailsPane.propTypes = {
  jobProvider: ImmutablePropTypes.map
}

export default JobHistoryDetailsPane
