import { IonPage } from '@ionic/react'
// import { inquirySchema } from '@seekster/schemas'
import GoogleDynamicMap from 'components/GoogleDynamicMap'
import { PageContent, PageHeader } from 'components/layout'
import { useCurrentLocation } from 'hooks/interfaces'
// import { useResource } from 'hooks/resources'
import { useTeamDistribution } from 'hooks/teamDistribution'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useHistory } from 'react-router'
import generateOffsetCoordinate from 'utils/generateOffsetCoordinate'
import get from 'lodash/get'

const JobMapPage = ({ match: { params } }) => {
  const jobId = params.job_id || params.id

  const [job, setJob] = useState()

  const { t } = useTranslation()
  // const history = useHistory()

  const { fetchTeam } = useTeamDistribution()

  // const [inquiry] = useResource(`/inquiries/${jobId}`, inquirySchema, { id: jobId })

  const [{ latitude, longitude }] = useCurrentLocation()
  const isDelivery = get(job, ['job', 'inquiry', 'has_logistics'])
  const destination = generateOffsetCoordinate(
    {
      latitude: isDelivery
        ? get(job, ['job', 'inquiry', 'route', 'destination', 'latitude'])
        : get(job, ['job', 'location', 'latitude']),
      longitude: isDelivery
        ? get(job, ['job', 'inquiry', 'route', 'destination', 'longitude'])
        : get(job, ['job', 'location', 'longitude'])
    },
    500
  )

  const fetch = async () => {
    const res = await fetchTeam(`/jobs/${jobId}`)
    setJob(res.body)
  }

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId])

  return (
    <IonPage className='team-job-map-page'>
      <PageHeader title={t('map')} withBackButton />
      <PageContent>
        <GoogleDynamicMap
          current={{ latitude, longitude }}
          destination={destination}
          marker='pin'
        />
      </PageContent>
    </IonPage>
  )
}

export default JobMapPage
