import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrentPosition } from '@ionic/react-hooks/geolocation'

import { setCurrentLocation } from 'actions/interfaces'

export const useCurrentLocation = () => {
  const { currentPosition, getPosition, isAvailable } = useCurrentPosition()
  const dispatch = useDispatch()

  const coordinates = useSelector(state => state.getIn(['interfaces', 'currentLocation'])) || {}

  useEffect(() => {
    if (isAvailable && currentPosition && currentPosition.coords) {
      const { latitude, longitude } = currentPosition.coords

      dispatch(setCurrentLocation({ latitude, longitude }))
    }
  }, [currentPosition, isAvailable, dispatch])

  return [coordinates, getPosition]
}
