import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { IonItem, IonText, IonLabel, IonList, IonChip } from '@ionic/react'
import { Map, List } from 'immutable'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Segment from 'components/Segment'
import { DateFormatter, TimeRangeFormatter } from 'components/formatters'

import './JobStartTimeSegment.scss'

const JobStartTimeSegment = ({
  jobs,
  job,
  isDelivery,
  restDates,
  availableDates,
  ...rest
}) => {
  const { t } = useTranslation()

  const _jobs = job.isEmpty() ? jobs : List([job])

  return (
    <Segment title={t('start_time')} noPadding {...rest}>
      <IonList lines='full' className='start-time-list'>
        {_jobs.map((job) => {
          const startTime = moment(job.get('start_time'))
          let ava = []
          let rest = []
          if (availableDates) {
            ava = availableDates.filter((date) =>
              moment(date.get('start_time')).isSame(job.get('start_time'), 'day')
            )
          }

          if (restDates) {
            rest = restDates.filter((date) =>
              moment(date.get('start_time')).isSame(job.get('start_time'), 'day')
            )
          }

          return (
            <IonItem key={job.get('id')} className='start-time-item'>
              <IonText slot='start' className='start-time__date'>
                <IonLabel>{startTime.date()}</IonLabel>
                <IonLabel>{startTime.format('ddd')}</IonLabel>
              </IonText>

              <IonText className='start_time__full-date-time'>
                <IonLabel>
                  <DateFormatter
                    value={job.get('start_time')}
                    format='dddd, DD MMMM YYYY'
                  />
                </IonLabel>

                {ava.size > 0 ? (
                  <IonChip className='time-available-chip'>
                    <IonLabel className='time-available-chip-label'>
                      วันพร้อมรับงาน
                    </IonLabel>
                  </IonChip>
                ) : rest.size > 0 ? (
                  <IonChip className='time-disabled-chip'>
                    <IonLabel className='time-disabled-chip-label'>งานในวันหยุด</IonLabel>
                  </IonChip>
                ) : null}

                <IonLabel>
                  <TimeRangeFormatter
                    value={{
                      startTime: job.get('start_time'),
                      endTime: isDelivery ? job.get('start_time') : job.get('end_time')
                    }}
                  />
                </IonLabel>
              </IonText>
            </IonItem>
          )
        })}
      </IonList>
    </Segment>
  )
}

JobStartTimeSegment.propTypes = {
  job: ImmutablePropTypes.map,
  jobs: ImmutablePropTypes.list,
  availableDates: ImmutablePropTypes.list,
  restDates: ImmutablePropTypes.list,
  isDelivery: PropTypes.bool
}

JobStartTimeSegment.defaultProps = {
  job: Map(),
  jobs: List()
}

export default JobStartTimeSegment
