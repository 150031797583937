import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { useHistory } from 'react-router-dom'
import { IonItemGroup, IonItemDivider, IonList, IonItem, IonLabel } from '@ionic/react'

import JobCard from 'components/JobCard'
import { DateFormatter } from 'components/formatters'

import './JobList.scss'

const JobList = ({ data }) => {
  const { push } = useHistory()

  const navigateToJob = (jobProvider) => {
    jobProvider.get('job').get('route')
      ? push(`/jobs/${jobProvider.get('id')}/delivery`)
      : push(`/jobs/${jobProvider.get('id')}`)
  }

  return (
    <IonList style={{ marginBottom: 0 }}>
      {data.map((group) => (
        <IonItemGroup key={group.get('start_time')}>
          <IonItemDivider className='group-header' color='secondary' sticky>
            <IonLabel className='group-label'>
              <DateFormatter value={group.get('start_time')} format='DD MMMM YYYY' />
            </IonLabel>
          </IonItemDivider>

          {group.get('job_providers').map((jobProvider) => {
            const job = jobProvider.get('job') || Map()
            return (
              <IonItem
                key={jobProvider.get('id')}
                button
                onClick={() => navigateToJob(jobProvider)}
                detail={false}
                lines='full'
                className='list-item'
              >
                <JobCard
                  type={job.get('type')}
                  jobNumber={job.get('number')}
                  serviceName={job.getIn(['service', 'name'])}
                  serviceIconUrl={job.getIn(['service', 'icon_url'])}
                  chargeCustomerPrice={job.getIn(['price', 'display_value'])}
                  payout={job.getIn(['payout', 'display_value'])}
                  paymentMethod={job.get('payment_method')}
                  startTime={job.get('start_time')}
                  endTime={job.get('end_time')}
                  address={job.getIn(['location', 'short_address'])}
                  preferredBy={job.get('preferred_customer')}
                  footer={{
                    state: jobProvider.get('state'),
                    phoneNumber: job.getIn(['location', 'phone_number']),
                    conversation: job.get('conversation')
                  }}
                  hasLogistics={job.get('route')}
                  route={job.get('route')}
                  remark={job.getIn(['inquiry', 'remarks_for_provider'])}
                />
              </IonItem>
            )
          })}
        </IonItemGroup>
      ))}
    </IonList>
  )
}

JobList.propTypes = {
  data: ImmutablePropTypes.list
}

export default JobList
