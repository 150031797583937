import React from 'react'
import { IonButton, IonItem, IonLabel } from '@ionic/react'
import { bankAccountSchema } from '@seekster/schemas'

import { PageLayout, PageContent, PageHeader } from 'components/layout'

import { useResource } from 'hooks/resources'
import { useTranslation } from 'react-i18next'

import './BankAccountPendingPage.scss'

const BankAccountPendingPage = ({ history, match }) => {
  const { t } = useTranslation('bank_account')
  const [bankAccount, { loading }] = useResource(
    `/bank_accounts/${match.params.id}`,
    bankAccountSchema,
    { id: match.params.id }
  )

  return (
    <PageLayout>
      <PageHeader
        noColor
        noBorder
      />

      <PageContent className='page-content'>
        <div clasName='page-content__section'>
          <img
            className='page-content__pending-approve-img'
            src='/assets/img/pending_approve.png'
            alt='pending'
          />

          <h5>{t('your_bank_account_pending_approve')}</h5>

          <p style={{ marginBottom: 24 }}>
            {t('your_bank_account_pending_approve_description')}
          </p>

          <p style={{ fontSize: 18 }}>{t('your_bank_account')}</p>

          {
            !loading && (
              <IonItem lines='none' className='bank-select__bank-detail'>
                <img
                  src={bankAccount.getIn(['bank', 'logo_url'])}
                  className='bank-select__bank-logo-url'
                  alt='Bank Logo'
                />

                <IonLabel>
                  <h3>{bankAccount.get('account_name')}</h3>
                  <h4>{bankAccount.getIn(['bank', 'name'])} : {bankAccount.get('formatted_account_number')}</h4>
                </IonLabel>
              </IonItem>
            )
          }
          <IonButton
            expand='block'
            style={{ marginTop: 24 }}
            onClick={() => {
              history.push('/settings')
            }}
          >
            {t('back_to_lead_page')}
          </IonButton>
        </div>
      </PageContent>
    </PageLayout>
  )
}

export default BankAccountPendingPage
