import { FCM } from '@capacitor-community/fcm'
import { Plugins } from '@capacitor/core'

const { PushNotifications } = Plugins
const fcm = new FCM()

const registerPushNotifications = async () => {
  const permission = await PushNotifications.requestPermission()

  if (permission.granted) {
    await PushNotifications.register()
    const response = await fcm.getToken()

    return response.token
  }
}

export default registerPushNotifications
