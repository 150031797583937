import { withFormik } from 'formik'

import SignInForm from './SignInForm'

const formikConfig = {
  mapPropsToValues: () => ({}),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
      .then(() => {
        props.push('/')
      })
  }
}

export default withFormik(formikConfig)(SignInForm)
