import React, { useRef, useState, useEffect } from 'react'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonImg,
  IonAvatar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonFab,
  IonFabButton,
  IonFabList,
  IonAlert,
  IonLoading
} from '@ionic/react'

import { ellipse, add } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar, utils } from 'react-modern-calendar-datepicker'

import CalendarCard from 'components/CalendarCard'
import { PageLayout, PageHeader, PageContent } from 'components/layout'
import moment from 'moment'
import { useEventId } from 'hooks/utils'
import {
  providerRestDateSchema,
  providerActiveDateSchema,
  providerSchema
} from '@seekster/schemas'
import { useCollection } from 'hooks/collections'
import { useResourceActions, useResource } from 'hooks/resources'
// import { uniqBy } from 'lodash'

import './MyCalendarPage.scss'

const myCustomLocale = {
  // months list by order
  months: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ],
  // week days by order
  weekDays: [
    {
      name: 'Sunday', // used for accessibility
      short: 'อา.'
    },
    {
      name: 'Monday',
      short: 'จ.'
    },
    {
      name: 'Tuesday',
      short: 'อ.'
    },
    {
      name: 'Wednesday',
      short: 'พ.'
    },
    {
      name: 'Thursday',
      short: 'พฤ.'
    },
    {
      name: 'Friday',
      short: 'ศ.'
    },
    {
      name: 'Saturday',
      short: 'ส.'
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day)
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate()
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'from',
  to: 'to',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false
}

const MyCalendarPage = ({ history }) => {
  const backButtonListenerHandle = useRef()
  const [eventId, generateEventId] = useEventId()
  const [eventId1, generateEventId1] = useEventId()
  const [restDates] = useCollection('/provider_rest_dates', providerRestDateSchema, {
    dependencies: [eventId],
    cacheKey: eventId
  })

  const [availableDates] = useCollection(
    '/provider_available_dates',
    providerActiveDateSchema,
    {
      dependencies: [eventId1],
      cacheKey: eventId1
    }
  )

  const [provider] = useResource('/provider', providerSchema, { implicit: true })

  let preselectedDays = []
  let activeDates = []
  let availableDays = []
  const [isModalActive, setIsModalActive] = useState(false)
  const [isModalDelete, setIsModalDelete] = useState(false)
  const [selectedDay, setSelectedDay] = useState()
  const [disabledDay, setDisabledDay] = useState([])
  const [activeDate, setActiveDate] = useState([])
  const [availableDate, setAvailableDate] = useState([])
  const [isFloatActive, setIsFloatActive] = useState(false)
  const [selectedActiveDay, setSelectedActiveDay] = useState(false)
  const [selectedAvailableDay, setSelectedAvailableDay] = useState(false)
  const [selectedDisabledDate, setSelectedDisabledDay] = useState(false)
  const [selectedDisabledDateId, setSelectedDisabledDayId] = useState(false)
  const [selectedAvailableDayId, setSelectedAvailableDayId] = useState(false)
  const [pairAvailableActiveDay, setPairAvailableActiveDay] = useState([])
  const [pairAvailableDisableDay, setPairAvailableDisableDay] = useState([])
  const [pairActiveDisableDay, setPairActiveDisableDay] = useState([])
  const [groupAvailableActiveDisableDay, setGroupAvailableActiveDisableDay] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  const concatCustomDate = groupAvailableActiveDisableDay
    .concat(pairAvailableActiveDay)
    .concat(pairAvailableDisableDay)
    .concat(pairActiveDisableDay)
    .concat(disabledDay)
    .concat(activeDate)
    .concat(availableDate)

  useIonViewDidEnter(() => {
    setSelectedDay()
    generateEventId()
    generateEventId1()
  })

  useEffect(() => {
    if (provider.get('active_jobs')) {
      const activeDay = provider.get('active_jobs').toJS()
      activeDay.map(
        (date) =>
          // eslint-disable-next-line
          (activeDates = [
            ...activeDates,
            {
              className: 'active_day',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      setActiveDate(activeDates, (v) => [v.year, v.month, v.day].join())
    }
  }, [provider])

  useEffect(() => {
    if (!restDates.isEmpty()) {
      const dateTime = restDates.toJS()
      dateTime.map(
        (date) =>
          // eslint-disable-next-line
          (preselectedDays = [
            ...preselectedDays,
            {
              className: 'disable_day',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      setDisabledDay(preselectedDays)
    } else {
      setDisabledDay([])
    }
  }, [restDates, eventId])

  useEffect(() => {
    if (!availableDates.isEmpty()) {
      const availableDay = availableDates.toJS()
      availableDay.map(
        (date) =>
          // eslint-disable-next-line
          (availableDays = [
            ...availableDays,
            {
              className: 'available_day',
              year: moment(date.start_time).get('year'),
              month: moment(date.start_time).get('month') + 1,
              day: moment(date.start_time).get('date'),
              id: date.id
            }
          ])
      ) // eslint-disable-line react-hooks/exhaustive-deps

      setAvailableDate(availableDays, (v) => [v.year, v.month, v.day].join())
    }
  }, [availableDates, eventId1])

  useEffect(() => {
    const onceActiveAvailable = []
    const onceActiveDisable = []
    const onceDisableAvailable = []
    const onceMix = []

    if (
      !availableDates.isEmpty() &&
      provider.get('active_jobs') &&
      !restDates.isEmpty()
    ) {
      const activeAndAvailable = availableDates.filter((available) =>
        provider
          .get('active_jobs')
          .find(
            (active) =>
              moment(available.get('start_time')).get('date') ===
                moment(active.get('start_time')).get('date') &&
              moment(available.get('start_time')).get('month') + 1 ===
                moment(active.get('start_time')).get('month') + 1
          )
      )

      const disabledAndAvailable = availableDates.filter((available) =>
        restDates.find(
          (disable) =>
            moment(available.get('start_time')).get('date') ===
              moment(disable.get('start_time')).get('date') &&
            moment(available.get('start_time')).get('month') + 1 ===
              moment(disable.get('start_time')).get('month') + 1
        )
      )

      const disabledAndActive = restDates.filter((disable) =>
        provider
          .get('active_jobs')
          .find(
            (active) =>
              moment(disable.get('start_time')).get('date') ===
                moment(active.get('start_time')).get('date') &&
              moment(disable.get('start_time')).get('month') + 1 ===
                moment(active.get('start_time')).get('month') + 1
          )
      )

      const mixDates = activeAndAvailable.filter((date) =>
        restDates.find(
          (disable) =>
            moment(date.get('start_time')).get('date') ===
              moment(disable.get('start_time')).get('date') &&
            moment(date.get('start_time')).get('month') + 1 ===
              moment(disable.get('start_time')).get('month') + 1
        )
      )

      // mixDate
      // eslint-disable-next-line array-callback-return
      mixDates.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'group_available_active_disable_day'
        }
        onceMix.push(formatDay)
      })

      // activeAndAvailable
      // eslint-disable-next-line array-callback-return
      activeAndAvailable.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'pair_available_active_day'
        }
        onceActiveAvailable.push(formatDay)
      })

      // eslint-disable-next-line array-callback-return
      disabledAndAvailable.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'pair_available_disable_day'
        }
        onceDisableAvailable.push(formatDay)
      })

      // eslint-disable-next-line array-callback-return
      disabledAndActive.map((date) => {
        const formatDay = {
          year: moment(date.get('start_time')).get('year'),
          month: moment(date.get('start_time')).get('month') + 1,
          day: moment(date.get('start_time')).get('date'),
          className: 'pair_active_disable_day'
        }
        onceActiveDisable.push(formatDay)
      })

      setGroupAvailableActiveDisableDay(
        onceMix.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairAvailableActiveDay(
        onceActiveAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairAvailableDisableDay(
        onceDisableAvailable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
      setPairActiveDisableDay(
        onceActiveDisable.filter(
          (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        )
      )
    }
  }, [provider, restDates, availableDates, eventId, eventId1]) // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation('settings')
  const [isSelectedDayOff, setIsSelectedDayOff] = useState(false)

  const { deleted: deleteRestDate } = useResourceActions(providerRestDateSchema)
  const { deleted: deleteAvailableDate } = useResourceActions(providerActiveDateSchema)

  function handleRemoveCard(date) {
    const selectedDayWithRemove = selectedDay.filter(
      (value) =>
        value.day !== date.day || value.month !== date.month || value.year !== date.year
    )

    setSelectedDay(selectedDayWithRemove)
  }

  useIonViewDidLeave(() => {
    if (backButtonListenerHandle.current) {
      backButtonListenerHandle.current.remove()
      backButtonListenerHandle.current = undefined
    }
  })

  function handleAcceptEnableDay() {
    setShowLoading(true)
    deleteRestDate(`/provider_rest_dates/${selectedDisabledDateId}`).then(() => {
      handleOnChangeSetDefault()
      setSelectedAvailableDayId()
      setTimeout(function () {
        generateEventId()
        generateEventId1()
        setShowLoading(false)
      }, 1000)
    })
  }

  function handleDisabledDay() {
    setShowLoading(true)
    deleteAvailableDate(`/provider_available_dates/${selectedAvailableDayId}`).then(
      () => {
        handleOnChangeSetDefault()
        setSelectedAvailableDayId()
        setTimeout(function () {
          generateEventId()
          generateEventId1()
          setShowLoading(false)
        }, 1000)
      }
    )
  }

  function getContent() {
    return document.querySelector('.calendar-main-page')
  }

  const hanldeOnChangeDisableDay = (date) => {
    let checkIsActive = []
    let checkIsDisabled = []
    let availabledDate = []
    const selectedDate = date

    if (selectedDate) {
      checkIsActive = activeDate.filter(
        (value) =>
          value.day === selectedDate.day &&
          value.month === selectedDate.month &&
          value.year === selectedDate.year
      )

      checkIsDisabled = disabledDay.filter(
        (value) =>
          value.day === selectedDate.day &&
          value.month === selectedDate.month &&
          value.year === selectedDate.year
      )

      availabledDate = availableDate.filter(
        (value) =>
          value.day === selectedDate.day &&
          value.month === selectedDate.month &&
          value.year === selectedDate.year
      )

      if (checkIsActive.length > 0) {
        const activeDay = provider
          .get('active_jobs')
          .filter((day) => checkIsActive.find((active) => day.get('id') === active.id))
        setSelectedActiveDay(activeDay)
      } else {
        setSelectedActiveDay(false)
      }

      if (checkIsDisabled.length > 0) {
        const disabled = restDates.filter((day) =>
          checkIsDisabled.find((disable) => day.get('id') === disable.id)
        )

        setSelectedDisabledDay(disabled)
      } else {
        setSelectedDisabledDay(false)
      }

      if (availabledDate.length > 0) {
        const availabled = availableDates.filter((day) =>
          availabledDate.find((avialable) => day.get('id') === avialable.id)
        )

        setSelectedAvailableDay(availabled)
      } else {
        setSelectedAvailableDay(false)
      }
    }

    getContent().scrollToPoint(0, 500, 500)
    setSelectedDay(date)
  }

  function handleOnChangeSetDefault() {
    setSelectedDay()
    setSelectedAvailableDay(false)
    setSelectedActiveDay(false)
    setSelectedDisabledDay(false)

    // generateEventId()
    // generateEventId1()
  }

  return (
    <PageLayout>
      {isSelectedDayOff ? (
        <>
          <PageHeader
            title={t('set_mycalendar')}
            withBackButton
            onBack={() => setIsSelectedDayOff(false)}
          />

          <PageContent className='calendar-page'>
            <div className='content-calendar-page'>
              <p>
                หากคุณตั้งเป็นวันหยุด คุณจะไม่เห็นใบงานเข้าใหม่ในวันที่ตรงกับวันหยุดของคุณ
                แต่คุณสามารถยกเลิกวันหยุดได้ หากคุณเปลี่ยนใจ
              </p>

              <h5>วันที่เลือก (จำนวน {selectedDay.length} วัน)</h5>

              <IonGrid fixed>
                <IonRow className='calendar-card-row'>
                  {selectedDay.map((date, index) => {
                    return (
                      <IonCol size='3' key={index}>
                        <CalendarCard date={date} onRemoveCard={handleRemoveCard} />
                      </IonCol>
                    )
                  })}
                </IonRow>
              </IonGrid>
            </div>
          </PageContent>
        </>
      ) : (
        <>
          <PageHeader title={t('my_calendar_title')} withBackButton backTo='/settings' />
          <PageContent className='calendar-main-page'>
            {/* {
        isLoading ? <Spinner /> : children
      } */}
            <IonGrid className='my-calendar' fixed>
              <Calendar
                value={selectedDay}
                onChange={hanldeOnChangeDisableDay}
                // disabledDays={disabledDay}
                minimumDate={utils().getToday()}
                // onDisabledDayError={handleDisabledSelect}
                shouldHighlightWeekends
                locale={myCustomLocale}
                customDaysClassName={concatCustomDate}
              />
              <div className='calendar-devide' />

              <IonRow>
                <IonCol size='5'>
                  <IonRow className='remark-for-calendar'>
                    <IonIcon icon={ellipse} className='icon-actived-date' />
                    วันที่คุณรับงานไว้
                  </IonRow>
                </IonCol>
                <IonCol size='7'>
                  <IonRow className='remark-for-calendar'>
                    <IonIcon icon={ellipse} className='icon-selected-date' />
                    วันหยุดที่คุณเลือกไว้
                  </IonRow>
                </IonCol>
                <IonCol size='12'>
                  <IonRow className='remark-for-calendar'>
                    <IonIcon icon={ellipse} className='icon-already-date' />
                    วันพร้อมรับงาน
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>

            {selectedActiveDay &&
              selectedDay &&
              selectedActiveDay.map((date) => (
                <div className='my-job-card' key={date.get('id')}>
                  <IonGrid className='job-card-container' fixed>
                    <IonRow>
                      <IonCol size='9'>
                        <IonRow className='job-number-row'>
                          <IonText className='calendar-action-text'>
                            {date.getIn(['service', 'name'])}
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonText className='job-numbe'>
                            {`${moment(date.get('start_time')).format('HH:mm')} -
                              ${moment(date.get('end_time')).format('HH:mm')} |
                              ${date.getIn(['location', 'sub_district'])}`}
                          </IonText>
                        </IonRow>
                      </IonCol>

                      <IonCol size='3'>
                        <IonRow className='row-flex-end'>
                          <IonAvatar>
                            <IonImg
                              className='service-icon'
                              src={date.getIn(['service', 'icon_url'])}
                              alt='Service Icon'
                            />
                          </IonAvatar>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              ))}

            {selectedDisabledDate &&
              selectedDay &&
              selectedDisabledDate.map((date) => (
                <div className='my-job-card' key={date.get('id')}>
                  <IonGrid className='job-card-container' fixed>
                    <IonRow>
                      <IonCol size='10'>
                        <IonRow>
                          <IonText className='delete-job'>
                            {`หยุด ${moment(date.get('start_time')).format('HH:mm')} -
                          ${moment(date.get('end_time')).format('HH:mm')}`}
                          </IonText>
                        </IonRow>
                      </IonCol>

                      <IonCol size='2'>
                        <IonRow
                          className='row-flex-end'
                          onClick={() => {
                            setSelectedDisabledDayId(date.get('id'))
                            setIsModalDelete(true)
                          }}
                        >
                          <IonText className='calendar-action-text'>ลบ</IonText>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              ))}

            {selectedAvailableDay &&
              selectedDay &&
              selectedAvailableDay.map((date) => (
                <div className='my-job-card' key={date.get('id')}>
                  <IonGrid className='job-card-container' fixed>
                    <IonRow>
                      <IonCol size='10'>
                        <IonRow>
                          <IonText className='available-job'>
                            {`พร้อมรับงาน ${moment(date.get('start_time')).format(
                              'HH:mm'
                            )} -
                          ${moment(date.get('end_time')).format('HH:mm')}`}
                          </IonText>
                        </IonRow>
                      </IonCol>

                      <IonCol size='2'>
                        <IonRow
                          className='row-flex-end'
                          onClick={() => {
                            setSelectedAvailableDayId(date.get('id'))
                            setIsModalActive(true)
                          }}
                        >
                          <IonText className='calendar-action-text'>ลบ</IonText>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              ))}
          </PageContent>
        </>
      )}

      {isFloatActive && <div className='full-spot-light' />}

      <IonFab
        vertical='bottom'
        horizontal='end'
        slot='fixed'
        onClick={() => setIsFloatActive(!isFloatActive)}
        activated={isFloatActive}
      >
        <IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList side='top'>
          <span>
            <p className='text-toast-button-secondary'>สร้างวันหยุด</p>
            <IonFabButton
              color='danger'
              onClick={() => history.push('/settings/disable_calendar')}
            >
              <img
                className='apple-sign-in-button__icon'
                src='/assets/img/calendar-times.png'
                alt='calendar Logo'
              />
            </IonFabButton>
          </span>
          <span className='child'>
            <p className='text-toast-button'>สร้างวันทำงาน</p>
            <IonFabButton
              color='success'
              onClick={() => history.push('/settings/active_calendar')}
            >
              <img
                className='apple-sign-in-button__icon'
                src='/assets/img/calendar-check.png'
                alt='calendar Logo'
              />
            </IonFabButton>
          </span>
        </IonFabList>
      </IonFab>

      <IonAlert
        isOpen={isModalActive}
        onDidDismiss={() => setIsModalActive(false)}
        cssClass='alert-calendar'
        header='คุณยืนยันที่จะลบวันพร้อมรับงาน ดังกล่าวใช่หรือไม่?'
        buttons={[
          {
            text: 'ยกเลิก',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'ยืนยัน',
            handler: handleDisabledDay
          }
        ]}
      />

      <IonAlert
        isOpen={isModalDelete}
        onDidDismiss={() => setIsModalDelete(false)}
        cssClass='alert-calendar'
        header='คุณยืนยันที่จะลบวันหยุด ดังกล่าวใช่หรือไม่?'
        buttons={[
          {
            text: 'ยกเลิก',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'ยืนยัน',
            handler: handleAcceptEnableDay
          }
        ]}
      />

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message='Please wait...'
        duration={2000}
      />
    </PageLayout>
  )
}

export default MyCalendarPage
