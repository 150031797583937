import React from 'react'
import { IonItem, IonAvatar, IonLabel } from '@ionic/react'
import Select, { components } from 'react-select'
import { Map } from 'immutable'
import { providerConfigurationSchema } from '@seekster/schemas'

import { useResource } from 'hooks/resources'
import { useTranslation } from 'react-i18next'

import './BankSelect.scss'

const makeAccountOption = (bank = Map()) => ({
  value: bank.get('id'),
  labelData: {
    name: bank.get('name'),
    bankLogoUrl: bank.get('logo_url')
  }
})

const Label = ({ data }) => {
  return (
    data.bankLogoUrl && data.name
      ? (
        <IonItem lines='none' className='bank-select__label'>
          <IonAvatar slot='start'>
            <img src={data.bankLogoUrl} alt='Bank Logo' style={{ width: 30, height: 30, margin: '3px 0' }} />
          </IonAvatar>

          <IonLabel>
            <h3>{data.name}</h3>
          </IonLabel>
        </IonItem>
      )
      : null
  )
}

const Option = ({ data, ...rest }) => {
  return (
    <components.Option {...rest}>
      <Label data={data.labelData} />
    </components.Option>
  )
}

const SingleValue = ({ data, children, selectProps, ...rest }) => {
  return (
    <components.SingleValue {...rest}>
      {
        selectProps.isLoading ? (
          'loading....'
        ) : (
          <Label data={data.labelData} />
        )
      }
    </components.SingleValue>
  )
}

const customStyles = {
  valueContainer: base => ({ ...base, height: 44, padding: 8 }),
  indicatorSeparator: () => {},
  option: base => ({ ...base, backgroundColor: 'transparent' })
}

const BankSelect = ({ value, onChange }) => {
  const { t } = useTranslation('bank_account')
  const [providerConfiguration, { loading }] = useResource('/tenant/provider_configuration', providerConfigurationSchema, { implicit: true })

  const bankOptions = providerConfiguration.get('banks') && providerConfiguration.get('banks')
    .map(bank => makeAccountOption(bank))
    .toJS()

  const bankAccountValue = providerConfiguration.get('banks') && makeAccountOption(
    providerConfiguration.get('banks').find(bank => bank.get('id') === value)
  )

  const handleChange = (data) => {
    onChange && onChange(data.value)
  }

  return (
    <Select
      value={bankAccountValue}
      onChange={handleChange}
      options={bankOptions}
      components={{ Option, SingleValue }}
      placeholder={t('select_bank')}
      isLoading={loading}
      styles={customStyles}
    />
  )
}

export default BankSelect
