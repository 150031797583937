import React, { useEffect, useRef, useState } from 'react'
import { Map } from 'immutable'
import { useSelector } from 'react-redux'
import moment from 'moment'
import clsx from 'clsx'

import { getCurrentResource } from 'selectors/authentication'
import { useTranslation } from 'react-i18next'
import { providerSchema } from '@seekster/schemas'

import './GoogleMapRoute.scss'

const markerBlue = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/origin_enable_pin.svg'
const markerRed = 'https://s3-ap-southeast-1.amazonaws.com/seekster-web/assets/images/destination_enable_pin.svg'

const customMapStyles = [
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  }
]

export function GoogleRouteMap ({ zoom, route, originRoute, currentLocation, startTime }) {
  const { t } = useTranslation('jobs')

  const mapInstantRef = useRef()
  const provider = useSelector(state => getCurrentResource(state, providerSchema)) || Map()
  const mapInstant = mapInstantRef.current
  const pickUpTimeText = originRoute ? t('delivery.pick_up', { time: moment(startTime).format('H:mm') }) : t('delivery.heading_to_destination')
  const [origin, setOrigin] = useState()

  useEffect(() => {
    const renderGoogleMap = async () => {
      const map = await new window.google.maps.Map(mapInstantRef.current, {
        zoom,
        styles: customMapStyles,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false
      })

      mapInstantRef.current = map
    }

    if (window.google && window.google.maps) {
      renderGoogleMap()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapInstant) {
      let destinationMaker
      let originMaker
      const bounds = new window.google.maps.LatLngBounds()

      if (typeof origin === 'object') {
        origin.setMap(null)
      }

      if (route) {
        const pickUpWindow = new window.google.maps.InfoWindow({ disableAutoPan: true, maxWidth: 200 })

        const markerIcon = {
          url: originRoute ? markerBlue : markerRed,
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
          labelOrigin: new window.google.maps.Point(15, 50)
        }

        originMaker = new window.google.maps.Marker({
          position: { lat: route.getIn(['origin', 'latitude']), lng: route.getIn(['origin', 'longitude']) },
          map: mapInstant,
          icon: markerIcon
        })

        destinationMaker = new window.google.maps.Marker({
          position: { lat: route.getIn(['destination', 'latitude']), lng: route.getIn(['destination', 'longitude']) },
          map: mapInstant,
          icon: markerIcon
        })

        setOrigin(originMaker)
        if (!originRoute) {
          originMaker.setMap(null)
          pickUpWindow.setContent(pickUpTimeText)
          pickUpWindow.open(mapInstant, destinationMaker)
        }
        else {
          destinationMaker.setMap(null)
          pickUpWindow.setContent(pickUpTimeText)
          pickUpWindow.open(mapInstant, originMaker)
        }

        bounds.extend(new window.google.maps.LatLng(currentLocation.latitude || 13.7250211, currentLocation.longitude || 100.5799585))
        bounds.extend(new window.google.maps.LatLng(route.getIn(['bounds', 'southwest', 'lat']), route.getIn(['bounds', 'southwest', 'lng'])))

        mapInstant.fitBounds && mapInstant.fitBounds(bounds)
      }
    }
  }, [mapInstant, route, originRoute]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentLocation !== null) {
      const originIcon = {
        url: '/assets/img/shape.png',
        scaledSize: new window.google.maps.Size(35, 50),
        origin: new window.google.maps.Point(0, 0),
        labelOrigin: new window.google.maps.Point(20, 16)
      }

      const origin = new window.google.maps.Marker({ // eslint-disable-line no-unused-vars
        position: { lat: currentLocation.latitude, lng: currentLocation.longitude },
        map: mapInstant,
        icon: originIcon,
        zIndex: 101
      })

      const currentLocationIcon = {
        url: provider.get('avatar_url'),
        scaledSize: new window.google.maps.Size(12, 12),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(5.8, 37.5),
        labelOrigin: new window.google.maps.Point(20, 16)
      }

      const currentLocationIconMaker = new window.google.maps.Marker({
        position: { lat: currentLocation.latitude, lng: currentLocation.longitude },
        map: mapInstant,
        zIndex: 102,
        title: 'spot',
        icon: currentLocationIcon
      })

      currentLocationIconMaker.addListener('click', () => {
        alert('provider location')
      })
    }
  }, [currentLocation]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='google-map-container'>
      <div
        className={
          clsx([
            'google-map',
            originRoute ? 'originRoute' : 'destinationRoute'
          ])
        }
        ref={mapInstantRef}
        id='map'
      />
    </div>
  )
}

GoogleRouteMap.defaultProps = {
  zoom: 12
}

export default GoogleRouteMap
