import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IonAlert, isPlatform } from '@ionic/react'
import { Plugins } from '@capacitor/core'
import { useTranslation } from 'react-i18next'
import { clearErrorAlertMessage } from 'actions/interfaces'
import {
  loadLocalData,
  loadBasicAuthentication,
  loadTeamData
} from 'actions/localStorage'
import {
  fetchTenantWithBasicAuth,
  updateCurrentDevice,
  fetchCurrentDevice
} from 'actions/authentication'
import { getAccessToken } from 'selectors/authentication'
import registerPushNotifications from 'utils/pushNotifications'
import { useCurrentLocation } from 'hooks/interfaces'
import { Network } from '@ionic-native/network'
// import OneSignal from 'onesignal-cordova-plugin'
import Router from './Router'

import Spinner from 'components/Spinner'

const { IOSAppTracking } = Plugins

const InitializedLayout = () => {
  const { t } = useTranslation('translation')
  const [ready, setReady] = useState(false)
  const [isError, setIsError] = useState(false)
  const dispatch = useDispatch()

  Network.onDisconnect().subscribe(() => {
    alert('ไม่มีการเชื่อมต่ออินเตอร์เน็ต กรุณาเช็คอินเตอร์เน็ตของท่าน')
  })

  useEffect(() => {
    if (isPlatform('ios')) {
      IOSAppTracking.getTrackingStatus().then((res) => {
        if (res.status === 'unrequested') {
          IOSAppTracking.requestPermission().then((res) => console.log(res))
        }
      })
    }
  }, [])

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(loadLocalData('accessToken')),
        dispatch(loadTeamData('team')),
        dispatch(loadBasicAuthentication())
      ])

      await dispatch(fetchTenantWithBasicAuth())

      setReady(true)
    }

    init()
  }, [dispatch])

  const accessToken = useSelector(getAccessToken)

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchCurrentDevice())
        .then((response) => {
          const providerState = response.body.user.state

          if (!['pending_approval', 'registering'].includes(providerState)) {
            registerPushNotifications()
              .then((fcmToken) => {
                dispatch(updateCurrentDevice({ registration_token: fcmToken }))
              })
              .catch(console.warn)
          }
        })
        .catch(() => {
          setIsError(true)
        })

      // if (isPlatform('android')) {
      //   dispatch(fetchResource('/provider', providerSchema)).then((response) => {
      //     const provider = response.body

      //     OneSignalInit(provider.id)
      //   })
      // }
    }
  }, [dispatch, accessToken])

  useCurrentLocation()

  const errorMessage = useSelector((state) => state.getIn(['interfaces', 'error']))

  const handleDismissAlert = () => {
    dispatch(clearErrorAlertMessage())
  }

  // function OneSignalInit(userId) {
  //   // Uncomment to set OneSignal device logging to VERBOSE
  //   // OneSignal.setLogLevel(6, 0);

  //   // NOTE: Update the setAppId value below with your OneSignal AppId.
  //   OneSignal.setAppId('63149266-9fb1-4eef-9eca-3b18fa36d233')
  //   OneSignal.setNotificationOpenedHandler(function (jsonData) {
  //     console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
  //   })

  //   // iOS - Prompts the user for notification permissions.
  //   //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  //   OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
  //     console.log('User accepted notifications: ' + accepted)
  //   })

  //   OneSignal.setExternalUserId(`${userId}`)
  // }

  return (
    <>
      {ready || isError ? <Router isError={isError} /> : <Spinner />}
      <IonAlert
        isOpen={Boolean(errorMessage)}
        onDidDismiss={handleDismissAlert}
        header={t('error_occured')}
        // header={"This account doesn't exist"}
        message={errorMessage}
        buttons={[t('okay')]}
      />
    </>
  )
}

export default InitializedLayout
