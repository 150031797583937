import { withFormik } from 'formik'
import * as Yup from 'yup'

import WalletWithdrawalForm from './WalletWithdrawalForm'

const formikConfig = {
  mapPropsToValues: ({ defaultBankAccountId }) => ({
    bank_account_id: defaultBankAccountId
  }),

  validationSchema: ({ t }) => Yup.object().shape({
    amount_satangs: Yup.number()
      .required(t('withdrawal_form.minimum_withdraw_100'))
      .min(100, t('withdrawal_form.minimum_withdraw_100')),

    bank_account_id: Yup.number()
      .required(t('withdrawal_form.bank_account_required'))
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
}

export default withFormik(formikConfig)(WalletWithdrawalForm)
