import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { IonGrid } from '@ionic/react'
import { PageLayout, PageContent, PageHeader } from 'components/layout'
import ResetPasswordForm from 'forms/ResetPasswordForm'

import { updatePassword } from 'actions/authentication'

import './ResetPasswordPage.scss'

const ResetPasswordPage = ({ history, match }) => {
  const { t } = useTranslation('reset_password')
  const dispatch = useDispatch()

  const handleSubmit = (data) => {
    return dispatch(updatePassword(data))
  }

  return (
    <PageLayout withSafeAreaBottom className='forgot-password-page-core-layout'>
      <PageHeader title={t('reset_password')} withBackButton />

      <PageContent>
        <IonGrid className='grid-container'>
          <ResetPasswordForm
            onSubmit={handleSubmit}
            push={history.push}
            token={match.params.token}
          />
        </IonGrid>
      </PageContent>
    </PageLayout>
  )
}

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default ResetPasswordPage
