import React from 'react'
import './SmallParagraph.scss'

const SmallParagraph = ({ children }) => {
  return <p className='typo-small-paragraph'>{children}</p>
}

SmallParagraph.propTypes = {}

export default SmallParagraph
