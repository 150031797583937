/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { useHistory } from 'react-router-dom'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonAvatar,
  IonText,
  IonImg,
  IonLabel
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import {
  DateFormatter,
  TimeRangeFormatter,
  StateFormatter,
  RemarkFormatter
} from 'components/formatters'
import { getCurrentResource } from 'selectors/authentication'

import { providerSchema } from '@seekster/schemas'

import './JobCard.scss'

const JobCard = ({
  type,
  jobNumber,
  serviceName,
  serviceIconUrl,
  chargeCustomerPrice,
  payout,
  paymentMethod,
  startTime,
  endTime,
  address,
  preferredBy,
  footer,
  hasLogistics,
  route,
  remark,
  distance,
  availableDates,
  restDates,
  distanceEnabled = false,
  isSpecial = false
}) => {
  const { push } = useHistory()
  const [isAvailableDate, setIsAvailableDate] = useState(false)
  const [isDisableDate, setIsDisableDate] = useState(false)

  const { t } = useTranslation(type)
  const provider =
    useSelector((state) => getCurrentResource(state, providerSchema)) || Map()

  const handleClickMessageIcon = (e) => {
    e.stopPropagation()

    push(`/conversations/${footer.conversation.get('id')}`)
  }

  const handleClickCallIcon = (e) => {
    e.stopPropagation()

    window.location.href = `tel:${footer.phoneNumber}`
  }

  useEffect(() => {
    const startHour = moment(startTime).hours()
    const endHour = moment(endTime).hours()

    if (availableDates) {
      const ava = availableDates.filter(
        (date) =>
          moment(date.get('start_time')).isSame(startTime, 'day') &&
          moment(date.get('start_time')).hours() < endHour &&
          moment(date.get('end_time')).hours() > startHour
      )

      if (ava.size > 0) {
        setIsAvailableDate(true)
      } else {
        setIsAvailableDate(false)
      }
    }

    if (restDates) {
      const rest = restDates.filter(
        (date) =>
          moment(date.get('start_time')).isSame(startTime, 'day') &&
          moment(date.get('start_time')).hours() < endHour &&
          moment(date.get('end_time')).hours() > startHour
      )

      if (rest.size > 0) {
        setIsDisableDate(true)
      } else {
        setIsDisableDate(false)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IonGrid className={`job-card-container ${isSpecial && 'text-red'}`} fixed>
      <IonRow>
        {distanceEnabled &&
          (provider.get('home_address') ||
            provider.getIn(['home_address', 'latitude']) ||
            provider.getIn(['home_address', 'longitude'])) && (
            <>
              <IonCol size='12'>
                <IonRow className='job-number-row'>
                  <IonChip
                    className={
                      parseFloat(distance) >
                      parseFloat(provider.get('available_distance'))
                        ? 'far-chip'
                        : 'near-chip'
                    }
                  >
                    <IonLabel
                      className={
                        parseFloat(distance) >
                        parseFloat(provider.get('available_distance'))
                          ? 'far-chip-label'
                          : 'near-chip-label'
                      }
                    >
                      ห่างจากบ้านคุณ {distance} กม.
                    </IonLabel>
                  </IonChip>
                  {isAvailableDate && (
                    <IonChip className='available-chip'>
                      <IonLabel className='available-chip-label'>วันพร้อมรับงาน</IonLabel>
                    </IonChip>
                  )}
                  {isDisableDate && (
                    <IonChip className='disabled-chip'>
                      <IonLabel className='disabled-chip-label'>งานในวันหยุด</IonLabel>
                    </IonChip>
                  )}
                </IonRow>
              </IonCol>
            </>
          )}

        {!distanceEnabled && (
          <IonCol size='12'>
            <IonRow className='job-number-row'>
              {isAvailableDate && (
                <IonChip className='available-chip'>
                  <IonLabel className='available-chip-label'>วันพร้อมรับงาน</IonLabel>
                </IonChip>
              )}
              {isDisableDate && (
                <IonChip className='disabled-chip'>
                  <IonLabel className='disabled-chip-label'>งานในวันหยุด</IonLabel>
                </IonChip>
              )}
            </IonRow>
          </IonCol>
        )}

        <IonCol size='9'>
          <IonRow className='job-number-row'>
            <IonText className='job-number'>{jobNumber}</IonText>
          </IonRow>

          <IonRow className='service-name-row'>
            <IonText className='service-name'>{serviceName}</IonText>
          </IonRow>

          <IonRow className='start-time-row'>
            <IonCol size='1' className='clock-icon-column'>
              <IonRow>
                <img className='clock-icon' src='/assets/img/clock.png' alt='Clock' />
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonText className='start-time'>
                  <DateFormatter value={startTime} />
                </IonText>
              </IonRow>

              <IonRow>
                <IonText className='start-time'>
                  <TimeRangeFormatter value={{ startTime, endTime }} />
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonCol>

        <IonCol size='3'>
          <IonRow className='row-flex-end'>
            <IonAvatar>
              <IonImg className='service-icon' src={serviceIconUrl} alt='Service Icon' />
            </IonAvatar>
          </IonRow>

          <IonRow className='row-flex-end'>
            <IonText className='payout' color='secondary'>
              {paymentMethod && paymentMethod.get('direct')
                ? chargeCustomerPrice
                : payout}
            </IonText>
          </IonRow>

          {paymentMethod && paymentMethod.get('direct') && (
            <IonRow className='row-flex-end'>
              <IonChip color='success' outline className='cash-chip'>
                <IonLabel color='success'>{t('cash')}</IonLabel>
              </IonChip>
            </IonRow>
          )}
        </IonCol>
      </IonRow>

      {hasLogistics ? (
        <>
          <IonRow className='address-row'>
            <IonCol size='1' className='oval-icon-column'>
              <IonRow>
                <img className='oval-icon' src='/assets/img/oval.png' alt='Marker' />
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonText className='address'>
                  จุดรับ : {route.getIn(['origin', 'masked_address'])}
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>

          <div alt='line' className='timeline' />

          <IonRow className='address-row'>
            <IonCol size='1' className='marker-icon-column'>
              <IonRow>
                <img
                  className='marker-icon'
                  src='/assets/img/map_marker.png'
                  alt='Marker'
                />
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonText className='address'>
                  จุดส่ง : {route.getIn(['destination', 'masked_address'])}
                </IonText>
              </IonRow>
            </IonCol>
          </IonRow>
        </>
      ) : (
        <IonRow className='address-row'>
          <IonCol size='1' className='marker-icon-column'>
            <IonRow>
              <img
                className='marker-icon'
                src='/assets/img/map_marker.png'
                alt='Marker'
              />
            </IonRow>
          </IonCol>

          <IonCol>
            <IonRow>
              <IonText className='address'>{address}</IonText>
            </IonRow>
          </IonCol>
        </IonRow>
      )}

      {preferredBy && (
        <IonRow className='preferred-by-row'>
          <IonCol size='auto' className='customer-avatar-column'>
            <IonAvatar className='customer-avatar'>
              <IonImg src={preferredBy.get('avatar_url')} alt='Customer Avatar' />
            </IonAvatar>
          </IonCol>

          <IonCol>
            <IonText color='secondary' className='customer-text'>
              {t('preferred_by_text', { customerName: preferredBy.get('display_name') })}
            </IonText>
          </IonCol>
        </IonRow>
      )}

      {remark && (
        <IonRow className='remark-row'>
          <RemarkFormatter value={remark} />
        </IonRow>
      )}

      {footer && (
        <IonRow className='footer-by-row'>
          <IonCol>
            <IonRow>
              <IonText className='job-state-label'>{t('status')}</IonText>
            </IonRow>

            <IonRow>
              <StateFormatter value={footer.state} />
            </IonRow>
          </IonCol>

          {footer.phoneNumber && (
            <IonCol size='2'>
              <IonRow className='row-flex-end'>
                <img
                  className='call-icon'
                  src='/assets/img/icon_call.png'
                  alt='Call'
                  onClick={handleClickCallIcon}
                />
              </IonRow>
            </IonCol>
          )}
          {footer.conversation && (
            <IonCol size='2' sizeMd='auto' className='message-col'>
              <IonRow className='row-flex-end'>
                <img
                  className='message-icon'
                  src='/assets/img/icon_message.png'
                  alt='Message'
                  onClick={handleClickMessageIcon}
                />
              </IonRow>
            </IonCol>
          )}
        </IonRow>
      )}
    </IonGrid>
  )
}

JobCard.propTypes = {
  type: PropTypes.string,
  jobNumber: PropTypes.string,
  serviceName: PropTypes.string,
  serviceIconUrl: PropTypes.string,
  chargeCustomerPrice: PropTypes.string,
  payout: PropTypes.string,
  paymentMethod: ImmutablePropTypes.map,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  address: PropTypes.string,
  preferredBy: ImmutablePropTypes.map,
  availableDates: ImmutablePropTypes.list,
  restDates: ImmutablePropTypes.list,
  footer: PropTypes.shape({
    state: PropTypes.string,
    phoneNumber: PropTypes.string,
    conversation: ImmutablePropTypes.map
  }),
  hasLogistics: PropTypes.bool,
  route: ImmutablePropTypes.map,
  remark: PropTypes.string,
  distance: PropTypes.string,
  distanceEnabled: PropTypes.bool,
  isSpecial: PropTypes.bool
}

export default JobCard
