import React from 'react'
import { IonButton, IonIcon, IonText } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PageLayout, PageHeader, PageContent } from 'components/layout'

import './WalletWithdrawalErrorPage.scss'

const WalletWithdrawalErrorPage = () => {
  const { t } = useTranslation('settings')
  const { push } = useHistory()

  return (
    <PageLayout>
      <PageHeader
        title={t('my_bank_account')}
        withBackButton
        backTo='/settings/wallet'
      />

      <PageContent className='wallet-withdrawal-error-page'>
        <div className='content'>
          <p>{t('bank_account_content_1')}</p>
          <p>{t('bank_account_content_2')}</p>
          <IonButton onClick={() => push('/settings/bank_account/new')}>
            <IonIcon icon={addCircleOutline} />
            <IonText>{t('register_bank_account')}</IonText>
          </IonButton>
        </div>
      </PageContent>
    </PageLayout>
  )
}

export default WalletWithdrawalErrorPage
