import React from 'react';
import PropTypes from 'prop-types';

import './InfoBox.scss';
import {  IonRow, IonText } from '@ionic/react';

const InfoBox = ({ colorOne, colorTwo, angle, isBigBox, topContentHeading, topContentData, bottomContentHeading, bottomContentData }) => {
  return (
    <div
      className={isBigBox ? "info-box-big-container" : "info-box-container"}
      style={{ background: `linear-gradient(${angle}deg,  ${colorOne} 0%,${colorTwo} 100%)` }}
    >
      <div className="info-box-content">
        <div className={isBigBox ? "info-box-top-content-big" : "info-box-top-content"}>
          <IonRow>
            <IonText className={isBigBox ? "info-box-top-heading-big" : "info-box-top-heading"}>
              {topContentHeading}
            </IonText>
          </IonRow>
          <IonRow className="info-box-top-data" >
            <IonText>
              {topContentData}
            </IonText>
          </IonRow>
        </div>
        <div className={isBigBox ? "info-box-bottom-content-big" : "info-box-bottom-content"}>
          <IonRow>
            <IonText className="info-box-bottom-heading">
              {bottomContentHeading}
            </IonText>
          </IonRow>
          <IonRow className="info-box-bottom-data">

            {bottomContentData ? (
              <IonText className={isBigBox && "info-box-big-text"}>
                <span className={isBigBox ? "info-box-bottom-data-first-char-big" : "info-box-bottom-data-first-char"}>{bottomContentData[0]} </span><span>{bottomContentData.slice(1, bottomContentData.length)}</span>
              </IonText>
            ) : "N/A"
            }
          </IonRow>
        </div>
      </div>
    </div>);
};

InfoBox.defaultProps = {
  colorOne: "#9C8CF8",
  colorTwo: "#7D5BF6",
  angle: 270,

};

InfoBox.propTypes = {
  colorOne: PropTypes.string,
  colorTwo: PropTypes.string,
  angle: PropTypes.number,
  topContentHeading: PropTypes.string,
  topContentData: PropTypes.string,
  bottomContentHeading: PropTypes.string,
  bottomContentData: PropTypes.string,
  isBigBox: PropTypes.bool
};

export default InfoBox;
