import React from 'react'
import { Form } from 'formik'
import { IonIcon } from '@ionic/react'
import { paperPlane } from 'ionicons/icons'
import BaseField from 'components/fields/BaseField'

import './ChatForm.scss'

function ChatForm ({ values, handleSubmit }) {
  // function handleChangeFile (e) {
  //   setFieldValue('image', e.target.files[0])
  //   handleSubmitForm()
  // }

  function handleSubmitForm () {
    handleSubmit()
  }

  return (
    <Form className='chat-form'>
      {/* <label htmlFor='file-upload' className='file-upload'>
        <IonIcon icon={add} size='large' />
        <input
          id='file-upload'
          type='file'
          accept='image/*'
          onChange={handleChangeFile}
        />
      </label> */}
      <div className='input-conatiner'>
        <BaseField
          noLabel
          name='content'
          textarea
          rows={1}
          autoComplete='off'
          autoGrow
          placeholder
        />
      </div>
      <label className={values.image || values.content ? 'active' : 'inactive'} onClick={handleSubmitForm}>
        <IonIcon icon={paperPlane} size='large' />
      </label>
    </Form>
  )
}

export default ChatForm
