import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { IonList, IonItem, IonThumbnail, IonImg } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import './JobHistoryReportPane.scss'

const JobHistoryReportPane = ({ answers }) => {
  const { push, location } = useHistory()

  const renderAnswer = (answer) => {
    let urls = []

    switch (answer.getIn(['question', 'display_type'])) {
      case 'string':
      case 'textarea':
      case 'number':
      case 'radio':
      case 'checkboxes':
      case 'select':
        return <p>{answer.get('display_value')}</p>
      case 'camera':
      case 'gallery':
        urls = [answer.get('image_url')]

        return (
          <IonThumbnail
            className='job-image-answer'
            onClick={() => {
              push(`${location.pathname}/report_photos`, {
                urls,
                initialSlide: 0
              })
            }}
          >
            <IonImg src={answer.get('image_url')} alt='Image Answer' />
          </IonThumbnail>
        )
      case 'camera_multiple':
      case 'gallery_multiple':
        urls = answer
          .get('attachments', List())
          .map((attachment) => attachment.get('file_url'))
          .toJS()

        return (
          <div className='job-images-answer-container'>
            {answer.get('attachments', List()).map((attachment, i) => (
              <IonThumbnail
                key={attachment.get('id')}
                className='job-image-answer'
                onClick={() => {
                  push(`${location.pathname}/report_photos`, {
                    urls,
                    initialSlide: i
                  })
                }}
              >
                <IonImg src={attachment.get('file_url')} alt='Image Answer' />
              </IonThumbnail>
            ))}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='job-history-report-pane'>
      <IonList>
        {answers.map((answer) => (
          <IonItem key={answer.get('id')}>
            <div>
              <p>{answer.getIn(['question', 'name'])}</p>
              {renderAnswer(answer)}
            </div>
          </IonItem>
        ))}
      </IonList>
    </div>
  )
}

JobHistoryReportPane.defaultProps = {
  answers: ImmutablePropTypes.list
}

JobHistoryReportPane.defaultProps = {
  answers: List()
}

export default JobHistoryReportPane
