import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import 'moment/locale/th'

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    // backend: {
    //   loadPath: '/locales/{{lng}}/{{ns}}.json'
    // },

    fallbackLng: 'en',
    whitelist: ['en', 'th'],

    // defaultNS: 'translation',
    fallbackNS: 'translation',

    react: {
      wait: true,
      nsMode: 'fallback',
      useSuspense: false
    }
  })
  .then(() => moment.locale(i18next.language))

export default i18next
