import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { providerSchema } from '@seekster/schemas'

import { PageLayout, PageContent, PageHeader } from 'components/layout'
import SignUpDetailsForm from 'forms/SignUpDetailsForm'

import { signOut } from 'actions/authentication'
import { updateResource, triggerResource } from 'actions/resources'
import { useResource } from 'hooks/resources'

const SignUpDetailsPage = ({ history }) => {
  const { t } = useTranslation('sign_up')
  const dispatch = useDispatch()
  const [provider] = useResource('/provider', providerSchema)

  const handleSubmit = (data) => {
    return dispatch(updateResource('/provider', providerSchema, data)).then(() => {
      return dispatch(triggerResource('/provider/complete_registration', providerSchema))
    })
  }

  const handleBack = () => {
    return dispatch(signOut())
      .then(() => {
        return history.push('/sign_in')
      })
      .catch(() => {
        return history.push('/sign_in')
      })
  }

  useEffect(() => {
    if (provider.get('verified') === false) {
      history.push('/verification/generate')
    }
  }, [provider, history])

  return (
    <PageLayout>
      <PageHeader title={t('private_info')} withBackButton onBack={handleBack} />

      <PageContent>
        <SignUpDetailsForm data={provider} onSubmit={handleSubmit} push={history.push} />
      </PageContent>
    </PageLayout>
  )
}

SignUpDetailsPage.propTypes = {
  history: PropTypes.object
}

export default SignUpDetailsPage
