import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonItem,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonViewWillEnter
} from '@ionic/react'
import { groupBy } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Accordion from 'components/Accordion'
import ListSkeleton from 'components/ListSkeleton'
import TeamJobCard from 'components/TeamJobCard'
import { useTeamDistribution } from 'hooks/teamDistribution'

const IncommingJob = () => {
  const { fetchTeam, jobSearch, currentTeam } = useTeamDistribution()
  const [jobs, setJob] = useState([])
  const [loading, setLoading] = useState(true)
  const groupJob = groupBy(jobs, (job) => moment(job.startTime).format('D MMM YYYY'))
  const entries = Object.entries(groupJob)
  const { t } = useTranslation(['settings', 'team_lead'])

  useIonViewWillEnter(() => {
    const teamTabs = document.getElementById('team-tab-bar')
    teamTabs.style.display = ''
  })

  useEffect(() => {
    setLoading(true)
    fetchTeam('/jobs', {
      query: {
        status: 'pending_assign',
        sort: 'startTime:asc',
        search: jobSearch,
        team: currentTeam?._id
      }
    }).then((res) => {
      setJob(res.body)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSearch, currentTeam])

  const doRefresh = (e) => {
    fetchTeam('/jobs', {
      query: {
        status: 'pending_assign',
        sort: 'startTime:asc',
        search: jobSearch,
        team: currentTeam?._id
      }
    }).then((res) => {
      setJob(res.body)
      e.detail.complete()
    })
  }
  return (
    <IonPage className='incomming-job-page'>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonItem lines='full'>
          {loading ? <ListSkeleton /> : `${t('number')} ${jobs.length} ${t('job')}`}
        </IonItem>
        {entries.map((group, index) => (
          <Accordion
            key={group[0] + index}
            label={group[0]}
            labelRight={`${group[1].length} ${t('job')}`}
          >
            <IonList>
              {group[1].map((job) => (
                <IonItem key={job.wfJobId} lines='full' style={{ width: '100%' }}>
                  <TeamJobCard incomming {...job} />
                </IonItem>
              ))}
            </IonList>
          </Accordion>
        ))}
      </IonContent>
    </IonPage>
  )
}

export default IncommingJob
