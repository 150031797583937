import React from 'react'
import './Header.scss'
import PropTypes from 'prop-types'

const Header = ({ children, white }) => {
  return <h3 className={`typo-header ${white && 'white'}`}>{children}</h3>
}

Header.propTypes = {
  children: PropTypes.string
}

export default Header
