import { denormalize } from 'normalizr'
import { Map } from 'immutable'
import reReselect from 're-reselect'

import getEntities from './entities'

export const getSchemaState = reReselect(
  getEntities,
  (_state, schema) => schema,
  (entities, schema) => entities.get(schema._key) || Map()
)((_state, schema) => schema._key)

export const getResource = reReselect(
  getEntities,
  (_state, id, _schema) => id,
  (_state, _id, schema) => schema,
  (entities, id, schema) => denormalize(id, schema, entities)
)((_state, id, schema) => `${schema._key}[${id}]`)

export const getChildResource = () => {}
