import React from 'react'
import { IonText, IonButton } from '@ionic/react'

import { PageLayout, PageContent } from 'components/layout'

import './NotFoundPage.scss'

const NotFoundPage = () => {
  return (
    <PageLayout>
      <PageContent className='not-found-page'>
        <IonText color='warning'>
          <h3>404 na jaaa</h3>
        </IonText>

        <IonButton routerLink='/'>
          back
        </IonButton>
      </PageContent>
    </PageLayout>
  )
}

export default NotFoundPage
