import React from 'react'

import { IonText } from '@ionic/react'
import PropTypes from 'prop-types'

import './Paragraph.scss'
const Paragraph = ({ error, children, white }) => {
  return (
    <IonText
      className={`typo-paragraph ${error && 'error-paragraph'} ${white && 'white'}`}
    >
      {children}
    </IonText>
  )
}

Paragraph.propTypes = {
  error: PropTypes.bool
}

export default Paragraph
