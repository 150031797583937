import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  IonItem,
  IonLabel,
  IonProgressBar,
  IonButton,
  IonText,
  isPlatform
} from '@ionic/react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PageLayout, PageHeader, PageContent } from 'components/layout'
import QRScanner from 'components/inputs/QRScanner'
import UploadQRScanner from 'components/inputs/UploadQRScanner'
import SwitchTenantForm from 'forms/SwitchTenantForm'
import { fetchTenantWithBasicAuth } from 'actions/authentication'
import { setBasicAuthentication } from 'actions/localStorage'
import request from 'utils/request'

import './SwitchTenantPage.scss'

const SwitchTenantPage = ({ history }) => {
  const { t } = useTranslation(['switch_tenant', 'home', 'settings'])
  const [currentPage, setCurrentPage] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { push } = useHistory()

  const dispatch = useDispatch()

  const handleScanSuccess = (data) => {
    const tokenRegex = new RegExp(/^(\S{32})([|])(\S{88})$/)
    const isValidToken = typeof data === 'string' && tokenRegex.test(data)

    if (isValidToken) {
      setLoading(true)

      const [clientAccessKey, clientSecretKey] = data.split('|')

      return request
        .auth(clientAccessKey, clientSecretKey)
        .get('/ping')
        .then(() =>
          dispatch(setBasicAuthentication({ clientAccessKey, clientSecretKey }))
        )
        .then(() => dispatch(fetchTenantWithBasicAuth()))
        .then(() => history.replace('/sign_in'))
        .catch(() => {
          setLoading(false)

          setErrorMessage('access_denied')
        })
    } else {
      setErrorMessage('invalid_qr_code')
    }
  }

  const handleOnSubmit = (data) => {
    const clientAccessKey = data.access_key
    const clientSecretKey = data.secret_key

    setLoading(true)

    return request
      .auth(clientAccessKey, clientSecretKey)
      .get('/ping')
      .then(() => dispatch(setBasicAuthentication({ clientAccessKey, clientSecretKey })))
      .then(() => dispatch(fetchTenantWithBasicAuth()))
      .then(() => history.replace('/sign_in'))
      .catch(() => {
        setLoading(false)

        setErrorMessage('access_denied')
      })
  }

  const handleScan = (data) => {
    if (data) {
      setErrorMessage('')

      handleScanSuccess(data)
    } else {
      setErrorMessage('invalid_qr_code')
    }
  }

  function handleBack() {
    if (currentPage !== null) {
      setCurrentPage(null)
    } else {
      push('/home')
    }
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('sign_in_to_another_account')}
        withBackButton
        onBack={handleBack}
      />
      <PageContent>
        {loading && <IonProgressBar type='indeterminate' color='secondary' />}

        {currentPage === null && (
          <div className='switch-tenant-page'>
            {isPlatform('android') && (
              <IonButton
                className='switch-tenant-button'
                expand='block'
                fill='outline'
                onClick={() => setCurrentPage('qr')}
              >
                Scan QR Code
              </IonButton>
            )}

            <IonButton
              className='switch-tenant-button'
              expand='block'
              onClick={() => setCurrentPage('gallery')}
            >
              เปิด QR Code จาก Gallery
            </IonButton>

            <div className='button-divider'>
              <div className='line' />

              <IonText className='text'>{t('or')}</IonText>

              <div className='line' />
            </div>

            <IonButton
              className='switch-tenant-button'
              expand='block'
              fill='outline'
              onClick={() => setCurrentPage('input')}
            >
              กรอก Access Key
            </IonButton>
          </div>
        )}

        {errorMessage !== '' && currentPage !== null && (
          <IonItem lines='none'>
            <IonLabel color='danger'>{t(errorMessage)}</IonLabel>
          </IonItem>
        )}

        {currentPage === 'gallery' && (
          <>
            <UploadQRScanner onScan={handleScan} />
          </>
        )}

        {currentPage === 'qr' && (
          <>
            <QRScanner onScan={handleScan} />
          </>
        )}

        {currentPage === 'input' && (
          <SwitchTenantForm loading={loading} onSubmit={handleOnSubmit} />
        )}
      </PageContent>
    </PageLayout>
  )
}

SwitchTenantPage.propTypes = {
  history: PropTypes.object
}

export default SwitchTenantPage
