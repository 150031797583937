import React from 'react'
import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react'
import { personSharp } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { providerSchema } from '@seekster/schemas'

import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import { useResource } from 'hooks/resources'

import './MyAddressPage.scss'

const MyAddressPage = ({ history }) => {
  const { t } = useTranslation('settings')
  const [provider] = useResource('/provider', providerSchema, { implicit: true })

  return (
    <PageLayout>
      <PageHeader title={t('my_address')} withBackButton backTo='/settings' />

      <PageContent>
        <IonGrid className='my-address-page' fixed>
          <IonRow>
            <IonCol size='1'>
              <IonRow>
                <IonIcon icon={personSharp} className='person-image' />
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonLabel>{provider.getIn(['home_address', 'contact_name'])}</IonLabel>
              </IonRow>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='1'>
              <IonRow>
                <img
                  alt='img'
                  className='marker-icon-image'
                  src='/assets/img/ic_address_grey.png'
                />
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonLabel>{provider.getIn(['home_address', 'full_address'])}</IonLabel>
              </IonRow>
            </IonCol>
          </IonRow>
          {provider.getIn(['home_address', 'latitude']) &&
            provider.getIn(['home_address', 'longitude']) && (
              <IonRow>
                <IonCol>
                  <div style={{ width: '100%', overflow: 'hidden', height: '300px' }}>
                    <iframe
                      title='customer location'
                      src={`https://maps.google.com/maps/embed/v1/place?key=AIzaSyAU_2eJ5XtFbDRTPcg0dYMGGO8d-c_AZ_M&q=${provider.getIn(
                        ['home_address', 'latitude']
                      )},${provider.getIn(['home_address', 'longitude'])}`}
                      width='100%'
                      height='300'
                      frameBorder='0'
                      loading='lazy'
                      style={{ border: 0 }}
                    />
                  </div>
                </IonCol>
              </IonRow>
            )}
        </IonGrid>
      </PageContent>

      <PageFooter className='my-address-footer-container'>
        <IonButton expand='block' onClick={() => history.push('/settings/address')}>
          {t('edit_address')}
        </IonButton>
      </PageFooter>
    </PageLayout>
  )
}

export default MyAddressPage
