import { IonAvatar, IonImg, IonItem, IonText } from '@ionic/react'
import React from 'react'
import './AvatarCard.scss'
import PropTypes from 'prop-types'

const AvatarCard = ({ provider }) => {
  return (
    <IonItem lines='none' className='avatar-card'>
      <IonAvatar className='avatar-img-container'>
        <IonImg src={provider.get('avatar_url')} />
      </IonAvatar>
      <IonText className='avatar-name-text'>{provider.get('display_name')}</IonText>
    </IonItem>
  )
}
AvatarCard.propTypes = {
  provider: PropTypes.object
}
export default AvatarCard
