import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { IonAlert } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { jobProviderSchema, providerSchema } from '@seekster/schemas'
import { useSelector } from 'react-redux'
import { useResource, useResourceActions } from 'hooks/resources'
import { useCurrentLocation } from 'hooks/interfaces'
import { useJobTabs } from 'hooks/utils'

import { PageLayout, PageHeader, PageContent, PageFooter } from 'components/layout'
import JobActionFooter from 'components/JobActionFooter'
import Spinner from 'components/Spinner'
import { updateProviderLocation } from 'actions/firebase/resources'

import { getCurrentResource } from 'selectors/authentication'

import { JobDeliveryDetailsPane, JobDeliveryReportPane } from './panes'

const JobDeliveryShowPage = ({ match }) => {
  const { t } = useTranslation('jobs')
  const submitButtonRef = useRef()
  const [showStartJobAlert, setShowStartJobAlert] = useState(false)
  const [showStartTimeAlert, setShowStartTimeAlert] = useState(false)
  const [currentTab, setTab] = useJobTabs()
  const [{ latitude, longitude }] = useCurrentLocation()
  const provider =
    useSelector((state) => getCurrentResource(state, providerSchema)) || Map()

  const [jobProvider, { loading }] = useResource(
    `/job_providers/${match.params.id}`,
    jobProviderSchema,
    { id: match.params.id }
  )
  const job = jobProvider.get('job') || Map()

  const {
    triggerWithData: startJob,
    update: completeJob,
    loading: updatingJob
  } = useResourceActions(jobProviderSchema)

  const isPendingCompletion = jobProvider.get('state') === 'pending_completion'

  const handleUpdateLocation = () => {
    if (latitude && longitude) {
      return updateProviderLocation(
        `${provider.get('id')}`,
        'staging_current_locations',
        {
          latitude,
          longitude,
          updated_at: new Date()
        }
      )
    }
  }

  const updateLocation = useCallback(handleUpdateLocation, [
    provider,
    latitude,
    longitude
  ])

  useEffect(() => {
    let myUpdateLocation

    if (jobProvider.get('state') === 'heading_to_destination') {
      myUpdateLocation = setInterval(updateLocation, 150000)
    } else {
      clearInterval(myUpdateLocation)
    }
  }, [jobProvider, updateLocation])

  const handleStartJob = () => {
    const startTime = jobProvider.getIn(['job', 'start_time'])
    const halfHour = 1000 * 60 * 30
    const anHalfHourAgo = (new Date(startTime).valueOf() - halfHour).valueOf()
    const jobStartTime = new Date().valueOf()

    if (anHalfHourAgo <= jobStartTime) {
      return startJob(`/job_providers/${match.params.id}/depart`, {
        start_latitude: latitude,
        start_longitude: longitude
      }).then(() => {
        updateLocation()
      })
    } else {
      setShowStartTimeAlert(true)
    }
  }

  const handleHeadingToDestination = () => {
    return startJob(`/job_providers/${match.params.id}/start`, {
      start_latitude: latitude,
      start_longitude: longitude
    }).then(() => {
      updateLocation()

      handleCompleteJob()
    })
  }

  const handleCompleteJob = (data) => {
    if (currentTab !== 'report') {
      setTab('report')
    } else {
      submitButtonRef.current.click()
    }
  }

  return (
    <PageLayout>
      <PageHeader title='งานของฉัน' withBackButton noBorder backTo='/jobs' />

      <PageContent loading={loading}>
        {currentTab === 'details' ? (
          <JobDeliveryDetailsPane jobProvider={jobProvider} />
        ) : (
          <JobDeliveryReportPane
            reportQuestions={jobProvider.getIn(['job', 'report_questions'])}
            submitButtonRef={submitButtonRef}
            completeJob={completeJob}
            jobProvider={jobProvider}
            jobProviderId={match.params.id}
          />
        )}

        <IonAlert
          isOpen={showStartTimeAlert}
          onDidDismiss={() => setShowStartTimeAlert(false)}
          message={t('time_error')}
          buttons={[
            {
              text: t('okay'),
              role: 'cancel',
              cssClass: 'secondary'
            }
          ]}
        />

        <IonAlert
          isOpen={showStartJobAlert}
          onDidDismiss={() => setShowStartJobAlert(false)}
          message={t('ask_customer_about')}
          buttons={[
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: t('okay'),
              handler: handleStartJob
            }
          ]}
        />
      </PageContent>
      <PageFooter>
        <JobActionFooter
          footer={{
            phoneNumber:
              jobProvider.get('state') === 'heading_to_destination'
                ? job.getIn(['route', 'destination', 'phone_number'])
                : job.getIn(['route', 'origin', 'phone_number']),
            conversation: job.get('conversation')
          }}
          // paymentMethod={jobProvider.getIn(['job', 'payment_method'])}
          // payout={jobProvider.get('payout')}
          onActionClick={
            isPendingCompletion
              ? handleCompleteJob
              : jobProvider.get('state') === 'heading_to_destination'
              ? handleHeadingToDestination
              : jobProvider.get('state') === 'heading_to_origin'
              ? handleStartJob
              : () => setShowStartJobAlert(true)
          }
          actionLabel={
            isPendingCompletion
              ? t('complete_job')
              : t(`delivery.${jobProvider.get('state')}`)
          }
          disabled={updatingJob}
        />
      </PageFooter>

      {updatingJob && <Spinner overlaid />}
    </PageLayout>
  )
}

JobDeliveryShowPage.propTypes = {
  match: PropTypes.object
}

export default JobDeliveryShowPage
