import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import {
  IonGrid, IonRow, IonCol, IonButton,
  IonLabel
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import BaseField from 'components/fields/BaseField'
import PasswordInput from 'components/inputs/PasswordInput'

import './ResetPasswordForm.scss'

const ResetPasswordForm = ({ loading }) => {
  const { t } = useTranslation('reset_password')

  return (
    <Form>
      <IonGrid className='form-container'>
        <IonRow>
          <IonCol>
            <IonLabel
              className='field-label'
              color='primary'
            >
              {t('password')}
            </IonLabel>

            <BaseField
              name='password'
              type='password'
              component={PasswordInput}
              inputmode='password'
              placeholder={t('password')}
              noLabel
              noErrorMessagePlaceholder
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonLabel
              className='field-label'
              color='primary'
            >
              {t('re_password')}
            </IonLabel>

            <BaseField
              name='re_password'
              type='password'
              component={PasswordInput}
              inputmode='password'
              placeholder={t('re_password')}
              noLabel
              noErrorMessagePlaceholder
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className='sign-in-button-col'>
            <IonButton
              type='submit'
              expand='block'
              disabled={loading}
            >
              {t('send')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Form>
  )
}

ResetPasswordForm.propTypes = {
  loading: PropTypes.bool
}

export default ResetPasswordForm
