import { Plugins } from '@capacitor/core'
import request from 'utils/request'
import selectQueryToServer from 'utils/selectQueryToServer'
import { getBasicAuth, getAccessToken } from 'selectors/authentication'
import {
  FETCH_COLLECTION_SUCCEEDED,
  CLEAR_COLLECTION_DATA_SUCCEEDED
} from 'constants/collections'

const { Storage } = Plugins

const fetchCollectionSucceeded = (
  response,
  schema,
  { parentSchema, parentId, cacheKey }
) => ({
  type: FETCH_COLLECTION_SUCCEEDED,
  response,
  schema,
  parentSchema,
  parentId,
  cacheKey
})

export const fetchCollection = (endpoint, schema, options = {}) => async (
  dispatch,
  getState
) => {
  const { query = {}, parentSchema, parentId, cacheKey } = options
  const { locale, ...rest } = query
  const queryToServer = { ...options.query }
  const language = await Storage.get({ key: 'locale' })

  return request
    .auth(...getBasicAuth(getState()))
    .get(endpoint)
    .query({
      ...rest,
      ...queryToServer,
      locale: locale || language.value,
      filter: selectQueryToServer(schema, options)
    })
    .authentication(getAccessToken(getState()))
    .then((response) => {
      dispatch(
        fetchCollectionSucceeded(response, schema, { parentSchema, parentId, cacheKey })
      )

      return response
    })
    .catch((error) => {
      throw error
    })
}

export const clearCollectionData = (schema) => (dispatch) => {
  return dispatch({
    type: CLEAR_COLLECTION_DATA_SUCCEEDED,
    schema
  })
}
