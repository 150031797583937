import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  IonLabel,
  IonButton,
  IonRouterLink,
  IonAvatar,
  IonItem,
  IonImg,
  IonText
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import Segment from 'components/Segment'
import { JobDetailsSegment, JobStartTimeSegment } from 'modules/segments'
import { StateFormatter, RemarkFormatter } from 'components/formatters'

import './JobDetailsPane.scss'
import JobAdditionalInfoSegment from 'modules/segments/JobAdditionalInfoSegment/JobAdditioanlInfoSegment'

const JobDetailsPane = ({ jobProvider, match, mapDirectionUrl }) => {
  const { t } = useTranslation('jobs')

  return (
    <>
      {jobProvider.getIn(['job', 'inquiry', 'remarks_for_provider']) && (
        <Segment>
          <RemarkFormatter
            value={jobProvider.getIn(['job', 'inquiry', 'remarks_for_provider'])}
          />
        </Segment>
      )}
      <Segment title={t('customer_name')}>
        <IonItem lines='none' className='item-no-padding customer-details-segment'>
          <IonAvatar slot='start' className='customer-avatar'>
            <IonImg
              src={jobProvider.getIn(['customer', 'avatar_url'])}
              alt='Customer Name'
            />
          </IonAvatar>

          <IonLabel className='customer-details'>
            <IonLabel>{jobProvider.getIn(['job', 'location', 'contact_name'])}</IonLabel>
            <IonLabel>{jobProvider.getIn(['job', 'location', 'phone_number'])}</IonLabel>
          </IonLabel>
        </IonItem>

        <IonItem lines='none' className='item-no-padding'>
          <IonLabel>
            <IonButton
              expand='block'
              color='success'
              href={`tel:${jobProvider.getIn(['job', 'location', 'phone_number'])}`}
            >
              {t('call')}
            </IonButton>
          </IonLabel>
          <IonLabel>
            <IonButton
              expand='block'
              color='secondary'
              routerLink={`/conversations/${jobProvider.getIn([
                'job',
                'conversation',
                'id'
              ])}`}
            >
              {t('message')}
            </IonButton>
          </IonLabel>
        </IonItem>
      </Segment>
      <Segment title={t('location')} noPadding>
        <IonText className='lead-address'>
          {jobProvider.getIn(['job', 'location', 'full_address'])}
        </IonText>

        <IonRouterLink routerLink={`${match.url}/map`}>
          {jobProvider.getIn(['job', 'location', 'latitude']) && (
            <div style={{ width: '100%', overflow: 'hidden', height: '300px' }}>
              <iframe
                title='customer location'
                src={`https://maps.google.com/maps/embed/v1/place?key=AIzaSyAU_2eJ5XtFbDRTPcg0dYMGGO8d-c_AZ_M&q=${jobProvider.getIn(
                  ['job', 'location', 'latitude']
                )},${jobProvider.getIn(['job', 'location', 'longitude'])}`}
                width='100%'
                height='300'
                frameBorder='0'
                loading='lazy'
                // style={{ border: 0, marginTop: '-150px' }}
                style={{ border: 0 }}
              />
            </div>
          )}
        </IonRouterLink>

        <IonItem
          href={mapDirectionUrl}
          target='_blank'
          rel='noopener noreferrer'
          lines='none'
          className='item-open-google-map-app'
          // disabled={!(jobProvider.getIn(['job', 'location', 'latitude']) && jobProvider.getIn(['job', 'location', 'longitude']))}
          disabled={!mapDirectionUrl}
        >
          <IonAvatar slot='start'>
            <img src='/assets/img/ic_get_direction.png' alt='ic_get_direction' />
          </IonAvatar>

          <IonLabel>{t('use_map_navigation')}</IonLabel>
        </IonItem>
      </Segment>
      <JobStartTimeSegment job={jobProvider.get('job')} />
      <JobAdditionalInfoSegment
        additionalInfo={jobProvider.getIn(['job', 'inquiry', 'additional_information'])}
      />
      <JobDetailsSegment
        number={jobProvider.getIn(['job', 'number'])}
        service={jobProvider.getIn(['job', 'service'])}
        orders={jobProvider.getIn(['job', 'orders'])}
        answers={jobProvider.getIn(['job', 'answers'])}
        attachments={jobProvider.getIn(['job', 'attachments'])}
      />
      <Segment title={t('status')}>
        <StateFormatter value={jobProvider.get('state')} />
      </Segment>
    </>
  )
}

JobDetailsPane.propTypes = {
  jobProvider: ImmutablePropTypes.map,
  match: PropTypes.object,
  mapDirectionUrl: PropTypes.string
}

export default JobDetailsPane
