import React, { useState } from 'react'
import { IonItem, IonLabel, IonList, useIonViewDidEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { conversationSchema } from '@seekster/schemas'
import moment from 'moment'

import {
  PageLayout,
  PageHeader,
  PageContent
} from 'components/layout'

import { useCollection } from 'hooks/collections'
import groupByStartDate from 'utils/groupByStartDate'
import { useEventId } from 'hooks/utils'

import './ConversationIndexPage.scss'

const Time = ({ updatedAt }) => {
  const isToday = moment().isSame(updatedAt, 'day')

  let setFormat

  if (isToday) {
    setFormat = moment(updatedAt).format('HH:mm')
  }
  else {
    setFormat = moment(updatedAt).format('D MMM')
  }

  return <span style={{ float: 'right', color: '#B6B6B6' }}>{setFormat}</span>
}

const ConversationIndexPage = () => {
  const { t } = useTranslation('conversations')
  const [currentPage, setCurrentPage] = useState(1)
  const { push } = useHistory()
  const [eventId, generateEventId] = useEventId()
  const [conversations, { loading }] = useCollection(
    '/conversations',
    conversationSchema, {
      dependencies: [eventId],
      query: { page: currentPage },
      cacheKey: eventId
    }
  )

  const handleRefresh = () => {
    generateEventId()
    setCurrentPage(1)
  }

  useIonViewDidEnter(() => {
    generateEventId()
  })

  return (
    <PageLayout>
      <PageHeader title={t('messages')} />

      <PageContent onRefresh={handleRefresh} loading={loading}>
        <IonList lines='full' style={{ marginBottom: 0 }} className='conversation-list'>
          {
            groupByStartDate(conversations, conversationSchema, ['updated_at'], 'desc').map(group => (
              group.get('conversations').map((conversation, index) => (
                conversation.get('latest_message') && (
                  <IonItem
                    key={index}
                    onClick={() => push(`/conversations/${conversation.get('id')}`)}
                    className='conversation-list__item'
                  >
                    <img
                      alt='avatar'
                      src={conversation.getIn(['customers', 0, 'avatar_url'])}
                      className='conversation-list__img'
                    />
                    <IonLabel className='conversation-list__label'>
                      <p>
                        {conversation.getIn(['customers', 0, 'display_name'])}
                        <Time updatedAt={conversation.get('updated_at')} />
                      </p>
                      <span>{conversation.getIn(['latest_message', 'content'])}</span>
                    </IonLabel>
                  </IonItem>
                )
              ))
            ))
          }
        </IonList>
      </PageContent>

      {/* <IonFab vertical='bottom' horizontal='end' slot='fixed'>
        <IonFabButton onClick={() => push('/conversations/admin')}>
          <IonImg
            src='/assets/img/CSicon.png'
          />
        </IonFabButton>
      </IonFab> */}
    </PageLayout>
  )
}

export default ConversationIndexPage
