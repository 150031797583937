import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Form } from 'formik'

import BaseField from 'components/fields/BaseField'

import './NotificationForm.scss'

const NotificationForm = ({ submitButtonRef, data }) => {
  return (
    <Form>
      <BaseField name='available_distance' type='tel' children={<div>กิโลเมตร</div>} />

      <input ref={submitButtonRef} type='submit' style={{ display: 'none' }} />
    </Form>
  )
}

NotificationForm.propTypes = {
  submitButtonRef: PropTypes.object,
  data: ImmutablePropTypes.map
}

export default NotificationForm
