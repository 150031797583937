import React from 'react'
import PropTypes from 'prop-types'
import { IonAvatar, IonLabel, IonItem } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { jobProviderSchema } from '@seekster/schemas'

import { useResource } from 'hooks/resources'
import { useCurrentLocation } from 'hooks/interfaces'
import {
  PageLayout,
  PageHeader,
  PageContent,
  PageFooter
} from 'components/layout'
import GoogleDynamicMap from 'components/GoogleDynamicMap'

import './JobMapPage.scss'

const JobMapPage = ({ match }) => {
  const { t } = useTranslation('jobs')

  const [jobProvider] = useResource(
    `/job_providers/${match.params.id}`,
    jobProviderSchema,
    { id: match.params.id }
  )

  const [{ latitude, longitude }] = useCurrentLocation()

  const destinationLat = jobProvider.getIn(['job', 'location', 'latitude'])
  const destinationLng = jobProvider.getIn(['job', 'location', 'longitude'])
  const url = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${destinationLat},${destinationLng}`

  return (
    <PageLayout>
      <PageHeader
        title={t('map')}
        withBackButton
        backTo={`/jobs/${match.params.id}`}
      />

      <PageContent>
        <GoogleDynamicMap
          current={{
            latitude,
            longitude
          }}
          destination={{
            latitude: destinationLat,
            longitude: destinationLng
          }}
          marker='pin'
        />
      </PageContent>

      <PageFooter>
        <IonItem
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          lines='none'
          className='item-open-google-map-app'
          disabled={!(destinationLat && destinationLng)}
        >
          <IonAvatar slot='start'>
            <img src='/assets/img/ic_get_direction.png' alt='ic_get_direction' />
          </IonAvatar>
          <IonLabel>
            {t('use_map_navigation')}
          </IonLabel>
        </IonItem>
      </PageFooter>
    </PageLayout>
  )
}

JobMapPage.propTypes = {
  match: PropTypes.object
}

export default JobMapPage
