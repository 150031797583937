import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { IonText } from '@ionic/react'
import { Plugins } from '@capacitor/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import JobReportForm from 'forms/JobReportForm'
import { loadReport } from 'utils/report'

import './JobReportPane.scss'

const { Storage } = Plugins

const JobReportPane = ({
  reportQuestions,
  submitButtonRef,
  // completeJob,
  jobProviderId,
  jobProvider
}) => {
  const { t } = useTranslation('jobs')
  const { push } = useHistory()
  const [cachedAnswers, setCachedAnswers] = useState({})

  useEffect(() => {
    (async () => {
      const report = await loadReport(jobProviderId)

      setCachedAnswers(report)
    })()
  }, [jobProviderId])

  const handleSaveAnswers = async ({ questionId, ...answer } = {}) => {
    const _report = await Storage.get({ key: `report_${jobProviderId}` })
    const report = JSON.parse(_report.value) || {}

    await Storage.set({
      key: `report_${jobProviderId}`,
      value: JSON.stringify({ ...report, [questionId]: answer })
    })
  }

  const handleUpdateJobProvider = (data) => {
    // return completeJob(`/job_providers/${jobProviderId}`, data)
    push(`/jobs/${jobProviderId}/completion`, { data })
  }

  return (
    <div className='job-report-pane'>
      <IonText
        className='not-for-customer-text'
        color='danger'
      >
        {t('not_for_customer')}
      </IonText>

      <JobReportForm
        reportQuestions={reportQuestions}
        cachedAnswers={cachedAnswers}
        onSaveAnswers={handleSaveAnswers}
        submitButtonRef={submitButtonRef}
        existingAnswers={jobProvider.get('answers')}
        onSubmit={handleUpdateJobProvider}
        push={push}
      />
    </div>
  )
}

JobReportPane.propTypes = {
  submitButtonRef: PropTypes.object,
  // completeJob: PropTypes.func,
  jobProviderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  jobProvider: ImmutablePropTypes.map,
  reportQuestions: ImmutablePropTypes.list
}

export default JobReportPane
