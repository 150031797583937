import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Segment from 'components/Segment'
import { IonText } from '@ionic/react'

const JobAdditionalInfoSegment = ({ additionalInfo, rest }) => {
  const { t } = useTranslation()
  return (
    <Segment title={t('additional_information')} {...rest}>
      <IonText>
        {additionalInfo ? additionalInfo : t('no_additional_information')}
      </IonText>
    </Segment>
  )
}

JobAdditionalInfoSegment.propTypes = {
  additionalInfo: PropTypes.string
}

JobAdditionalInfoSegment.defaultProps = {
  additionalInfo: null
}

export default JobAdditionalInfoSegment
